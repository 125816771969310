import React from "react";
import SpeedProductDetailFeatures from "../../../../../pages/shopify/speed/product/features/SpeedProductDetailFeatures";
import "./ProductTitle.css"
const ProductTitle = () => {
    return (
        <>
            <h1 className="speed-swabs-product-details-product-title">
                <span className="highlight">Speed</span>Swab Oral Drug Screen
            </h1>
            <SpeedProductDetailFeatures />
        </>
    );
};

export default ProductTitle;
