import "./AuthenticatedWrapper.css";
import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Registration from '../registration/Registration';
import {selectIsRegistered, selectToken, setIsRegistered} from "../../../store/user/userSlice";
import AppRoutes from "../../../routes";
import LoginPage from "../../../pages/user/login/Login";
import LoadingSpinningCup from "../../../pages/loading/Loading";
import {authenticateWithToken, refreshToken} from "../../../utils/authentication";
import {hideQuestAccountsModal} from "../../../store/external/questAccountsSlice";
import Modals from "../../modals/Modals";
import {Route} from "react-router-dom";
import PanelsPage from "../../../pages/panels/Panels";

/**
 * AuthenticatedWrapper is a functional component responsible for handling authentication logic
 * and rendering appropriate content based on user's authentication status.
 * It checks for session token in sessionStorage and dispatches actions to update Redux store based on authentication status.
 * It also periodically refreshes token to ensure user stays authenticated.
 *
 * @param {Object} instance - The instance object containing relevant data for the component
 * @param {string} hostname - The hostname used to determine the rendering logic
 */
const AuthenticatedWrapper = ({instance, hostname}) => {
    const session_token = sessionStorage.getItem("accessToken");
    const [loading, setLoading] = React.useState(true);

    // redux store imports
    const dispatch = useDispatch();
    const isRegistered = useSelector(selectIsRegistered);
    const token = useSelector(selectToken);


    useEffect(() => {
        (async () => {
            const isAuthorized = await authenticateWithToken(token || session_token, dispatch);
            if (isAuthorized) {
                setLoading(false);
            } else {
                setLoading(false);
            }
        })();

        const interval = setInterval(async () => {
            const tokenExpiry = sessionStorage.getItem("tokenExpiry");
            const timeBeforeExpiry = 5 * 60 * 1000;

            if (Date.now() > tokenExpiry - timeBeforeExpiry) {
                await refreshToken(dispatch);
            }
        }, 60 * 1000);

        return () => clearInterval(interval);
    }, [dispatch, token, isRegistered]); // added isRegistered to dependency array

    const onEdit = () => dispatch(hideQuestAccountsModal());

    // TODO: need to fix log out and login every hour

    let content;
    if (loading) {
        content = <LoadingSpinningCup />;
    } else if (!token && !session_token) {
        content = (
            <div>
                {hostname === "fusion.labb.com" ? (
                    <PanelsPage />
                ) : (
                    <LoginPage instance={instance} />
                )}
            </div>
        );
    } else if (isRegistered) {
        content = <AppRoutes instance={instance} />;
    } else {
        content = (
            <Registration
                setIsMicrosoftAccountLinked={(status) => dispatch(setIsRegistered(status))}
            />
        );
    }

    return (
        <>
            <Modals />
            {content}
        </>
    );
};

export default AuthenticatedWrapper;
