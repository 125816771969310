// freddy-react/src/components/specimen_tacking/SpecimenTracking.js
import React from 'react';
import SpecimensTable from "./screens/SpecimensTable";
import "./Specimens.css";
import SpecimenFilters from "./specimen_filters/SpecimenFilters";
import {InvoiceIcons} from "../../assets/icons";
import {getLaboratoryBillingRecords} from "../../store/laboratory/laboratorySlice";
import {useDispatch, useSelector} from "react-redux";
function Specimens({ userRoles, screensArray, onEdit }) {

    return (
        <div className="specimens-container">
            <div className="specimens-table-container">
                <SpecimensTable userRoles={userRoles} screens={screensArray} onEdit={onEdit} />
            </div>
        </div>
    );
}

export default Specimens;



