import React, {useEffect} from 'react';
import "./MroSpecimensFilters.css";
import { DateRangePicker } from "rsuite";
import { combine, allowedMaxDays, afterToday } from "rsuite/cjs/DateRangePicker/disabledDateUtils";
import CreatableSelect from "react-select/creatable";
import {useDispatch, useSelector} from "react-redux";
import {getUserRolesFromToken} from "../../../../helpers/token/processToken";
import {subDays, subMonths} from "date-fns";
import {
    getClients,
    getDeviceIds,
    getLocations, getProducts, getServiceLevels,
    getStatuses,
    setDateRange,
    setFilter
} from "../../../../store/filters/filtersSlice";




const MroSpecimensFilters = ({ creatableSelectValues, onApplyFilters }) => {
    const dispatch = useDispatch();
    const {
        deviceId,
        deviceIds,
        client,
        clients,
        location,
        locations,
        product,
        products,
        status,
        statuses,
        serviceLevel,
        serviceLevels,
        dateRange,
    } = useSelector((state) => state.filters);

    const accessToken = sessionStorage.getItem("accessToken");
    const userRoles = getUserRolesFromToken(accessToken);

    const lastWeek = [subDays(new Date(), 6), new Date()];
    const lastMonth = [subMonths(new Date(), 1), new Date()];
    const last3Months = [subMonths(new Date(), 3), new Date()];


    const handleInputChange = (filterName) => (value) => {
        dispatch(setFilter({ filterName, value }));
        onApplyFilters(filterName, value);
    };




    const handleDateRangeChange = (value) => {
        // Ensure we are working with a value that is an array
        if (!Array.isArray(value) || value.length !== 2) {
            console.error("Invalid date range value:", value);
            return; // Exit if value is not a valid array with two dates
        }

        const isoDates = value.map(date => date.toISOString().split('T')[0]);

        // Create filterValues object with start_date and end_date
        let filterValues = {
            start_date: isoDates[0],
            end_date: isoDates[1],
        };

        // Update Redux store with array of ISO date strings
        dispatch(setDateRange(filterValues));

        // Invoke onApplyFilters with the structured date range
        const filterName = "date_range";
        onApplyFilters(filterName, filterValues);
    };


// Safely handle dateRange expecting it to always be an array
    const dateRangeValue = Array.isArray(dateRange) ? dateRange.map(dateStr => new Date(dateStr)) : [];


    return (
        <div className="date-range-picker-container">
            <td className="cell">
                <DateRangePicker
                    showOneCalendar
                    disabledDate={combine(allowedMaxDays(365), afterToday())}
                    appearance="default"
                    placeholder="Select Date Range..."
                    value={dateRangeValue.dateRange}
                    onChange={handleDateRangeChange}
                    ranges={[
                        {label: 'Last Week', value: lastWeek},
                        {label: 'Last Month', value: lastMonth},
                        {label: 'Last 3 Months', value: last3Months},
                    ]}
                />
            </td>
            {Object.entries(creatableSelectValues).map(([key, config]) => (
                <div className="filter-component" key={key}>
                    <CreatableSelect {...config} />
                </div>
            ))}
        </div>
    );
};

export default MroSpecimensFilters;
