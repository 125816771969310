import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchAllNotifications} from "../../services/api/v1/notificationService";

const initialState = {
    isModalOpen: false,
    notifications: [],
};



export const getAllNotifications = createAsyncThunk(
    'screen/getAllNotifications',
    async ( thunkAPI) => {
        // console.log("deviceIdHash from screenslice", deviceIdHash); // Log deviceIdHash here
        const accessToken = sessionStorage.getItem("accessToken");
        // console.log("accessToken from screenslice", accessToken); // Log accessToken here
        return await fetchAllNotifications(accessToken);
    }
);

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        toggleModal: state => {
            state.isModalOpen = !state.isModalOpen;
        },
        addNotification: (state, action) => {
            state.notifications.push(action.payload);
        },
        removeNotification: (state, action) => {
            state.notifications = state.notifications.filter(
                notification => notification.id !== action.payload
            );
        },
        getNotifications: (state, action) => {
            state.notifications = action.payload;
            state.isModalOpen = true;
        },
    },
});

export const { toggleModal, addNotification, removeNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;