import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../assets/input/Input";
import ProgressBar from "../../assets/progress_bar/ProgressBar";
import FormStepNavigation from "../../assets/navigation/Navigation";
import IndustrySelector from "../../assets/industry_selector/IndustrySelector";
import { config } from "../../../../Constants";
import "./TalkSwabs.css";
import {toggleTalkSwabsModal} from "../../../../../store/modals/modalsSlice";
import {IconButton} from "../../../../buttons/icon_button/IconButton";
import {GenericIcons} from "../../../../../assets/icons";

const TalkSwabsModal = () => {
    const dispatch = useDispatch();
    const isTalkSwabsModalOpen = useSelector((state) => state.modals.isTalkSwabsModalOpen);

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        companyName: "",
        email: "",
        phone: "",
        industry: "",
        testingVolume: "",
        importantFactors: [],
        subscribe: false,
    });

    const [errors, setErrors] = useState({});
    const [step, setStep] = useState(1);
    const [dailyVolume, setDailyVolume] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const industries = [
        { label: "Occupational Health Facility", id: "occupational_health_facility" },
        { label: "Employment Agency", id: "employment_agency" },
        { label: "Hospital or Urgent Care", id: "hospital_urgent_care" },
        { label: "Workplace", id: "workplace" },
        { label: "Probation/Parole Department", id: "probation_parole_department" },
        { label: "Drug Testing Site", id: "drug_testing_site" },
        { label: "Other", id: "other" },
    ];

    const volumes = [
        { label: "5-10 tests/day", range: [5, 10], id: "5_10_test_day" },
        { label: "10-15 tests/day", range: [10, 15], id: "10_15_test_day" },
        { label: "15-20 tests/day", range: [15, 20], id: "15_20_test_day" },
        { label: "20-30 tests/day", range: [20, 30], id: "20_30_test_day" },
        { label: "More than 30 tests/day", range: [30, 50], id: "over_30_test_day" },
    ];

    const importantOptions = [
        { label: "Faster lab and MRO results. Speed is everything.", id: "speed_matters" },
        { label: "To set my company above competitors in my area.", id: "competitive_advantage" },
        { label: "A wide range of products & services I can sell to clients.", id: "large_product_line" },
        { label: "Rapid test results that are accurate and reliable.", id: "accurate_and_reliable" },
        { label: "To run more drug tests with fewer employees.", id: "efficient_testing" },
        { label: "Eliminate mistakes and costly errors.", id: "reduce_mistake" },
        { label: "The cheapest price possible. That is the bottom line.", id: "price_matters" },
    ];

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
        setErrors({ ...errors, [name]: "" }); // Clear errors on change
    };

    const handleIndustrySelect = (industry) => {
        setFormData({ ...formData, industry });
        setErrors({ ...errors, industry: "" });
    };

    const handleVolumeSelect = (volume) => {
        setDailyVolume(volume);
        const [min, max] = volume.range;
        const avgMonthly = Math.round(((min + max) / 2) * 30);
        setFormData({ ...formData, testingVolume: avgMonthly });
        setErrors({ ...errors, dailyVolume: "" });
    };

    const handleMultiSelect = (option) => {
        const updatedFactors = formData.importantFactors.includes(option.label)
            ? formData.importantFactors.filter((item) => item !== option.label)
            : [...formData.importantFactors, option.label];
        setFormData({ ...formData, importantFactors: updatedFactors });
        setErrors({ ...errors, importantFactors: "" });
    };

    const validateStep = (currentStep) => {
        const newErrors = {};
        switch (currentStep) {
            case 1:
                if (!formData.firstName) newErrors.firstName = "First name is required.";
                if (!formData.lastName) newErrors.lastName = "Last name is required.";
                if (!formData.email) newErrors.email = "Email is required.";
                if (!formData.phone) newErrors.phone = "Phone number is required.";
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (formData.email && !emailRegex.test(formData.email)) {
                    newErrors.email = "Please provide a valid email address.";
                }
                const phoneRegex = /^[0-9]{10}$/;
                if (formData.phone && !phoneRegex.test(formData.phone)) {
                    newErrors.phone = "Phone number should be 10 digits.";
                }
                break;
            case 2:
                if (!formData.companyName) newErrors.companyName = "Company name is required.";
                if (!formData.industry) newErrors.industry = "Please select an industry.";
                break;
            case 3:
                if (!dailyVolume) newErrors.dailyVolume = "Please select your average daily test volume.";
                break;
            case 4:
                if (formData.importantFactors.length === 0) {
                    newErrors.importantFactors = "Please select what's most important to you.";
                }
                break;
            default:
                return true;
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleNext = () => {
        if (validateStep(step)) {
            setStep(step + 1);
        }
    };

    const handlePrevious = () => {
        setStep(step - 1);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateStep(step)) return;

        // Proceed with form submission
        const url = config.url.FREDDY_API_URL;

        const selectedIndustry = industries.find((industry) => industry.label === formData.industry)?.id;
        const selectedVolume = dailyVolume?.id;
        const selectedImportantFactors = formData.importantFactors.map(
            (option) => importantOptions.find((factor) => factor.label === option)?.id
        );

        const formPayload = new FormData();
        formPayload.append("first_name", formData.firstName);
        formPayload.append("last_name", formData.lastName);
        formPayload.append("company_name", formData.companyName);
        formPayload.append("email", formData.email);
        formPayload.append("phone", formData.phone);
        formPayload.append("industry", selectedIndustry);
        formPayload.append("testing_volume", selectedVolume);
        formPayload.append("important_factors", selectedImportantFactors.join(", "));

        try {
            const response = await fetch(`${url}api/v1/external/hubspot/leads/form/new`, {
                method: "POST",
                body: formPayload,
            });

            if (!response.ok) {
                throw new Error(`Failed to create lead: ${response.statusText}`);
            }

            setFormSubmitted(true);
        } catch (error) {
            console.error("Error creating lead:", error);
        }
    };

    const closeModal = () => {
        dispatch(toggleTalkSwabsModal(false));
    };

    if (!isTalkSwabsModalOpen) return null;


    if (formSubmitted) {
        return (
            <div className="talkswabs-modal-overlay">
                <div className="talkswabs-modal-container">
                    <div className="talkswabs-success">
                        <div className="talkswabs-success-icon">
                            <img src={GenericIcons.SuccessIcon} alt="Success" />
                        </div>
                        <h3 className="talkswabs-success-heading">Submission Successful!</h3>
                        <p className="talkswabs-success-message">Thank you for your submission! Our team will get in touch with you soon.</p>
                        <div className="talkswabs-button-group">
                            <button type="button" className="talkswabs-primary-button" onClick={closeModal}>
                                Explore Products
                            </button>
                            <button type="button" className="talkswabs-secondary-button" onClick={closeModal}>
                                Buy Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="talkswabs-modal-overlay">
            <div className="talkswabs-modal-container">
                <div className="talkswabs-modal-close-header">
                    <IconButton onClick={closeModal} defaultIcon={GenericIcons.ExitIcon} hoverIcon={GenericIcons.CloseIcon}/>

                </div>
                <form className="talkswabs-form" onSubmit={handleSubmit}>
                    <ProgressBar step={step} />

                    {step === 1 && (
                        <div className="talkswabs-name-form-group">
                            <Input
                                id="firstName"
                                name="firstName"
                                placeholder="First Name"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                            {errors.firstName && <span className="error">{errors.firstName}</span>}
                            <Input
                                id="lastName"
                                name="lastName"
                                placeholder="Last Name"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                            {errors.lastName && <span className="error">{errors.lastName}</span>}
                            <Input
                                id="email"
                                name="email"
                                placeholder="Email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            {errors.email && <span className="error">{errors.email}</span>}
                            <Input
                                id="phone"
                                name="phone"
                                placeholder="Phone Number"
                                type="tel"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />
                            {errors.phone && <span className="error">{errors.phone}</span>}
                        </div>
                    )}

                    {step === 2 && (
                        <div className="talkswabs-form-group">
                            <Input
                                id="companyName"
                                name="companyName"
                                placeholder="Company Name"
                                value={formData.companyName}
                                onChange={handleChange}
                                required
                            />
                            {errors.companyName && <span className="error">{errors.companyName}</span>}
                            <IndustrySelector
                                selectedIndustry={formData.industry}
                                industries={industries}
                                handleSelect={handleIndustrySelect}
                            />
                            {errors.industry && <span className="error">{errors.industry}</span>}
                        </div>
                    )}

                    {step === 3 && (
                        <div className="talkswabs-form-group">
                            <h3>What is your average daily test volume?</h3>
                            <div className="talkswabs-volume-selector">
                                {volumes.map((volume) => (
                                    <button
                                        type="button"
                                        key={volume.id}
                                        className={`talkswabs-volume-option ${
                                            dailyVolume?.label === volume.label ? "selected" : ""
                                        }`}
                                        onClick={() => handleVolumeSelect(volume)}
                                    >
                                        {volume.label}
                                    </button>
                                ))}
                            </div>
                            {errors.dailyVolume && <span className="error">{errors.dailyVolume}</span>}
                        </div>
                    )}

                    {step === 4 && (
                        <div className="talkswabs-form-group">
                            <h3>What is most important to you?</h3>
                            <div className="talkswabs-important-factors">
                                {importantOptions.map((option) => (
                                    <button
                                        type="button"
                                        key={option.id}
                                        className={`talkswabs-important-option ${
                                            formData.importantFactors.includes(option.label) ? "selected" : ""
                                        }`}
                                        onClick={() => handleMultiSelect(option)}
                                    >
                                        {option.label}
                                    </button>
                                ))}
                            </div>
                            {errors.importantFactors && <span className="error">{errors.importantFactors}</span>}
                        </div>
                    )}

                    <div className="talkswabs-button-group">
                        <FormStepNavigation
                            handleNext={step === 4 ? handleSubmit : handleNext}
                            handlePrevious={step > 1 ? handlePrevious : null}
                            isLastStep={step === 4}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default TalkSwabsModal;