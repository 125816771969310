import { makeGETRequest, makePOSTRequest } from "../../utils/httpUtils";

export const fetchScreenersByClientId = (client_id, offset = 0, limit = 20, filters = {}, accessToken) => {
    const filterQueries = Object.entries(filters)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");

    const queryString = `offset=${offset}&limit=${limit}${filterQueries ? '&' + filterQueries : ''}`;

    const endpoint = `/api/v1/screener/${client_id}/all?${queryString}`;

    return makeGETRequest(endpoint, accessToken);
};

export const fetchScreener = (screenerId, accessToken) => {
    const endpoint = `/api/v1/screener/${screenerId}`;
    return makeGETRequest(endpoint, accessToken);
};

export const updateScreener = (screenerId, updateScreenerData, accessToken) => {
    const endpoint = `/api/v1/screener/${screenerId}/update`;
    return makePOSTRequest(endpoint, updateScreenerData, accessToken);
};

export const fetchClientCollectors = (clientId, accessToken) => {
    const url = `/api/v1/screener/${clientId}/all`;
    return makeGETRequest(url, accessToken);
}

export const getThinkificCollectorDetails = (collectorId, accessToken) => {
    const formData = new FormData();
    formData.append('collector_id', collectorId);

    const url = `/api/v1/external/thinkific/user/list/details`;
    return makePOSTRequest(url, formData, accessToken);
}
