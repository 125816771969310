import React from "react";
import "./SpecimenNotification.css";
const SpecimenNotificationContent = ({ notification }) => {
  return (
    <div className="client-notification">
      <div className="client-notification-content">
        <div className="client-notification-content-title">
          {notification.content}
        </div>
        <div className="client-notification-content-description">
          {notification.description}
        </div>
      </div>
    </div>
  );
};

export default SpecimenNotificationContent;
