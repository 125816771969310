import React from "react";
import "./SpeedSwabsHowToUse.css";
import SpeedSwabRapidOralFluidDrugTestCollection from "../../../assets/graphics/SpeedSwabRapidOralFluidDrugTestCollection.png";
import SpeedSwabRapidOralFluidDrugTestRecap from "../../../assets/graphics/SpeedSwabRapidOralFluidDrugTestRecap.png";

const SpeedSwabsHowToUse = () => {
    return (
        <div className="how-to-use-section">
            <div className="how-to-use-heading-container">
                <h2 className="how-to-use-heading">Incredibly Easy Process</h2>
            </div>
            <div className="how-to-use-description-container">
                <p className="how-to-use-description">
                    Designed with simplicity in mind, the SpeedSwabs drug test requires minimal training. With clear
                    indicators and user-friendly instructions, even first-time users can administer the test
                    confidently.
                </p>
            </div>
            <div className="how-to-use-content">
                <div className="how-to-use-step">
                    <div className="image-container">
                        <img
                            src={SpeedSwabRapidOralFluidDrugTestCollection}
                            alt="SpeedSwab Oral Fluid Collection"
                            className="how-to-use-image"
                        />
                        <div className="step-overlay">
                            <p className="step-action">COLLECT SPECIMEN</p>
                        </div>
                    </div>
                </div>
                <div className="how-to-use-step">
                    <div className="image-container">
                        <img
                            src={SpeedSwabRapidOralFluidDrugTestRecap}
                            alt="SpeedSwab Oral Fluid Cap Kit"
                            className="how-to-use-image"
                        />
                        <div className="step-overlay">
                            <p className="step-action">RECAP + READ RESULTS</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpeedSwabsHowToUse;