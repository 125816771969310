const drugNameMapping = {
    "MARIJUANA": [
        "MARIJUANA METABOLITE (THCA) (100 ng/mL SCREEN)",
        "MARIJUANA METABOLITE (THCA) (50 ng/mL SCREEN)",
        "MARIJUANA METABOLITE (THCA) (20 ng/mL SCREEN)",
        "MARIJUANA METABOLITE (THC-COOH)",
        "THCSH3",
        "MARIJUANA METABOLITES",
        "MARIJUANA / METABOLITE",
        "MARIJUANA METAB RETEST",
        "MARIJUANA METABOLITE",
        "MARIJUANA (THC)",


    ],
    "OXYCODONE": [
        "OXYCODONE/OXYMORPHONE (100 ng/mL SCREEN)",
        "OXYCODONES",
        "OXYCODONE/OXYMORPHONE",
        "OXYS100",
        "OXYSO3A",
        "OXYSOQL",
        "OXYSQL"
    ],
    "AMPHETAMINES": [
        "AMPHETAMINE/METHAMPHETAMINE (1000 ng/mL SCREEN)",
        "AMPHETAMINE/METHAMPHETAMINE (300 ng/mL SCREEN)",
        "AMPHETAMINE/METHAMPHETAMINE (500 ng/mL SCREEN)",
        "METHAMPHETAMINE",
        "MDA (METHYLENEDIOXY- AMPHETAMINE)",
        "MDMA (METHYLENEDIOXY- METHAMPHETAMINE)",
        "MDEA (METHYLENEDIOXY- ETHYLAMPHETAMINE)",
        "METHAMPHETAMINES",
        "D-METHAMPHETAMINE (H)",
        "L-METHAMPHETAMINE (H)",
        "AMPHETAMINES",
        "d-METHAMPHETAMINE",
        "l-METHAMPHETAMINE",
        "AMPSOQL",
        "AMPSQL",
        "A300",
        "A500",
        "AMP",
        "MAMPSO3A",
        "MDAS",
        "MDAS250",
        "MDASQL"
    ],
    "BARBITURATES": [
        "BARBITURATES (200 ng/mL SCREEN)"
    ],
    "BB-22": [
        "BB-22 Metabolite"
    ],
    "COCAINE": [
        "COCAINE METABOLITE (BZE) (150 ng/mL SCREEN)",
        "COCAINE METABOLITE (BZE) (300 ng/mL SCREEN)",
        "COCH35",
        "COCSO3",
        "COCAINE METABOLITE",
        "COCAINE / METABOLITES",
        "COCAINE METABOLITES",
    ],
    "CODEINE": [
        "CODEINE/MORPHINE (2000 NG/ML SCREEN)",
        "OPICODHH"
    ],
    "MEPERIDINE": [
        "MEPERIDINE METABOLITE",
        "MEPERIDINE &/OR METAB.",
        "MMEPS",
        "MEPSQL"
    ],
    "TESTOSTERONE": [
        "1-TESTOSTERONE &/OR METAB",
        "4-HYDROXYTESTOSTERONE MTB",
        "METHYLTESTOSTERONE METB.",
        "EPITESTOSTERONE",
        "DIHYDROTESTOSTERONE",
        "DESOXYMETHYLTESTOSTERONE",
        "METHYLNORTESTOSTERONE MTB",
        "METHYL-1-TESTOSTERONE/MTB"
    ],
    "ACETYLMORPHINE": [
        "6-ACETYLMORPHINE",
        "6-ACETYLMORPHINE (6-AM)",
        "6-ACETYLMORPHINE (10 ng/mL SCREEN)"
    ],
    "BENZODIAZEPINES": [
        "BENZODIAZEPINES (200 ng/mL SCREEN)"
    ],
    "BUPRENORPHINE": [
        "BUPRENORPHINE &/OR METAB",
        "BUPRENORPHINE METABOLITE",
        "BUPRENORPHINE METAB",
        "BUPRSQL",
        "MBUPRMMS"
    ],
    "CHLOROTHIAZIDE": [
        "HYDROCHLOROTHIAZIDE"
    ],
    "EPHEDRINE": [
        "PSEUDOEPHEDRINE"
    ],
    "ETHYL GLUCURONIDE": [
        "ETHYL GLUCURONIDE (U) (100 ng/mL SCREEN)",
        "ETHYL GLUCURONIDE (U) (250 ng/mL SCREEN)",
        "ETHYL GLUCURONIDE (U) (500 ng/mL SCREEN)"
    ],
    "FENTANYL": [
        "FENTANYLS (0.5 ng/mL SCREEN)",
        "NORFENTANYL (FENTANYL METAB)",
        "FENTANYL METABOLITE (NORFENTANYL)",
        "FENTANYL &/OR METAB",
        "NORFENTANYL",
        "FENTSQL2",
        "HFENTS2"
    ],
    "FLUOXETINE": [
        "FLUOXETINE METABOLITE"
    ],
    "FLUNITRAZEPAM METABOLITE": [
        "FLUNITRAZEPAM METAB",
        "HBENZS"
    ],
    "HYDROCODONE": [
        "HYDROCODONE/HYDROMORPHONE (300 ng/mL SCREEN)",
        "HYDROMORPHONE",
        "OPIMORHH",
        "SOPIS"
    ],
    "KETAMINE": [
        "KETAMINE METABOLITE",
        "KETAMINE &/OR METAB",
        "KETAMINE METABOLITE (NORKETAMINE)",
        "KETSQL"
    ],
    "NALTREXONE": [
        "NALTREXONE METABOLITE"
    ],
    "OPIATES": [
        "OPIATES MORPHINE",
        "OPI",
        "OPI2K",
        "OPI2KM"
    ],
    "TRIAZOLAM METAB": [
        "TRIAZOLAM METABOLITE"
    ],
    "TRAMADOL": [
        "TRAMADOL METAB",
        "TRAMADOL &/OR METAB."
    ],
    "T/E RATIO": [
        "T/E RATIO, QUANT"
    ],
    "PHENIRAMINE": [
        "CHLORPHENIRAMINE",
        "BROMPHENIRAMINE"
    ],
    "PB-22 Metabolite": [
        "5-Fluoro-PB-22 Metabolite"
    ],
    "FLURAZEPAM": [
        "FLURAZEPAM METAB",
        "FLURAZEPAM METABOLITE"
    ],
    "OXYMESTERONE": [
        "FLUOXYMESTERONE METB."
    ]
}

export default drugNameMapping;