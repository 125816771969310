import React, {useEffect} from "react";
import "./ShopifySpeedProductPage.css";
import SpeedSwabsHeader from "../../../../components/headers/speed_swabs_header/SpeedSwabsHeader";
import ShopifySpeedProduct from "../../../../components/shopify/product/ShopifySpeedProduct";
import SpeedSwabsFooter from "../../../../components/footers/speed_swab_footer/SpeedSwabsFooter";
import SpeedSwabsLogo from "../../../../assets/logo/SpeedSwabLogo.png";

const ShopifySpeedProductPage = () => {

    // Set document title based on domain
    const docTitle = "SpeedSwabs | Product Details";

    useEffect(() => {
        document.title = docTitle;
    }, [docTitle]);

    return (
        <div className="shopify-speed-product-page">
            <SpeedSwabsHeader logoSrc={SpeedSwabsLogo} />
            <div className="content">
                <ShopifySpeedProduct />
            </div>
            <SpeedSwabsFooter />
        </div>
    );
};

export default ShopifySpeedProductPage;
