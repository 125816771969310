// freddy-react/src/components/footers/public_freddy_footer/PublicFreddyFooter.js
import "./PublicFreddyFooter.css";
import React from "react";

const PublicFreddyFooter = () => {
    return (
        <footer className="public-freddy-footer">
            <p>© 2024 Freddy | All rights reserved.</p>
        </footer>
    );
};

export default PublicFreddyFooter;