// freddy-react/src/services/api/v1/clientService.js
import {makePOSTRequest} from "../../utils/httpUtils";

export const startClientScreenerCreation = (accessToken, formData) => {
    return makePOSTRequest(`/api/v1/automation/client/screener/create`, formData, accessToken);
};

export async function startClientOrganizationCreation(accessToken, formData) {
    return makePOSTRequest(`/api/v1/automation/client/organization/create`, formData, accessToken);
}

export async function startClientEmployeeCreation(accessToken, formData) {
    return makePOSTRequest(`/api/v1/automation/client/employee/create`, formData, accessToken);
}