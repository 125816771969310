import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchQuickbooksAuthToken } from "../../services/api/v1/quickbooksAuthTokenService";

const initialState = {
    quickbooksAuth: {
        access_token: null,
        refresh_token: null,
        expires_in: null,
        scope: null,
        token_type: null,
        user_id: null,
    },
    isLoading: false,
    error: null,
};

export const getQuickbooksAuth = createAsyncThunk(
    'external/fetchQuickbooksAuthToken',
    async ({ quickbooksGrantToken }, thunkAPI) => {
        try {
            const accessToken = sessionStorage.getItem("accessToken");
            const tokenData = await fetchQuickbooksAuthToken({ quickbooksGrantToken, accessToken });
            sessionStorage.setItem("quickbooksAccessToken", tokenData.access_token);
            sessionStorage.setItem("quickbooksTokenExpiry", Date.now() + (tokenData.expires_in * 1000));
            return tokenData;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data || error.message);
        }
    }
);

const quickbooksAuthSlice = createSlice({
    name: 'quickbooksAuth',
    initialState,
    reducers: {
        clearQuickbooksAuth: (state) => {
            state.quickbooksAuth = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getQuickbooksAuth.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getQuickbooksAuth.fulfilled, (state, action) => {
                state.quickbooksAuth = action.payload;
                state.isLoading = false;
            })
            .addCase(getQuickbooksAuth.rejected, (state, action) => {
                state.error = action.payload || action.error.message;
                state.quickbooksAuth = null;
                state.isLoading = false;
            });
    }
});

export const { clearQuickbooksAuth } = quickbooksAuthSlice.actions;
export default quickbooksAuthSlice.reducer;
