// freddy-react/src/routes/userRoutes.js

import { Route, Routes } from 'react-router-dom';
import UserManagement from "../components/user/user_management/UserManagement";
import Profile from "../components/user/profile/Profile";
import LoginPage from "../pages/user/login/Login";

const UserRoutes = ({instance}) => {
    return (
        <Routes>
            <Route path="/login" element={<LoginPage instance={instance}/>} />
            <Route path="/management" element={<UserManagement />} />
            <Route path="/profile" element={<Profile />} />
        </Routes>
    );
};

export default UserRoutes;