import React from "react";
import "./ShopifySpeedProduct.css";
import SpeedProductDetails from "./details/ProductDetails";
import SpeedProductGuarantee from "./guarantee/ProductGuarantee";
import SpeedProductFAQ from "./faq/ProductFaq";

const ShopifySpeedProduct = () => {
    return (
        <div className="shopify-speed-product">
            <SpeedProductDetails />
            {/*<SpeedProductGuarantee />*/}
            {/*<SpeedProductFAQ />*/}
        </div>
    );
};

export default ShopifySpeedProduct;
