// freddy-react/src/store/autobot/employees/employeesSlice.js

// Asynchronous thunk for fetching organizations by clientId
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";


const initialState = {
    employeeData: [],
    status: 'idle',
    error: null,
};


const employeeSlice = createSlice({
    name: 'employees',
    initialState,
    reducers: {
        clearEmployees: (state) => {
            state.employeeData = [];
            state.status = 'idle';
            state.error = null;
        },
        setEmployees: (state, action) => {
            state.employeeData = action.payload;
        },
    },
});

export const { setEmployees, clearEmployees } = employeeSlice.actions;

export default employeeSlice.reducer;
