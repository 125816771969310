import { combineReducers } from '@reduxjs/toolkit';
import loginReducer from './login/loginSlice';
import screenersReducer from './screeners/screenersSlice';
import clientsReducer from './clients/clientsSlice';
import organizationsReducer from './clients/organizations/organizationsSlice';
import employeesReducer from './employees/employeesSlice';

const autobotReducers = combineReducers({
    login: loginReducer,
    screeners: screenersReducer,
    clients: clientsReducer,
    organizations: organizationsReducer,
    employees: employeesReducer,
});

export default autobotReducers;
