import React, { useEffect } from 'react';
import './ProductPickList.css';
import PickListItem from "./pick_list_item/PickListItem";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersPickList } from "../../../store/orders/ordersSlice";

const ProductPickList = ({ onSelectProduct, selectedProducts }) => {
    const dispatch = useDispatch();
    const ordersPickList = useSelector(state => state.orders.pickList);

    useEffect(() => {
        if (Object.keys(ordersPickList).length === 0) {
            dispatch(getOrdersPickList());
        }
    }, [dispatch, ordersPickList]);

    // Transforming the data into an array format
    const pickList = Object.keys(ordersPickList).map(key => {
        return {
            product_name: key,
            quantity: ordersPickList[key]
        };
    });

    return (
        <div className="product-pick-list">
            {pickList.length > 0 ? (
                pickList.map((item, index) => (
                    <div key={index} onClick={() => onSelectProduct(item.product_name)}
                         className={`pick-list-item ${selectedProducts.includes(item.product_name) ? 'selected' : ''}`}>
                        <PickListItem item={item} />
                    </div>
                ))
            ) : (
                <div>No items found.</div>
            )}
        </div>
    );
};

export default ProductPickList;
