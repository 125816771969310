// freddy-react/src/components/user/unauthenticated/UnauthenticatedWrapper.js

import React from 'react';
import UnauthenticatedRoutes from '../../../routes/UnauthenticatedRoutes';
import UnauthModals from "../../modals/unauth/UnauthModals"; // Import the unauthenticated routes

/**
 * A wrapper component for handling unauthenticated users.
 * @param {object} props - The props for the UnauthenticatedWrapper component.
 * @param {object} props.instance - The instance object that represents the current instance.
 * @param {string} props.hostname - The hostname of the current environment.
 * @returns {JSX.Element} A JSX element containing the UnauthenticatedWrapper content.
 */
const UnauthenticatedWrapper = ({ instance, hostname }) => {
    return (
        <div className="UnauthenticatedWrapper">
            <UnauthModals />
            <UnauthenticatedRoutes instance={instance} hostname={hostname} /> {/* This includes the routes, with login redirect */}
        </div>
    );
};

export default UnauthenticatedWrapper;
