// freddy-react/src/pages/screens/Screens.js

import React, {useEffect, useState} from "react";
import "./Screens.css";
import Specimens from "../../components/specimens/Specimens";
import {convertSpecimenIdToHash} from "../../services/api/v1/screenService";
import {clearScreenData, getScreenDetails} from "../../store/screen/screenSlice";
import {useDispatch, useSelector} from "react-redux";
import {clearOrganization} from "../../store/autobot/clients/organizations/organizationsSlice";
import {getScreens} from "../../store/screens/screensSlice";
import ScreenModal from "../../components/modals/edit/screen/ScreenModal";
import {getUserRolesFromToken} from "../../helpers/token/processToken";
import AdminHeader from "../../components/headers/admin_header/AdminHeader";

const ScreensPage = () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const userRoles = getUserRolesFromToken(accessToken);

    // Add a state for tracking loading status
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentSpecimen, setCurrentSpecimen] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [screenParams, setScreenParams] = useState({offset: 0, limit: 20, filters: {}});
    const dispatch = useDispatch();
    const screenData = useSelector(state => state.screens);
    const screensArray = screenData?.screens.combined_screens_result;


    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    // set current date and 30 days ago date for the defaulScreenParams to use as start_date and end_date
    const defaultScreenParams = {
        offset: 0,
        limit: 20,
        filters: {
            date_range: {
                start_date: thirtyDaysAgo.toISOString().slice(0, 10),
                end_date: today.toISOString().slice(0, 10)
            }
        }
    }
    useEffect(() => {
        dispatch(getScreens(defaultScreenParams));
    }, [dispatch, screenParams]);

    const handleEdit = async (specimenData) => {
        try {
            const accessToken = sessionStorage.getItem("accessToken");

            const deviceData = {
                device_id: specimenData.device_id
            };

            const response = await convertSpecimenIdToHash(deviceData, accessToken);
            const updatedSpecimenData = {
                ...specimenData,
                device_id_hash: response
            };

            if (updatedSpecimenData.device_id_hash) {
                dispatch(clearOrganization());
                dispatch(clearScreenData());
                setCurrentSpecimen(prevSpecimen => updatedSpecimenData);
                setIsModalOpen(true);
                setIsEditing(true);
            }
        } catch (error) {
            console.error("An error occurred while converting specimen_id to device_id_hash:", error);
        }
    };

    useEffect(() => {
        dispatch(getScreens(screenParams));
    }, [dispatch, screenParams]);


    useEffect(() => {
        if (currentSpecimen && currentSpecimen.device_id_hash) {
            const deviceIdHash = currentSpecimen.device_id_hash;
            dispatch(getScreenDetails({deviceIdHash}));
        }
    }, [currentSpecimen, dispatch]);

    const onFilterChange = (filterName, value) => {
        setScreenParams(prevParams => {
            const updatedParams = {
                ...prevParams,
                offset: 0,
                filters: {
                    ...prevParams.filters,
                    [filterName]: value
                }
            };

            dispatch(getScreens(updatedParams));
            return updatedParams;
        });
    };



    return (
        <div className="screens-page-container">
            <div className="screens-header-container">
                <AdminHeader />
            </div>
            <div className="screens-body-container">
                <div className="screens-content-container">
                    <Specimens
                        userRoles={userRoles}
                        screensArray={screensArray}
                        onEdit={handleEdit}
                        onFilterChange={onFilterChange}
                    />
                </div>
            </div>
            {/*<AdminFooter />*/}
        </div>
    );
};
export default ScreensPage;
