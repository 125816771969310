import React from "react";
import "./ProductPriceDetails.css";

const ProductPriceDetails = ({ selectedVariant }) => {
    const calculatePerKitPrice = (price) => {
        return price ? (price / 25).toFixed(2) : null; // Assuming 25 kits per box
    };

    return (
        <>
            <h2 className="product-price">
                {selectedVariant ? `$${selectedVariant.price.amount}` : ""}
            </h2>
            <h3 className="product-per-kit-price">
                {selectedVariant ? `$${calculatePerKitPrice(selectedVariant.price.amount)} per kit` : ""}
            </h3>
        </>
    );
};

export default ProductPriceDetails;
