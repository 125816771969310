import React, {useEffect} from 'react';
import './Panels.css';
import Panels from '../../components/panels/Panels';
import {useDispatch, useSelector} from 'react-redux';
import {fetchPanelsByLabbThunk} from '../../store/panels/panelsSlice';
import ShopifyHeader from '../../components/headers/shopify_header/ShopifyHeader';
import LabFusionLogo from '../../assets/logo/LabFusionLogo.png';
import {useShopifyLogout} from "../../utils/authUtils";
import LabbLogo from "../../assets/logo/LabbLogo.png";


/**
 * Represents the Panels Page component.
 * @returns {Component} PanelsPage
 */
const PanelsPage = () => {
    const dispatch = useDispatch();
    const onLogout = useShopifyLogout();

    const hostname = window.location.hostname;

    // Determine which logo to display
    const logoSrc = hostname === "fusion.labb.com" ? LabFusionLogo : LabbLogo;


    const panelsData = useSelector((state) => state.panels.panels);

    useEffect(() => {
        dispatch(fetchPanelsByLabbThunk());
    }, [dispatch]);


    const docTitle = hostname === 'fusion.labb.com' ? "Lab Fusion | Panel Finder" : "Labb | Panel Finder";

    useEffect(() => {
        document.title = docTitle;
    }, []);

    return (
        <div className="panels-page-container">
            <div className="panels-header-container">
                <ShopifyHeader logoSrc={logoSrc} onLogout={onLogout}/>
            </div>
            <div className="panels-body-container">
                <div className="panels-content-container">
                    <div className="panels-table-container">
                        <Panels data={panelsData}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PanelsPage;
