import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    deviceIdHash: null,
};

const deviceIdSlice = createSlice({
    name: 'deviceId',
    initialState,
    reducers: {
        setDeviceIdHash: (state, action) => {
            state.deviceIdHash = action.payload[0];
        },
    },
});

export const { setDeviceIdHash } = deviceIdSlice.actions;

export default deviceIdSlice.reducer;
