import { makePOSTRequest, makeGETRequest } from "../../utils/httpUtils";

export const fetchLaboratoryBillingRecords = (dateRange, accessToken) => {
    return makePOSTRequest(`/api/v1/export/billing/details`, dateRange, accessToken);
};

export const fetchLaboratoryBillingExport = (dateRange, accessToken) => {
    return makePOSTRequest(`/api/v1/export/billing/csv`, dateRange, accessToken);
};

export const fetchExportStatus = (jobId) => {
    return makeGETRequest(`/api/v1/export/billing/status/${jobId}`);
};
