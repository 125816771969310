// freddy-react/src/services/api/v1/uploadService.js

import { makePOSTRequest } from "../../utils/httpUtils";

export const uploadReport = (formData, accessToken) => {
    return makePOSTRequest(`/api/v1/analyze/document/upload`, formData, accessToken);
};

export const uploadUrls = (formData, accessToken) => {
    return makePOSTRequest(`/api/v1/analyze/urls/upload`, formData, accessToken);
};
