import React, { useEffect, useState } from "react";
import "./ShopifyManageServices.css";
import { useDispatch, useSelector } from "react-redux";
import { getQuestServices } from "../../../../store/services/questServicesSlice";
import LoadingSpinningCup from "../../../../pages/loading/Loading";
import { PrimaryButton } from "../../../buttons/primary_button/PrimaryButton";
import {createShopifyServices} from "../../../../store/products/shopifyProductsSlice";

const ShopifyManageServices = () => {
    const dispatch = useDispatch();
    const accessToken = sessionStorage.getItem("accessToken");

    const [panels, setPanels] = useState([]);
    const [selectedPanels, setSelectedPanels] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);

    // Get panel data from the Redux store
    const questServices = useSelector((state) => state.questServices.questPanels);

    // Handle Shopify admin access token, first from Redux, fallback to sessionStorage
    const [shopifyAdminAccessToken, setShopifyAdminAccessToken] = useState(
        useSelector((state) => state.shopifyAuth.shopifyAdminAccessToken) || sessionStorage.getItem("shopifyAdminAccessToken")
    );

    // Fetch panel data on component mount
    useEffect(() => {
        if (accessToken) {
            dispatch(getQuestServices({ accessToken }));
        }
    }, [accessToken, dispatch]);

    // Filter and set panels when questServices data changes
    useEffect(() => {
        if (questServices && questServices.length > 0) {
            const filteredPanels = questServices.filter((panel) => panel.unbundled === true);
            setPanels(filteredPanels);
        }
    }, [questServices]);

    // Group panels by service_code
    const groupPanelsByServiceCode = (panels) => {
        return panels.reduce((groups, panel) => {
            const { service_code } = panel;
            if (!groups[service_code]) {
                groups[service_code] = [];
            }
            groups[service_code].push(panel);
            return groups;
        }, {});
    };

    // Handle selecting/deselecting a panel
    const handleSelectPanel = (panel) => {
        setSelectedPanels((prevSelected) =>
            prevSelected.includes(panel) ? prevSelected.filter((p) => p !== panel) : [...prevSelected, panel]
        );
    };

    // Handle Select All / Deselect All
    const handleSelectAll = () => {
        if (isAllSelected) {
            setSelectedPanels([]);
        } else {
            setSelectedPanels(panels);
        }
        setIsAllSelected(!isAllSelected);
    };

    // Handle Create button click
    const handleCreate = () => {
        if (selectedPanels.length === 0) {
            alert("Please select at least one panel.");
        } else {
            dispatch(createShopifyServices({ accessToken, shopifyAdminAccessToken, selectedPanels }));
        }
    };

    // Group panels by service_code
    const groupedPanels = groupPanelsByServiceCode(panels);

    return (
        <div className="services-container">
            <div className="actions">
                <PrimaryButton onClick={handleSelectAll} isSelected={isAllSelected}>
                    {isAllSelected ? "Deselect All" : "Select All"}
                </PrimaryButton>
            </div>

            <div className="panels-column">
                {Object.keys(groupedPanels).length > 0 ? (
                    Object.keys(groupedPanels).map((serviceCode, index) => (
                        <div key={index} className="service-group">
                            <h3>{serviceCode}</h3>
                            {groupedPanels[serviceCode].map((panel, panelIndex) => (
                                <div
                                    key={panelIndex}
                                    className={`panel-item ${selectedPanels.includes(panel) ? "selected" : ""}`}
                                    onClick={() => handleSelectPanel(panel)}
                                >
                                    <div className="panel-details">
                                        <ul>
                                            {/* If compendium is an array, map through it; otherwise, treat it as an object */}
                                            {Array.isArray(panel.compendium) ? (
                                                panel.compendium.map((comp, compIndex) => (
                                                    <li key={compIndex}>{comp["Req Name"] || "N/A"}</li>
                                                ))
                                            ) : (
                                                <li>{panel.compendium?.["Req Name"] || "N/A"}</li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))
                ) : (
                    <LoadingSpinningCup />
                )}
            </div>

            <PrimaryButton className="create-button" onClick={() => handleCreate()}>
                CREATE
            </PrimaryButton>
        </div>
    );
};

export default ShopifyManageServices;
