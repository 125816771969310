// freddy-react/src/components/fusion_dashboard/FusionDashboard.js
import React from "react";
import "./FusionDashboard.css";
import {getShopifyOAuthUrl} from "../../../components/user/sign_in/SignIn";
import {PrimaryButton} from "../../../components/buttons/primary_button/PrimaryButton";

const FusionDashboard = () => {
    const handleLogin = async () => {
        const hostname = window.location.hostname;
        try {
            window.location.href = await getShopifyOAuthUrl(hostname);
        } catch (error) {
            console.error("Failed to initiate Shopify OAuth flow:", error);
        }
    };

    return (
        <div className="fusion-dashboard-container">
            <div className="fusion-dashboard-content">
                <h1 className="dashboard-title">Welcome to Lab Fusion</h1>
                <p className="dashboard-message">
                    Please log in using your Lab Fusion email to access your personalized account.
                </p>
                <div className="login-button-container">
                    <PrimaryButton onClick={handleLogin}>
                        LOGIN
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
};

export default FusionDashboard;