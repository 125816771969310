import {makeGETRequest, makePOSTRequest} from "../../utils/httpUtils";

export const fetchClientBillingDetails = async (clientId, accessToken) => {
    const filteredGetUrl = `/api/v1/client/${clientId}/billing/details/list`;
    return makeGETRequest(filteredGetUrl, accessToken);
};

export const createClientSpecimenInvoices = async (formData, accessToken) => {
    const postRequestUrl = `/api/v1/external/zoho/event/create/invoice/new`;
    return makePOSTRequest(postRequestUrl, formData, accessToken);
};

export const fetchExistingClientInvoice = async (formData, accessToken) => {
    const postRequestUrl = `/api/v1/external/zoho/event/create/invoice/existing`;
    return makePOSTRequest(postRequestUrl, formData, accessToken);
};

export const fetchInvoiceBillingDetails = async (dateRange, accessToken) => {
    const formData = new FormData();
    formData.append('start_date', dateRange.start_date);
    formData.append('end_date', dateRange.end_date);
    const getRequestUrl = `/api/v1/billing/invoices/details`;
    return makePOSTRequest(getRequestUrl, formData, accessToken);
};


export const checkJobStatus = async (jobId, accessToken) => {
    const getRequestUrl = `/api/v1/external/zoho/event/create/invoice/existing/status/${jobId}`;
    return makeGETRequest(getRequestUrl, accessToken);
};