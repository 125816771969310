import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid'; // Import UUID library
import axios from 'axios';
import "./PublicFreddyLogin.css";
import { PrimaryButton } from "../../buttons/primary_button/PrimaryButton";
import {
    fetchLoginPublicUser,
    loginPublicUser,
    selectError,
    selectLoadingState
} from "../../../store/user/publicUserSlice";
import { useDispatch, useSelector } from "react-redux";

const cleanToken = (token) => {
    return token.replace(/[^a-fA-F0-9]/g, '').toLowerCase(); // Remove non-hex characters
};

const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
};

const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

const deriveKeyFromUUID = async (cleanUuid) => {
    const uuidBytes = new TextEncoder().encode(cleanUuid);
    const hashBuffer = await crypto.subtle.digest('SHA-256', uuidBytes);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    while (hashArray.length < 32) {
        hashArray.push(0);
    }
    return new Uint8Array(hashArray);  // Returns Uint8Array of length 32
};

// Function to encrypt the password using AES-GCM with derived key from UUID
const encryptPassword = async (password, cleanUuid) => {
    const keyBytes = await deriveKeyFromUUID(cleanUuid);  // Derive key from UUID


    const key = await crypto.subtle.importKey(
        'raw',
        keyBytes,
        { name: 'AES-GCM' },
        false,
        ['encrypt']
    );

    const iv = crypto.getRandomValues(new Uint8Array(12));  // Generate 12-byte IV for AES-GCM

    const encrypted = await crypto.subtle.encrypt(
        { name: 'AES-GCM', iv: iv },
        key,
        new TextEncoder().encode(password)
    );

    // Return encrypted data and IV in Base64
    return {
        ciphertext: arrayBufferToBase64(encrypted),
        iv: arrayBufferToBase64(iv)
    };
};

const PublicFreddyLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loading = useSelector(selectLoadingState);
    const error = useSelector(selectError);

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!username || !password) {
            return;
        }

        const uuid = uuidv4();  // Generate random UUID for encryption

        try {

            const cleanUuid = cleanToken(uuid)
            const { ciphertext, iv } = await encryptPassword(password, cleanUuid);
            const encryptedPassword = { ciphertext, iv };

            // Dispatch login action with UUID and encrypted password
            dispatch(fetchLoginPublicUser({ token: uuid, username, password: encryptedPassword }))

        } catch (err) {
            console.error('Login failed:', err);
        }

        navigate("/home");

    };

    return (
        <div className="public-freddy-login-component">
            <div className="public-freddy-login__container">
                {error && <div className="public-freddy-login__error-message">{error}</div>}
                <form onSubmit={handleSubmit} className="public-freddy-login__form">
                    <div className="public-freddy-login__form-group">
                        <input
                            type="text"
                            id="username"
                            placeholder="Username"
                            value={username}
                            onChange={handleUsernameChange}
                            required
                        />
                    </div>
                    <div className="public-freddy-login__form-group">
                        <input
                            type="password"
                            id="password"
                            placeholder="Password"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                        />
                    </div>
                    <div className="public-freddy-login__form-group">
                        <PrimaryButton type="submit" disabled={loading}>Login</PrimaryButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PublicFreddyLogin;
