import React from "react";
import "./NavBubble.css";

const NavBubble = ({ activeTab, setActiveTab }) => {

    // Handler for when a nav button is clicked
    const handleTabClick = (tab) => {
        setActiveTab(tab);

        // Push data to the Google Analytics dataLayer
        if (window.dataLayer) {
            window.dataLayer.push({

                event: "nav_bubble",
                tabName: tab,
                action: "click",
                category: "navigation",
            });
        }
    };

    return (
        <div className="nav-bubble-container">
            <div className="nav-bubble">
                <button
                    className={`bubble-button ${activeTab === "overview" ? "active" : ""}`}
                    onClick={() => handleTabClick("overview")}
                >
                    OVERVIEW
                </button>
                <button
                    className={`bubble-button ${activeTab === "features" ? "active" : ""}`}
                    onClick={() => handleTabClick("features")}
                >
                    FEATURES
                </button>
                {/*<button*/}
                {/*    className={`bubble-button ${activeTab === "compare" ? "active" : ""}`}*/}
                {/*    onClick={() => handleTabClick("compare")}*/}
                {/*>*/}
                {/*    COMPARE*/}
                {/*</button>*/}
            </div>
        </div>
    );
};

export default NavBubble;
