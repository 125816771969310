import React from 'react';
import './OrderedProductCard.css';
import { PrimaryButton } from "../../../buttons/primary_button/PrimaryButton";

const BoxIndicator = ({ count, type }) => (
    count > 0 && (
        <div className={`box-indicator ${type}`} title={`${count} ${type.replace(/-/g, ' ')}`}>
            <span className="count">{count}</span>
        </div>
    )
);

const OrderedProductCard = ({ boxDetail }) => {

    return (
        <div className="ordered-product-card">
            <div className="product-info">
                <h2 className="product-name">{boxDetail.product_name}</h2>
                <div className="product-box-breakdown">
                    <p className="product-quantity">{boxDetail.product_quantity}</p>
                    <div className="box-types">
                        <BoxIndicator count={boxDetail.boxes_of_4} type="boxes-of-4" />
                        <BoxIndicator count={boxDetail.boxes_of_2} type="boxes-of-2" />
                        <BoxIndicator count={boxDetail.boxes_of_1} type="single-boxes" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderedProductCard;
