import React from "react";
import {useDispatch} from "react-redux";
import "./VariantSelector.css";
import {PrimaryButton} from "../../../../buttons/primary_button/PrimaryButton";
import {IconButton} from "../../../../buttons/icon_button/IconButton";
import {addItem, createCart, toggleSpeedCartModal} from "../../../../../store/shopify/cart/shopifySpeedCartSlice";
import {SpeedIcons} from "../../../../../assets/icons";


const VariantSelector = ({ variants, selectedVariant, setSelectedVariant, cart }) => {
    const dispatch = useDispatch();

    const handleSelectVariant = (e) => {
        const selectedVariantId = e.target.value;
        const variant = variants.find((v) => v.id === selectedVariantId);
        setSelectedVariant(variant);
    };

    const addProductToCart = () => {
        if (!selectedVariant || !selectedVariant.id) {
            alert("Please select a product variant");
            return;
        }
        if (!cart) {
            dispatch(createCart({}))
                .then((newCartAction) => {
                    const newCart = newCartAction.payload;
                    if (newCart?.id) {
                        const cartId = newCart?.id;
                        dispatch(addItem({ cartId, variantId: selectedVariant.id, quantity: 1 }))
                            .then(() => dispatch(toggleSpeedCartModal(true)));
                    } else {
                        console.error("Failed to create cart or missing cart ID");
                    }
                })
                .catch((err) => console.error("Error creating cart", err));
        } else {
            const cartId = cart.id;
            dispatch(addItem({ cartId, variantId: selectedVariant.id, quantity: 1 }))
                .then(() => dispatch(toggleSpeedCartModal(true)));
        }

        window.dataLayer.push({
            event: "add_to_cart",
            productQuantity: selectedVariant.quantity,
            productPrice: selectedVariant.price.amount,
            productName: selectedVariant.title,
            action: "click"
        });

    };

    return (
        <div className="panel-container">
            <select className="panel-select" onChange={handleSelectVariant} value={selectedVariant?.id || ""}>
                {variants.map((variant) => (
                    <option className="panel-select-text" key={variant.id} value={variant.id}>
                        {variant.title}
                    </option>
                ))}
            </select>
            <PrimaryButton onClick={addProductToCart} className="add-to-cart-button">
                <IconButton
                    className="speed-cart-icon-button"
                    hoverIcon={SpeedIcons.SpeedCartIcon}
                    defaultIcon={SpeedIcons.SpeedCartIcon}
                />
                Add to cart
            </PrimaryButton>
        </div>
    );
};

export default VariantSelector;
