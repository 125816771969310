import React from 'react';
import './Organizations.css';

function ReviewOrganization({ organizations }) {


    return (
        <div className="organization-container">
            <div className="organization-header">
                <div>Name</div>
                <div>Show Details</div>
                <div>Email Link</div>
                <div>Rapid Service Level</div>
                <div>Tox Service Level</div>
                <div>Result Report Type</div>
                <div>Send ECCF Notification</div>
                <div>DOT Service</div>
            </div>

            {organizations.map((organization, index) => (
                <div key={index} className="organization-row">
                    <div>{organization.name}</div>
                    <div><input type="checkbox" checked={organization.show_details} disabled /></div>
                    <div><input type="checkbox" checked={organization.email_link} disabled /></div>
                    <div>{organization.rapid_service_level}</div>
                    <div>{organization.tox_service_level}</div>
                    <div>{organization.result_report_type}</div>
                    <div><input type="checkbox" checked={organization.send_eccf_notification} disabled /></div>
                    <div><input type="checkbox" checked={organization.dot_service} disabled /></div>
                </div>
            ))}
        </div>
    );
}

export default ReviewOrganization;
