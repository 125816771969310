import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import './ShipSelection.css';

const ShipSelection = ({ order, setSelectedShipAddress }) => {
    const [selectedShipAddressOption, setSelectedShipAddressOption] = useState(null);
    const [shipAddressOptions, setShipAddressOptions] = useState([]);

    useEffect(() => {
        if (order) {
            getShipAddressOptions();
            setInitialSelectedOption();
        }
    }, [order]);

    const getShipAddressOptions = () => {
        const options = order.ship_options?.map(shipAddress => ({
            value: shipAddress.ship_option_location_id,
            label: `${shipAddress.ship_option_address_line_one}, ${shipAddress.ship_option_city}, ${shipAddress.ship_option_state}, ${shipAddress.ship_option_zip_code}`
        }));
        setShipAddressOptions(options);
    };

    const setInitialSelectedOption = () => {
        if (order.ship_location_id) {
            const initialOption = order.ship_options.find(
                option => option.ship_option_location_id === order.ship_location_id
            );
            if (initialOption) {
                const formattedOption = {
                    value: initialOption.ship_option_location_id,
                    label: `${initialOption.ship_option_address_line_one}, ${initialOption.ship_option_city}, ${initialOption.ship_option_state}, ${initialOption.ship_option_zip_code}`
                };
                setSelectedShipAddressOption(formattedOption);
                setSelectedShipAddress(formattedOption);
            }
        }
    };

    const handleShipAddressOptionChange = (selectedOption) => {
        setSelectedShipAddressOption(selectedOption);
        setSelectedShipAddress(selectedOption);
    };

    return (
        <div className="order-detail-ship-address-options-container">
            <Select
                options={shipAddressOptions}
                onChange={handleShipAddressOptionChange}
                className="filter-select"
                placeholder="Select Ship Option..."
                value={selectedShipAddressOption}
            />
        </div>
    );
};

export default ShipSelection;
