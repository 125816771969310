import React, {useState, useEffect} from "react";
import './Organization.css';
import {useDispatch, useSelector} from "react-redux";
import {fetchOrganizationsByClientId} from "../../../../../store/autobot/clients/organizations/organizationsSlice";
import {GenericIcons} from "../../../../../assets/icons";
import {IconButton} from "../../../../buttons/icon_button/IconButton";

const Organization = ({device_id, organization, onOrganizationChange}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [originalOrganization, setOriginalOrganization] = useState({"organization_name": organization, "organization_id": undefined});
    const [selectedOrganization, setSelectedOrganization] = useState({"organization_name": organization, "organization_id": undefined});
    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const LIMIT = 20;

    const dispatch = useDispatch();
    const screenDetails = useSelector(state => state.screen.screen);
    const questAccounts = useSelector((state) => state.questAccounts.questAccountData);

    useEffect(() => {
        if (isEditing && screenDetails && screenDetails.length > 0) {
            const clientId = screenDetails[0].client_id;
            console.log("clientId from organizaiotn", clientId);
            dispatch(fetchOrganizationsByClientId(questAccounts, clientId))
                .then(response => {
                    const organizations = response?.payload;
                    setOrganizations(organizations);

                    // Find the matching organization from the fetched list
                    const matchingOrg = organizations.find(org => org.organization_name === originalOrganization.organization_name);
                    if (matchingOrg) {
                        setOriginalOrganization({
                            organization_name: matchingOrg.organization_name,
                            organization_id: matchingOrg.organization_id
                        });
                    }
                })
                .catch(error => {
                    console.error("Error fetching organizations:", error);
                });
        }
    }, [isEditing, screenDetails, offset]);


    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleScroll = (e) => {
        const dropdown = e.target;
        if (dropdown.scrollHeight - dropdown.scrollTop === dropdown.clientHeight && hasMore) {
            setOffset(prev => prev + LIMIT);
        }
    }

    const handleOrganizationSelect = (org) => {
        const formattedOrg = {
            organization_id: org.organization_id,
            original_organization_id: originalOrganization.organization_id,
            organization_name: org.organization_name
        };

        setSelectedOrganization(formattedOrg);
        onOrganizationChange(formattedOrg);
        setIsEditing(false);
    };


    const toggleEdit = (e) => {
        e.preventDefault();
        setIsEditing(!isEditing);
    };

    return (
        <section>
            <div className="organization">
                <div className="organization_header">
                    <p className="organization_header_text">Organization</p>
                    {!isEditing
                        ? <IconButton onClick={toggleEdit} defaultIcon={GenericIcons.EditIcon} hoverIcon={GenericIcons.EditIcon} />
                        : <IconButton onClick={toggleEdit} defaultIcon={GenericIcons.CloseIcon} hoverIcon={GenericIcons.ExitIcon} />
                    }
                </div>
                <div className="dark_grey_strip"/>
                <div className="organization_info_section">
                    {isEditing ? (
                        <>
                            <input
                                className="organization-searchable-dropdown"
                                type="text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                onFocus={() => setShowDropdown(true)}
                                onBlur={() => setTimeout(() => setShowDropdown(false), 150)}
                                placeholder="Search organization..."
                            />
                            {showDropdown && (
                                <div className="dropdown-content" onScroll={handleScroll}>
                                    {organizations && organizations.length > 0 ? (
                                        organizations?.filter(org =>
                                                org?.organization_name.toLowerCase().includes(searchQuery.toLowerCase())
                                            )
                                            .map(org => (
                                                <div className="organization_dropdown_row" key={org?.organization_id}
                                                     onClick={() => handleOrganizationSelect(org)}>
                                                    <p className="organization_dropdown_row_text">
                                                        {org?.organization_name}
                                                    </p>
                                                </div>
                                            ))
                                    ) : (
                                        <div className="organization_dropdown_row">
                                            <p className="organization_dropdown_row_text">No organizations found</p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    ) : (
                        <p className="organization_dropdown_text">{selectedOrganization.organization_name}</p>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Organization;
