import React, { useState } from "react";
import { useSelector } from "react-redux";
import {PrimaryButton} from "../../../buttons/primary_button/PrimaryButton";

const ShopifyManageCompanies = () => {
    const [companyName, setCompanyName] = useState('');

    const shopifyAuth = useSelector((state) => state.shopifyAuth.shopifyAuth);
    const accessToken = shopifyAuth?.access_token;

    const assignCompanyTag = async () => {
        const query = `
          mutation {
            customerUpdate(input: {
              id: "customer-id",
              tags: ["${companyName}"]
            }) {
              customer {
                id
                tags
              }
              userErrors {
                field
                message
              }
            }
          }
        `;

        const response = await fetch('/admin/api/2024-01/graphql.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Access-Token': accessToken,
            },
            body: JSON.stringify({ query }),
        });

        const responseData = await response.json();
        console.log(responseData);
    };

    return (
        <div>
            <input
                type="text"
                value={companyName}
                placeholder="Company Name"
                onChange={(e) => setCompanyName(e.target.value)}
            />
            <PrimaryButton onClick={assignCompanyTag}>Assign Company</PrimaryButton>
        </div>
    );
};

export default ShopifyManageCompanies;
