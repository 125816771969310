import React, { useState, useEffect } from 'react';
import './ClientOrders.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPanelsByLabbThunk } from '../../store/panels/panelsSlice';
import PasswordProtect from '../../components/lab_fusion/password_protect/PasswordProtect';
import LabFusionLogo from '../../assets/logo/LabFusionLogo.png';
import LabbLogo from '../../assets/logo/LabbLogo.png';
import ShopifyHeader from "../../components/headers/shopify_header/ShopifyHeader";
import {clearShopifyAuth} from "../../store/external/shopifyAuthSlice";
import ClientOrderBilling from "../../components/orders/client_order_billing/ClientOrderBilling";
import {useShopifyLogout} from "../../utils/authUtils";

const ClientOrdersPage = () => {
    const dispatch = useDispatch();
    const hostname = new URL(window.location.href).hostname;
    const [isAuthorized, setIsAuthorized] = useState(false);
    const panelsData = useSelector(state => state.panels.panels);
    let shopifyAccessToken = useSelector(state => state.external?.shopifyAuth.access_token);

    if (!shopifyAccessToken) {
        shopifyAccessToken = sessionStorage.getItem('shopifyAccessToken');
    }
    useEffect(() => {
        dispatch(fetchPanelsByLabbThunk());
    }, [dispatch]);

    const handleAccessGranted = () => {
        setIsAuthorized(true);
    };

    const logoSrc = hostname === 'fusion.labb.com'? LabFusionLogo : LabbLogo;


    // if (!isAuthorized) {
    //     return <PasswordProtect onAccessGranted={handleAccessGranted} />;
    // }

    const docTitle = hostname === 'fusion.labb.com' ? "Lab Fusion | Invoices" : "Labb | Invoices";

    useEffect(() => {
        document.title = docTitle;
    }, []);


    const onLogout = useShopifyLogout();

    return (
        <div className="client-orders-page">
            {shopifyAccessToken && (
                <>
                    <ShopifyHeader logoSrc={logoSrc} onLogout={onLogout}/>
                    <main className="orders-body-container">
                        <div className="orders-content-container">
                            <ClientOrderBilling/>
                        </div>
                    </main>
                </>
            )}
        </div>
    );
};

export default ClientOrdersPage;
