import React, { useEffect, useState } from 'react';
import './ShipmentRates.css';
import { useDispatch, useSelector } from 'react-redux';
import { createShipmentLabel, getShipmentRates, setTotalShippingCost } from "../../../../store/orders/ordersSlice";
import { setUpsAuth } from "../../../../store/external/upsAuthSlice";
import { showMessageModal } from "../../../../store/modals/modalsSlice";
import { PrimaryButton } from "../../../buttons/primary_button/PrimaryButton";
import { SecondaryButton } from "../../../buttons/secondary_button/SecondaryButton";

const splitBoxes = (boxesDetailConfiguration) => {
    const splitDetails = [];

    boxesDetailConfiguration.forEach(detail => {
        for (let i = 0; i < detail.boxes_of_4; i++) {
            splitDetails.push({
                product_name: detail.product_name,
                product_quantity: 4,
                product_prefix: detail.product_prefix,
                boxes_of_4: 1,
                boxes_of_3: 0,
                boxes_of_2: 0,
                boxes_of_1: 0,
                selected: detail.selected || false
            });
        }

        for (let i = 0; i < detail.boxes_of_3; i++) {
            splitDetails.push({
                product_name: detail.product_name,
                product_quantity: 3,
                product_prefix: detail.product_prefix,
                boxes_of_4: 0,
                boxes_of_3: 1,
                boxes_of_2: 0,
                boxes_of_1: 0,
                selected: detail.selected || false
            });
        }

        for (let i = 0; i < detail.boxes_of_2; i++) {
            splitDetails.push({
                product_name: detail.product_name,
                product_quantity: 2,
                product_prefix: detail.product_prefix,
                boxes_of_4: 0,
                boxes_of_3: 0,
                boxes_of_2: 1,
                boxes_of_1: 0,
                selected: detail.selected || false
            });
        }

        for (let i = 0; i < detail.boxes_of_1; i++) {
            splitDetails.push({
                product_name: detail.product_name,
                product_quantity: 1,
                product_prefix: detail.product_prefix,
                boxes_of_4: 0,
                boxes_of_3: 0,
                boxes_of_2: 0,
                boxes_of_1: 1,
                selected: detail.selected || false
            });
        }
    });

    return splitDetails;
};

const ShipmentRates = ({ selectedShipAddress, productOrderId, boxesDetailConfiguration }) => {
    const dispatch = useDispatch();
    const upsAccessToken = useSelector(state => state.upsAuth.upsAuth);
    const shipmentRates = useSelector(state => state.orders.shipmentOrderRates);
    const [selectedServiceCodes, setSelectedServiceCodes] = useState({});
    const [previousCharges, setPreviousCharges] = useState({}); // Track previous charges for each package
    const [splitConfiguration, setSplitConfiguration] = useState([]);
    const [totalShippingCost, setTotalShippingCostState] = useState(0); // State to track total shipping cost
    const [showModal, setShowModal] = useState(false);
    const [pendingSelection, setPendingSelection] = useState(null); // Track the pending selection

    const storedUpsAccessToken = sessionStorage.getItem("upsAccessToken");

    useEffect(() => {
        if (storedUpsAccessToken) {
            dispatch(setUpsAuth(storedUpsAccessToken));
        }
    }, [storedUpsAccessToken, dispatch]);

    useEffect(() => {
        if (selectedShipAddress?.value) {
            const splitConfig = splitBoxes(boxesDetailConfiguration);
            setSplitConfiguration(splitConfig);
            dispatch(getShipmentRates({
                upsAccessToken,
                selectedShipLocation: selectedShipAddress.value,
                productOrderId,
                boxesDetailConfiguration: splitConfig
            }));
        }
    }, [upsAccessToken, selectedShipAddress, productOrderId, boxesDetailConfiguration, dispatch]);

    const downloadLabel = (labelUrl) => {
        if (labelUrl) {
            try {
                const link = document.createElement("a");
                link.href = labelUrl;
                link.download = `${new Date().getTime()}.gif`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                dispatch(showMessageModal({ message: "Download initiated successfully.", link: link }));
            } catch (error) {
                dispatch(showMessageModal({ message: "Failed to initiate download.", link: null }));
            }
        }
    }

    const upsServiceCodeMap = {
        '01': 'UPS Next Day Air',
        '02': 'UPS 2nd Day Air',
        '03': 'UPS Ground',
        '07': 'UPS Worldwide Express',
        '08': 'UPS Worldwide Expedited',
        '11': 'UPS Standard',
        '12': 'UPS 3 Day Select',
        '13': 'UPS Next Day Air Saver',
        '14': 'UPS Next Day Air Early A.M.',
        '54': 'UPS Worldwide Express Plus',
        '59': 'UPS 2nd Day Air A.M.',
        '65': 'UPS Saver',
        '82': 'UPS Today Standard',
        '83': 'UPS Today Dedicated Courier',
        '84': 'UPS Today Intercity',
        '85': 'UPS Today Express',
        '86': 'UPS Today Express Saver'
    };

    const parseRates = (rates) => {
        return rates?.map((rateResponse, packageIndex) => {
            return {
                packageIndex,
                ratedShipments: rateResponse.RateResponse.RatedShipment.map((shipment) => ({
                    serviceCode: shipment.Service.Code,
                    serviceDescription: upsServiceCodeMap[shipment.Service.Code] || 'Unknown Service',
                    totalCharges: shipment.TotalCharges.MonetaryValue,
                    currencyCode: shipment.TotalCharges.CurrencyCode
                }))
            };
        });
    };

    const parsedRates = shipmentRates ? parseRates(shipmentRates) : [];

    const confirmSelection = (packageIndex, serviceCode, totalCharges) => {
        const updatedBoxesDetailConfiguration = splitConfiguration.map((box, index) => ({
            ...box,
            selected: index === packageIndex
        }));

        const singleBox = updatedBoxesDetailConfiguration[packageIndex];

        setSelectedServiceCodes((prev) => ({ ...prev, [packageIndex]: serviceCode }));

        // Calculate the new total shipping cost
        setTotalShippingCostState((prevTotal) => {
            const previousCharge = parseFloat(previousCharges[packageIndex] || 0);
            const newTotal = prevTotal - previousCharge + parseFloat(totalCharges);
            dispatch(setTotalShippingCost(newTotal)); // Dispatch new total to Redux store
            setPreviousCharges((prev) => ({ ...prev, [packageIndex]: totalCharges }));
            return newTotal;
        });

        dispatch(createShipmentLabel({
            upsAccessToken,
            selectedShipAddress: selectedShipAddress.value,
            selectedServiceCode: serviceCode,
            productOrderId,
            boxesDetailConfiguration: singleBox,
        })).then((action) => {
            if (action.type === createShipmentLabel.fulfilled.toString() && action.payload) {
                const labelUrl = action.payload[0];
                if (labelUrl) {
                    downloadLabel(labelUrl);
                }
            }
        });
    };

    const handleServiceSelection = (packageIndex, serviceCode, totalCharges) => {
        if (selectedServiceCodes[packageIndex]) {
            // If a service code is already selected, show confirmation modal
            setPendingSelection({ packageIndex, serviceCode, totalCharges });
            setShowModal(true);
        } else {
            confirmSelection(packageIndex, serviceCode, totalCharges);
        }
    };

    const handleModalConfirm = () => {
        if (pendingSelection) {
            confirmSelection(pendingSelection.packageIndex, pendingSelection.serviceCode, pendingSelection.totalCharges);
            setPendingSelection(null);
            setShowModal(false);
        }
    };

    const handleModalCancel = () => {
        setPendingSelection(null);
        setShowModal(false);
    };

    return (
        <div className="shipment-rates-container">
            {parsedRates && parsedRates.length > 0 ? (
                parsedRates.map((packageRates) => (
                    <div key={packageRates.packageIndex} className="shipment-rates-list">
                        {packageRates.ratedShipments.map((rate, index) => (
                            <div
                                key={index}
                                className={`shipment-rate-item ${selectedServiceCodes[packageRates.packageIndex] === rate.serviceCode ? 'selected' : ''}`}
                                onClick={() => handleServiceSelection(packageRates.packageIndex, rate.serviceCode, rate.totalCharges)}
                            >
                                <p>{rate.serviceDescription}</p>
                                <p className="total-shipping-charge">${rate.totalCharges}</p>
                                <div className="box-details">
                                    {splitConfiguration[packageRates.packageIndex] && (
                                        <p>Box Details: {splitConfiguration[packageRates.packageIndex].product_name} - {splitConfiguration[packageRates.packageIndex].product_quantity} units</p>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                ))
            ) : (
                <p>No shipment rates available.</p>
            )}

            {showModal && (
                <div className="confirmation-modal-shipment-labels">
                    <div className="modal-content-shipment-labels">
                        <p>Change shipment method?</p>
                        <div className="modal-buttons">
                            <PrimaryButton onClick={handleModalConfirm}>CONFIRM</PrimaryButton>
                            <SecondaryButton onClick={handleModalCancel}>CANCEL</SecondaryButton>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShipmentRates;
