import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchScreens,
  fetchScreensCsv,
} from "../../services/api/v1/screenService";

export const getScreens = createAsyncThunk(
  "screens/getScreens",
  async ({ offset, limit, filters }, thunkAPI) => {
    const accessToken = sessionStorage.getItem("accessToken");
    console.log("fetchScreens: ", { offset, limit, filters });
    const response = await fetchScreens(offset, limit, filters, accessToken); // Adjust fetchScreens to accept filters
    // Include offset in the payload to distinguish between initial load and subsequent loads
    return { screens: response, offset };
  }
);
const screensSlice = createSlice({
  name: "screens",
  initialState: {
    screens: [],
    qrCodeData: [],
    status: "idle",
    error: null,
  },

  reducers: {
    // Add a new reducer for appending screens
    appendScreens: (state, action) => {
      state.screens = [...state.screens, ...action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getScreens.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getScreens.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Check if it's an initial load or subsequent load by checking offset
        if (action.payload.offset === 0) {
          state.screens = action.payload.screens;
        } else {
          // It's a subsequent load, append the screens
          state.screens = [...state.screens, ...action.payload.screens];
        }
      })
      .addCase(getScreens.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});


export default screensSlice.reducer;
