import React from 'react';
import "./Input.css";

const Input = ({ id, name, placeholder, value, options = [], type = 'text', multiple = false, onChange, required = false }) => {
    if (type === 'select') {
        return (
            <div className="select-container">
                <select id={id} name={name} value={value} onChange={onChange} required={required} className="talkswabs-select">
                    <option value="" disabled>{placeholder}</option>
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>{option.label}</option>
                    ))}
                </select>
            </div>
        );
    }

    if (multiple) {
        return (
            <div className="multi-select-options">
                {options.map((option, index) => (
                    <div key={index} className="multi-select-option">
                        <input
                            type="checkbox"
                            id={`${id}-${index}`}
                            checked={value.includes(option.value)}
                            onChange={(e) => {
                                const selectedValues = [...value];
                                if (e.target.checked) {
                                    selectedValues.push(option.value);
                                } else {
                                    const valueIndex = selectedValues.indexOf(option.value);
                                    if (valueIndex >= 0) {
                                        selectedValues.splice(valueIndex, 1);
                                    }
                                }
                                onChange(selectedValues);
                            }}
                        />
                        <label htmlFor={`${id}-${index}`}>{option.label}</label>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className="input-container">
            <input
                type={type}
                id={id}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                required={required}
                className="talkswabs-input"
            />
        </div>
    );
};

export default Input;
