import React, {useState, Fragment} from 'react';
import './SpecimensRow.css';
import ActionTray from "../../../modals/action_tray/ActionTray";
import CopyToClipboard from "../../../../utils/copyToClipboard";
import formatDate from "../../../../utils/dateUtils";
import {ActionTrayIcons} from "../../../../assets/icons";
import NoteList from "../../../modals/notes/note_list/NoteList";
import Select from "react-select";
import {makePOSTRequest} from "../../../../services/utils/httpUtils";
import {showMessageModal} from "../../../../store/modals/modalsSlice";
import {useDispatch} from "react-redux";


const SpecimensRow = ({userRoles, screenData, onEdit}) => {
    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState(false); // State to handle row expansion
    const [screenDataState, setScreenData] = useState(screenData);

    const screen = screenData.screen || screenData;
    const { specimen_notes, laboratory_results } = screenData.screen_details || {}; // Safely extract if screen_details exists

    const statusMapping = {
        1: 'ORDER GENERATED',
        2: 'SHIPPED',
        3: 'IN TRANSIT',
        4: 'DELIVERED',
        5: 'REGISTERED IN LAB',
        6: 'SENT TO MRO',
        7: 'ATTENTION REQUIRED',
        8: 'PENDING REVIEW',
        9: 'APPROVED',
        10: 'PENDING UPLOAD',
        11: 'COMPLETE',
        12: 'SEND TO LAB',
        13: 'SEND TO MRO',
        14: 'SENT TO LAB'
    };


    const handleViewEditModel = () => {
        if (onEdit) {
            onEdit(screen);
        }
    };

    const iconsData = [
        {
            icon: ActionTrayIcons.EditIcon,
            alt: "Edit",
            action: handleViewEditModel,
            permission: "Labb.Edit"
        },
    ];

    const getStatusText = (id) => {
        return statusMapping[id] || 'UNKNOWN STATUS';
    };

    const handleDropdownChange = async (screenData, newStatusKey) => {
        const accessToken = sessionStorage.getItem("accessToken");
        try {
            const newStatusKeyNumber = parseInt(newStatusKey);
            const newStatusValue = statusMapping[newStatusKeyNumber];

            const formData = new FormData();
            formData.append('mro_result_id', screenData.mro_result_id);
            formData.append('internal_status_id', newStatusKeyNumber);

            await makePOSTRequest(
                '/api/v1/status/update',
                formData,
                accessToken
            );

            setScreenData(prevData => ({
                ...prevData,
                internal_status: newStatusValue,
            }));
            dispatch(showMessageModal({message: "Status updated successfully!", link: null}));
        } catch (error) {
            console.error('Error updating status:', error);
            dispatch(showMessageModal({message: "Error updating status", link: null}));
        }
    };

    const statusOptions = Object.entries(statusMapping).map(([key, value]) => ({
        value: key,
        label: value,
    }));

    const toggleRowExpansion = () => setIsExpanded(!isExpanded);

    const internalStatusId = screen.internal_status_id; // Ensure internalStatusId is defined

    return (
        <Fragment>
            <tr className={`specimen-row ${isExpanded ? 'specimen-row-expanded' : ''}`} onClick={toggleRowExpansion}>
                <th className="specimen-row-header-cell">
                    <div className="specimen-row-cell-content" title={`${screen?.device_id}`}>
                        {screen?.device_id}
                        <CopyToClipboard text={`${screen?.device_id}`} rowData={screen}/>
                    </div>
                </th>
                <th className="specimen-row-cell" title={screen?.client_name}>{screen?.client_name}</th>
                <th className="specimen-row-cell">{formatDate(screen?.collected_on)}</th>
                <th className="specimen-row-cell">{screen?.status}</th>
                <th className="specimen-row-cell">{screen?.labb_cost}</th>
                <th className="specimen-row-cell">{screen?.retail_price}</th>
                <th className="specimen-row-cell">{screen?.retail_price - screen?.labb_cost}</th>
                <td className="specimen-row-cell" onClick={(e) => e.stopPropagation()}>
                    <ActionTray iconsData={iconsData} rowData={screen} userRoles={userRoles} onEdit={onEdit}/>
                </td>
            </tr>
            {isExpanded && (
                <tr className="specimen-row-detail">
                    <td className="specimen-row-detail-cell">
                        <p>{screen.product_name}</p>
                    </td>
                    <td className="specimen-row-detail-cell">
                        <p>{screen.organization_name}</p>
                    </td>
                    <td className="specimen-row-detail-cell">
                        <p>{screen.service_level}</p>
                    </td>
                    <td className="specimen-row-detail-cell">
                        <Select
                            options={statusOptions}
                            value={statusOptions.find(option => option.value === String(internalStatusId))}
                            onChange={(selectedOption) => handleDropdownChange(screenData, selectedOption.value)}
                            placeholder="Select Status"
                        />
                    </td>
                    <td className="specimen-row-detail-cell">
                        <p>{`${screen.collector_first_name} ${screen.collector_last_name}`}</p>
                    </td>
                    <td className="specimen-row-detail-cell">
                        <p>{screen.panel_name}</p>
                    </td>
                    <td className="specimen-row-detail-cell">
                        <NoteList rowData={specimen_notes || []} />
                    </td>
                </tr>
            )}
        </Fragment>
    );
};

export default SpecimensRow;
