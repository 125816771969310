// freddy-react/src/store/shopify/cart/shopifySpeedCartSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchSpeedCart, createSpeedCart, updateSpeedCart, removeItemFromCart, addItemToCart } from "../../../services/api/v1/speedCartService";

// Thunk to fetch the cart without access token
export const fetchCart = createAsyncThunk(
    "speedCart/fetchCart",
    async ({ cartId }, thunkAPI) => {
        return await fetchSpeedCart(cartId);
    }
);

// Thunk to create a new cart without access token
export const createCart = createAsyncThunk(
    "speedCart/createCart",
    async (_, thunkAPI) => {
        return await createSpeedCart();
    }
);

// Thunk to update an existing cart using POST
export const updateCart = createAsyncThunk(
    "speedCart/updateCart",
    async ({ cartId, lineItemId, quantity }, thunkAPI) => {
        const formData = new FormData();
        formData.append("shopify_speed_cart_id", cartId);
        formData.append("shopify_speed_line_item_id", lineItemId);
        formData.append("shopify_speed_quantity", quantity);
        return await updateSpeedCart(formData);
    }
);

// Thunk to add an item to the cart
export const addItem = createAsyncThunk(
    "speedCart/addItem",
    async ({ cartId, variantId, quantity }, thunkAPI) => {
        return await addItemToCart(cartId, variantId, quantity);
    }
);

// Thunk to remove an item from the cart
export const removeItem = createAsyncThunk(
    "speedCart/removeItem",
    async ({ cartId, lineItemId }, thunkAPI) => {
        return await removeItemFromCart(cartId, lineItemId);
    }
);

const speedCartSlice = createSlice({
    name: "speedCart",
    initialState: {
        shopifySpeedCartId: null,
        isCartModalOpen: false,
        cookieConsentAccepted: false, // <-- Added this line
        cart: null,
        status: "idle",
        error: null,
    },

    reducers: {
        toggleSpeedCartModal: (state, action) => {
            state.isCartModalOpen = action.payload;
        },
        acceptCookieConsent: (state) => {
            state.cookieConsentAccepted = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCart.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCart.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.cart = action.payload;
            })
            .addCase(fetchCart.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(createCart.pending, (state) => {
                state.status = "loading";
            })
            .addCase(createCart.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.cart = action.payload;
            })
            .addCase(createCart.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(updateCart.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateCart.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.cart = action.payload;
            })
            .addCase(updateCart.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(addItem.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addItem.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.cart = action.payload; // Update cart after adding an item
            })
            .addCase(addItem.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(removeItem.pending, (state) => {
                state.status = "loading";
            })
            .addCase(removeItem.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.cart = action.payload; // Update cart after removing an item
            })
            .addCase(removeItem.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export default speedCartSlice.reducer;
export const { toggleSpeedCartModal, acceptCookieConsent } = speedCartSlice.actions;
