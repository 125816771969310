// freddy-react/src/store/autobot/screeners/screenersSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allClients: null,
    selectedClient: null,
};

const clientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        setAllClients: (state, action) => {
            state.allClients = action.payload;
        },
        selectClient: (state, action) => {
            state.selectedClient = action.payload;
        },
    }
});

export const { setAllClients, selectClient } = clientsSlice.actions;

export default clientsSlice.reducer;
