// freddy-react/src/App.js

import React, {useEffect} from 'react';
import './App.css';
import AuthenticatedWrapper from './components/user/authenticated/AuthenticatedWrapper';
import UnauthenticatedWrapper from "./components/user/unauthenticated/UnauthenticatedWrapper";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import {Provider} from "react-redux";
import store from "./store/store";

/**
 * Method to render the main application component with authentication handling based on the provided instance and window location hostname.
 *
 * @param {Object} param - Object containing the instance to determine authentication context.
 * @param {Object} param.instance - Instance object to provide authentication context.
 * @return {JSX.Element} The rendered main application component with authentication handling based on the provided instance.
 */
function App({ instance }) {
    const hostname = window.location.hostname;

    return (
        <Provider store={store}>
            <div className="App">
                <main>
                    <>
                        {/* Default wrapper for other domains */}
                        <AuthenticatedTemplate>
                            <AuthenticatedWrapper instance={instance} hostname={hostname} />
                        </AuthenticatedTemplate>

                        <UnauthenticatedTemplate>
                            <UnauthenticatedWrapper instance={instance} hostname={hostname} />
                        </UnauthenticatedTemplate>
                    </>
                </main>
            </div>
        </Provider>
    );
}

export default App;

