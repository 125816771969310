import React from "react";
import "./InvoiceItem.css";

const InvoiceItem = ({ invoice }) => {
    const formatCurrency = (amount) => {
        const num = parseFloat(amount);
        return isNaN(num) ? "$0.00" : `$${num.toFixed(2)}`;
    };

    const netGainLoss = invoice.client_total - invoice.labb_total;

    if (!invoice) {
        return <div className="invoice-item">No invoice data available</div>;
    }

    // Group line items by specimen_id or device_id
    const groupedLineItems = invoice.line_items.reduce((acc, item) => {
        const key = item.specimen_id || item.device_id;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(item);
        return acc;
    }, {});

    return (
        <div className="invoice-item">
            <div className="invoice-header">
                <span className="invoice-number">{invoice.invoice_number || "N/A"}</span>
                <div className="invoice-details">
                    <span className="invoice-date">{invoice.date || "N/A"}</span>
                    <span className="invoice-status">{invoice.status || "N/A"}</span>
                    <span className="invoice-total">{formatCurrency(invoice.client_total || 0)}</span>
                    <span className="invoice-client-site-location">{invoice.location_name || "N/A"}</span>
                    <a href={invoice.invoice_url || "#"} target="_blank" rel="noopener noreferrer">VIEW INVOICE</a>
                </div>
            </div>
            <table className="line-items-table">
                <thead>
                <tr>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Labb Cost</th>
                    <th>Retail Price</th>
                    <th>Specimen ID</th>
                    <th>Panel Codes</th>
                </tr>
                </thead>
                <tbody>
                {Object.entries(groupedLineItems).map(([key, items], index) => (
                    <React.Fragment key={key}>
                        {items.map((item, subIndex) => (
                            <tr key={subIndex} className={`invoice-item-group-${index % 2} ${subIndex === 0 ? 'group-start' : ''}`}>
                                <td className="invoice-item-description-row">{item.product_name || "N/A"}</td>
                                <td className="invoice-item-quantity-row">{item.quantity || 1}</td>
                                <td className="invoice-item-labb-cost-row">{formatCurrency(item.labb_cost || 0)}</td>
                                <td className="invoice-item-retail-price-row">{formatCurrency(item.retail_price || 0)}</td>
                                <td className="invoice-item-specimen-id-row">{item.specimen_id || item.device_id || "N/A"}</td>
                                <td className="invoice-item-panel-codes-row">
                                    {item.panel_codes?.map((panelCode, idx) => (
                                        <span key={idx}>{panelCode}</span>
                                    ))}
                                </td>
                            </tr>
                        ))}
                    </React.Fragment>
                ))}
                </tbody>
            </table>
            <div className="invoice-cost-summary">
                <div className="cost-bubble invoice-client-cost">{formatCurrency(invoice.client_total)}</div>
                <div className={`cost-bubble invoice-net-gain-loss ${netGainLoss < 0 ? "net-loss" : "net-gain"}`}>
                    {formatCurrency(netGainLoss)}
                </div>
                <div className="cost-bubble invoice-labb-cost">{formatCurrency(invoice.labb_total)}</div>
            </div>
        </div>
    );
};

export default InvoiceItem;
