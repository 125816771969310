import React from "react";
import "./PublicFreddyDashboard.css";
import PublicFreddyLogin from "../../../components/user/public_freddy_login/PublicFreddyLogin";
import { useSelector } from "react-redux";
import PublicFreddyHeader from "../../../components/headers/public_freddy_header/PublicFreddyHeader";
import PublicFreddyLogo from "../../../assets/logo/PublicFreddyLogo.png";
import { useShopifyLogout } from "../../../utils/authUtils";
import LoadingSpinningCup from "../../loading/Loading";



const PublicFreddyDashboard = () => {
    const token = useSelector((state) => state.publicUser?.publicAccessToken);
    const loading = useSelector((state) => state.publicUser?.loading);
    const onLogout = useShopifyLogout();

    if (loading) {
        return (
            <div className="public-freddy-dashboard-container">
                <div className="dashboard-content-container">
                    <LoadingSpinningCup />
                </div>
            </div>
        );
    }

    if (!token) {
        return (
            <div className="public-freddy-dashboard-container">
                <div className="dashboard-content-container">
                    <PublicFreddyLogin />
                </div>
            </div>
        );
    }

    return (
        <div className="public-freddy-dashboard-container">
            <PublicFreddyHeader logoSrc={PublicFreddyLogo} onLogout={onLogout} />
            <div className="dashboard-content-container">
                <h1>Welcome!</h1>
            </div>
        </div>
    );
};

export default PublicFreddyDashboard;
