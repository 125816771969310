import React, { useState } from "react";
import { useSelector } from "react-redux";
import {PrimaryButton} from "../../../buttons/primary_button/PrimaryButton";

const ShopifyManageOrders = () => {
    const [orderData, setOrderData] = useState({
        email: '',
        lineItems: [],
    });

    const shopifyAuth = useSelector((state) => state.shopifyAuth.shopifyAuth);
    const accessToken = shopifyAuth?.access_token;

    const createOrder = async () => {
        const query = `
          mutation {
            orderCreate(input: {
              email: "${orderData.email}",
              lineItems: ${JSON.stringify(orderData.lineItems)}
            }) {
              order {
                id
                email
              }
              userErrors {
                field
                message
              }
            }
          }
        `;

        const response = await fetch('/admin/api/2024-01/graphql.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Access-Token': accessToken,
            },
            body: JSON.stringify({ query }),
        });

        const responseData = await response.json();
        console.log(responseData);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOrderData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div>
            <h2>Create Order</h2>
            <input
                type="email"
                name="email"
                value={orderData.email}
                placeholder="Customer Email"
                onChange={handleChange}
            />
            <PrimaryButton onClick={createOrder}>Create Order</PrimaryButton>
        </div>
    );
};

export default ShopifyManageOrders;
