// freddy-react/src/services/api/v1/screenService.js

import {makeGETRequest, makePOSTRequest} from "../../utils/httpUtils";

export const fetchScreens = (offset = 0, limit = 20, filters = {}, accessToken) => {
    // Helper function to construct query string from filters
    const constructFilterQueries = (filters) => {
        return Object.entries(filters)
            .filter(([key, value]) => value !== undefined && value !== null)
            .map(([key, value]) => `${encodeURIComponent(key)}=${value}`)
            .join("&");
    };

    let filterQueries = constructFilterQueries(filters);
    if (filterQueries) {
        filterQueries = "&" + filterQueries;
    }
console.log({filters});
//&date_range=2019-03-20T00:00:00,2019-03-24T00:00:00
    const filteredGetUrl = `/api/v1/screens/detail?offset=${offset}&limit=${limit}${filterQueries}`;
    return makeGETRequest(filteredGetUrl, accessToken);
};

export const fetchScreensCsv = (offset = 0, limit = 20, filters = {}, accessToken) => {
    // Helper function to construct query string from filters
    const constructFilterQueries = (filters) => {
        return Object.entries(filters)
            .filter(([key, value]) => value !== undefined && value !== null)
            .map(([key, value]) => `${encodeURIComponent(key)}=${value}`)
            .join("&");
    };

    let filterQueries = constructFilterQueries(filters);
    if (filterQueries) {
        filterQueries = "&" + filterQueries;
    }
console.log({filters});
//&date_range=2019-03-20T00:00:00,2019-03-24T00:00:00
    const filteredGetUrl = `/api/v1/screens/export_bill_to_csv?offset=${offset}&limit=${limit}${filterQueries}`;
    return makeGETRequest(filteredGetUrl, accessToken);
};

export const fetchScreenFullDetails = (deviceIdHash, accessToken) => {
    return makePOSTRequest(`/api/v1/screen/details/${deviceIdHash}/full`, null, accessToken);
};
export const updateScreen = (deviceIdHash, updateScreenData, accessToken) => {
    return makePOSTRequest(`/api/v1/screen/details/${deviceIdHash}/update`, updateScreenData, accessToken);
};

export const convertSpecimenIdToHash = (specimenId, accessToken) => {
    return makePOSTRequest(`/api/v1/screen/convert`, specimenId, accessToken);
};

export const fetchLocationDetails = async (device_id, accessToken) => {
    const deviceId = {device_id: device_id}
    let deviceIdHash = await convertSpecimenIdToHash(deviceId, accessToken);

    return makeGETRequest(`/api/v1/screen/details/${deviceIdHash}/location/all`, accessToken);
};

export const fetchDeviceIds = async (accessToken) => {
    return makeGETRequest(`/api/v1/screens/list`, accessToken);
};
