import MasterBox from './Master.svg';
import ThreeBox from './Master.svg';
import MailerBox from './Mailer.svg';
import HalfBox from './Half.svg';

export const BoxStyles = {
    MasterBox,
    ThreeBox,
    MailerBox,
    HalfBox,
};