import React, { useEffect, useState } from "react";
import "./SpeedSwabsOverview.css";
import SpeedSwabsFeatureCards from "../feature_cards/SpeedSwabsFeatureCards";
import SpeedSwabsHero from "../hero/SpeedSwabsHero";
import SpeedSwabsHeroGraphic from "../hero_graphic/SpeedSwabsHeroGraphic";
import SpeedSwabsHowToUse from "../how_to_use/SpeedSwabsHowToUse";
import SpeedSwabsDoNotOverpay from "../do_not_overpay/SpeedSwabsDoNotOverpay";


const SpeedSwabsOverview = () => {
    const [scrollY, setScrollY] = useState(0);
    const [rotateAngle, setRotateAngle] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setScrollY(scrollPosition);
            const scrollFactor = 10; // Reduced scroll factor for more pronounced rotation

            let angle = -(scrollPosition / scrollFactor);

            // Updated limits for more significant rotation
            if (angle > 60) angle = 60;
            if (angle < -75) angle = -75;

            setRotateAngle(angle);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="speed-swabs-overview-container">
            {/* Fixed Hero Graphic */}
            <div className="speed-swabs-hero-graphic-container">
                <SpeedSwabsHeroGraphic scrollY={scrollY} rotateAngle={rotateAngle} />
            </div>

            {/* Hero Text Content */}
            <div className="feature-section-hero-text-wrapper">
                <SpeedSwabsHero />
            </div>

            {/* Feature Cards Section */}
            <div className="feature-section-wrapper">
                <SpeedSwabsFeatureCards />
            </div>

            {/* How To Use Section */}
            <div className="how-to-use-section-wrapper">
                <SpeedSwabsHowToUse />
            </div>

            {/* Dont Overpay Section */}
            <div className="do-not-overpay-section-wrapper">
                <SpeedSwabsDoNotOverpay />
            </div>
        </div>
    );
};

export default SpeedSwabsOverview;
