// freddy-react/src/services/api/v1/userService.js

import {makeGETRequest, makePOSTRequest, makeUnAuthPOSTRequest} from "../../utils/httpUtils";

export const authenticateUser = (accessToken) => {
    return makeGETRequest(`/api/v1/user/me`, accessToken.token);
};
export const registerUser = (formData, accessToken) => {
    return makePOSTRequest(`/api/v1/user/me`, formData, accessToken);
};

export const getCollectorByEmail = (formData) => {
    return makeUnAuthPOSTRequest(`/api/v1/collector/me`, formData);
};