import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import "./QuestAccountOrganizationRow.css"
import {approveQuestSubAccount} from "../../../../../../store/external/questAccountsSlice";
import {saveLaboratoryAccountRecords} from "../../../../../../store/laboratory/laboratoryAccountSlice";
import {GenericIcons} from "../../../../../../assets/icons";
import {IconButton} from "../../../../../buttons/icon_button/IconButton";

const QuestSubAccountRow = ({
    selectedMainClientAccount,
    filteredQuestSubAccount,
    updateFilteredQuestSubAccount
                            }) => {
    const dispatch = useDispatch();
    const allOrganizationData = useSelector((state) => state.organizations.allOrganizationData);
    const matchedOrganizationData = useSelector((state) => state.organizations.matchedOrganizationData);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [organizationOptions, setOrganizationOptions] = useState([]);

    useEffect(() => {
        const options = allOrganizationData.map(organization => ({
            value: organization.organization_id,
            label: organization.organization_name,
        }));

        // Assuming each sub-account can be uniquely identified by "Client Number"
        // Find the updated sub-account from matchedOrganizationData
        const updatedSubAccount = matchedOrganizationData.find(match => match["Client Number"] === filteredQuestSubAccount["Client Number"]);

        const bestMatchedOrganization = options.find(option => String(option.value).trim() === String(updatedSubAccount?.ss_organization_id || null).trim());
        setOrganizationOptions(options);
        setSelectedOrganization(bestMatchedOrganization);
    }, [allOrganizationData, matchedOrganizationData, filteredQuestSubAccount]);

    const handleDropdownChange = (selectedOption) => {
        setSelectedOrganization(selectedOption);
    };

    const handleApproveAccount = () => {
        if (selectedOrganization) {
            dispatch(saveLaboratoryAccountRecords({
                client_id: selectedMainClientAccount.value,
                ss_organization_id: selectedOrganization.value,
                client_name: filteredQuestSubAccount["Client Name"],
                client_address: filteredQuestSubAccount["Client Address 1"],
                client_number: filteredQuestSubAccount["Client Number"],
            })).then(() => {
                dispatch(approveQuestSubAccount({ organization_id: selectedOrganization.value }));
                updateFilteredQuestSubAccount(filteredQuestSubAccount);
            });
        }
    };

    return (
        <div className={`quest-account-row ${filteredQuestSubAccount.is_draft ? "is-draft" : "is-not-draft"}`}>
            <div className="custom-dropdown">
                <Select
                    options={organizationOptions}
                    value={selectedOrganization}
                    className="custom-select"
                    classNamePrefix="react-select"
                    placeholder="Select Lab Station Client"
                    onChange={handleDropdownChange}
                />
            </div>
            <div className="client-details">
                <span>{filteredQuestSubAccount["Client Name"]}</span>
                <span>{filteredQuestSubAccount["Client Address 1"]}</span>
                <span>{filteredQuestSubAccount["Client Number"]}</span>
            </div>
            <div>
                {filteredQuestSubAccount.is_draft && (
                    <IconButton
                        defaultIcon={GenericIcons.CheckIcon}
                        hoverIcon={GenericIcons.CheckIcon}
                        onClick={handleApproveAccount}
                    />
                )}
            </div>
        </div>
    );
};

export default QuestSubAccountRow;