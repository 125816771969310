import {makePOSTRequest} from "../../utils/httpUtils";


export const fetchCreateQuickbooksCustomer = ({quickbooksAccessToken, clientId, accessToken}) => {
    // Prepare the form data
    const formData = new FormData();
    formData.append('quickbooks_access_token', quickbooksAccessToken);
    formData.append('client_id', clientId);
    return makePOSTRequest(`/api/v1/external/quickbooks/customers/create/new`, formData, accessToken);
};
