import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {fetchQuestServices} from "../../services/api/v1/questServicesService";


export const getQuestServices = createAsyncThunk(
    "questServices/getQuestServices",
    async ({ accessToken }, thunkAPI) => {
        return await fetchQuestServices(accessToken);
    }
);
const questServicesSlice = createSlice({
    name: "questServices",
    initialState: {
        questPanels: [],
        status: "idle",
        error: null,
    },

    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getQuestServices.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getQuestServices.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.questPanels = action.payload;
            })
            .addCase(getQuestServices.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});


export default questServicesSlice.reducer;
