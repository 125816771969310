import React from 'react';
import './PickListItem.css';

const PickListItem = ({ item }) => {
    return (
        <div className="pick-list-item">
            <div className="item-quantity">{item.quantity}</div>
            <div className="product-name">{item.product_name}</div>
        </div>
    );
};

export default PickListItem;