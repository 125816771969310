import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchQuickbooksAuthToken } from "../../services/api/v1/quickbooksAuthTokenService";
import {fetchCreateQuickbooksCustomer} from "../../services/api/v1/quickbooksCustomerService";

const initialState = {
    quickbooksCustomer: [],
    isLoading: false,
    error: null,
};

export const createQuickbooksCustomer = createAsyncThunk(
    'external/fetchCreateQuickbooksCustomer',
    async ({ quickbooksAccessToken, clientId }, thunkAPI) => {
        try {
            const accessToken = sessionStorage.getItem("accessToken");
            await fetchCreateQuickbooksCustomer({ quickbooksAccessToken, clientId, accessToken });
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data || error.message);
        }
    }
);

const quickbooksCustomerSlice = createSlice({
    name: 'quickbooksCustomer',
    initialState,
    reducers: {
        clearQuickbooksCustomer: (state) => {
            state.quickbooksCustomer = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createQuickbooksCustomer.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createQuickbooksCustomer.fulfilled, (state, action) => {
                state.quickbooksCustomer = action.payload;
                state.isLoading = false;
            })
            .addCase(createQuickbooksCustomer.rejected, (state, action) => {
                state.error = action.payload || action.error.message;
                state.quickbooksCustomer = null;
                state.isLoading = false;
            });
    }
});

export const { clearQuickbooksCustomer } = quickbooksCustomerSlice.actions;
export default quickbooksCustomerSlice.reducer;
