import React, {useState} from 'react';
import './ProductDetails.css';
import {config} from "../../../Constants";
import {default as axios} from "axios";

const ProductDetails = ({
                            screenDetails,
                        }) => {
    return (
        <div className="product-details">
            <div className="product-header">
                <p className="product-header-text">Product</p>
            </div>
            <div className="dark-grey-strip"/>
            <div className="product-info">
                <p>{screenDetails?.productDetails?.product_name}</p>
            </div>
            <div className="product-info">
                <p>{screenDetails?.productDetails?.product_lot}</p>
            </div>
        </div>
    );
};

export default ProductDetails;
