import React from 'react';
import './BoxStyleOptions.css';
import {BoxStyles} from "../../../../assets/boxes";

const BoxStyleOptions = ({ onSelect }) => {
    return (
        <div className="box-style-options">
            <img src={BoxStyles.MasterBox} alt="MasterBox" className="box-image-large" onClick={() => onSelect('MasterBox')} />
            <img src={BoxStyles.HalfBox} alt="HalfBox" className="box-image-large" onClick={() => onSelect('HalfBox')} />
            <img src={BoxStyles.MailerBox} alt="MailerBox" className="box-image-large" onClick={() => onSelect('MailerBox')} />
        </div>
    );
};

export default BoxStyleOptions;
