// src/store/invoices/questInvoiceSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const desertToxInvoicesSlice = createSlice({
    name: 'desertToxInvoices',
    initialState: {
        invoices: [],
    },
    reducers: {
        setDesertToxInvoices: (state, action) => {
            state.invoices = action.payload;
        },
        clearDesertToxInvoices: (state) => {
            state.invoices = [];
        },
    },
});

export const { setDesertToxInvoices, clearDesertToxInvoices } = desertToxInvoicesSlice.actions;

export default desertToxInvoicesSlice.reducer;