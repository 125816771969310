// freddy-react/src/components/labb_dashboard/LabbDashboard.js
import React from "react";
import "./LabbDashboard.css";
import {PrimaryButton} from "../../../components/buttons/primary_button/PrimaryButton";
import {getShopifyOAuthUrl} from "../../../components/user/sign_in/SignIn";

const LabbDashboard = () => {
    const handleLogin = async () => {
        const hostname = window.location.hostname;
        try {
            window.location.href = await getShopifyOAuthUrl(hostname);
        } catch (error) {
            console.error("Failed to initiate Shopify OAuth flow:", error);
        }
    };

    return (
        <div className="labb-dashboard-container">
            <div className="labb-dashboard-content">
                <h1 className="dashboard-title">Welcome to Labb</h1>
                <p className="dashboard-message">
                    Please log in using your Labb Station email to access your personalized account.
                </p>
                <div className="login-button-container">
                    <PrimaryButton onClick={handleLogin}>
                        LOGIN
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
};

export default LabbDashboard;