import "./PublicFooter.css";
import React from "react";

const PublicFooter = () => {
    return (
        <footer className="public-footer">
            <p>© 2024 Labb | All rights reserved.</p>
        </footer>
    );
};

export default PublicFooter;
