import React, {useState, useEffect} from 'react';
import './Screener.css';
import {GenericIcons} from "../../../../../assets/icons";
import {IconButton} from "../../../../buttons/icon_button/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {getScreeners} from "../../../../../store/screener/screenerSlice";

const Screener = ({device_id, screenerDetails, onScreenerChange}) => {
    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);

    const LIMIT = 20;
    const [isEditing, setIsEditing] = useState(false);
    const [screeners, setScreeners] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedScreener, setSelectedScreener] = useState(screenerDetails);
    const [originalScreener, setOriginalScreener] = useState(screenerDetails);

    const dispatch = useDispatch();
    const currentScreen = useSelector(state => state?.screen?.screen || []);

    useEffect(() => {
        if (isEditing && currentScreen.length > 0) {
            dispatch(getScreeners({client_id: currentScreen[0].client_id}))
                .unwrap()
                .then((fetchedScreeners) => {
                    setScreeners(fetchedScreeners);

                    // Find the matched screener based on the names
                    const matchedScreener = fetchedScreeners?.find(
                        screener => screener?.collector_first_name === originalScreener?.collector_first_name &&  screener?.collector_last_name === originalScreener?.collector_last_name
                    );
                    if (matchedScreener) {
                        setOriginalScreener(matchedScreener);
                    }
                })
                .catch(error => console.error("Error fetching screeners:", error));
        }
    }, [isEditing, currentScreen]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };


    const handleScreenerSelect = (screener) => {

        console.log("originalScreener", originalScreener);

        const updatedScreenerData = {
            original_collector_id: originalScreener?.collector_id,
            collector_id: screener?.collector_id,
            collector_first_name: screener?.collector_first_name,
            collector_last_name: screener?.collector_last_name
        };

        setSelectedScreener(updatedScreenerData);
        onScreenerChange(updatedScreenerData);
        setIsEditing(false);
    };

    const handleScroll = (e) => {
        const dropdown = e.target;
        if (dropdown.scrollHeight - dropdown.scrollTop === dropdown.clientHeight && hasMore) {
            setOffset(prev => prev + LIMIT);
        }
    }


    const toggleEdit = (e) => {
        e.preventDefault();
        setIsEditing(prev => !prev);
    };


    return (
        <div>
            <section className="screener">
                <div className="screener_header">
                    <p className="screener_header_text">Collector</p>
                    {!isEditing
                        ? <IconButton onClick={toggleEdit} defaultIcon={GenericIcons.EditIcon}
                                      hoverIcon={GenericIcons.EditIcon}/>
                        : <IconButton onClick={toggleEdit} defaultIcon={GenericIcons.CloseIcon}
                                      hoverIcon={GenericIcons.ExitIcon}/>
                    }
                </div>
                <div className="dark_grey_strip"/>
                <div className="screener_info_section">
                    {isEditing ? (
                        <>
                            <input
                                className="screener-searchable-dropdown"
                                type="text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                onFocus={() => setShowDropdown(true)}
                                placeholder="Search screener..."
                            />
                            {showDropdown && (
                                <div className="dropdown-content" onScroll={handleScroll}>
                                    {
                                        screeners?.length ? (
                                            screeners?.filter(screener =>
                                                screener?.collector_first_name?.toLowerCase().includes(searchQuery.toLowerCase())
                                            ).map(screener => (
                                                <div key={screener.collector_id}
                                                     onClick={() => handleScreenerSelect(screener)}
                                                     className="screener_dropdown_row">
                                                    <p className="screener_dropdown_row_text">
                                                        {screener?.collector_first_name} {screener?.collector_last_name}
                                                    </p>
                                                </div>
                                            ))
                                    ) : (
                                    <div className="screener_dropdown_row">
                                        <p className="screener_dropdown_row_text">No screener found</p>
                                    </div>
                                    )

                                    }

                                </div>
                            )}
                        </>
                    ) : (
                        <p className="screener_name">
                            {selectedScreener.collector_first_name} {selectedScreener.collector_last_name}
                        </p>
                    )}
                </div>
            </section>
        </div>
    );
};

export default Screener;
