import React from 'react';
import { useSelector } from 'react-redux';
import './NotificationModal.css';
import NotificiationsList from "./notificiations_list/NotificiationsList";

const NotificationModal = () => {

    const isVisible = useSelector(state => state.notifications.isModalOpen);
    const notifications = useSelector(state => state.notifications.notifications);




    if (!isVisible) return null;

    return (
        <div className="notification-modal">
            <div className="notification-modal-header">
               <h4>NOTIFICATIONS</h4>
            </div>
            <div className="notification-modal-content">
                <NotificiationsList notifications={notifications} />
            </div>
        </div>
    );
};

export default NotificationModal;
