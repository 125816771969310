import React, { useState, useEffect, useMemo } from 'react';
import './IconCard.css';
import { useSelector } from "react-redux";
import { PrimaryButton } from "../../buttons/primary_button/PrimaryButton";
import { HeaderIcons } from "../../../assets/icons";
import {IconButton} from "../../buttons/icon_button/IconButton"; // Assuming this is where PersonIcon is imported from

const UserIconCard = ({ handleCollectorLogin, onLogout }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [initials, setInitials] = useState('');

    const customerDetails = useSelector(state => state.shopifyUser.customerDetails);
    const lssCustomerDetails = useSelector(state => state.shopifyUser.lssCollectorDetails);

    useEffect(() => {
        if (lssCustomerDetails) {
            const firstNameInitial = lssCustomerDetails.first_name?.charAt(0).toUpperCase() || '';
            const lastNameInitial = lssCustomerDetails.last_name?.charAt(0).toUpperCase() || '';
            setInitials(`${firstNameInitial}${lastNameInitial}`);
        }
    }, [lssCustomerDetails]);

    // Generate a random color for the circle
    const backgroundColor = useMemo(() => {
        const colors = ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#FFC300', '#900C3F'];
        return colors[Math.floor(Math.random() * colors.length)];
    }, []);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <div className="user-icon-card">
            <div
                className="initials-circle"
                style={{ backgroundColor }}
                onClick={toggleDropdown}
            >
                {customerDetails && lssCustomerDetails ? (
                    initials
                ) : (
                    <IconButton defaultIcon={HeaderIcons.PersonIcon} hoverIcon={HeaderIcons.PersonIcon} onClick={handleCollectorLogin}  />
                )}
            </div>
            {isDropdownOpen && (
                <div className="dropdown-menu">
                    {customerDetails && lssCustomerDetails ? (
                        <>
                            <p>{customerDetails?.emailAddress?.emailAddress}</p>
                            <PrimaryButton onClick={onLogout}>
                                LOGOUT
                            </PrimaryButton>
                        </>
                    ) : null }
                </div>
            )}
        </div>
    );
};

export default UserIconCard;
