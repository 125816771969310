// freddy-react/src/services/api/v1/donorService.js

import { makeGETRequest } from "../../utils/httpUtils";

export const fetchDonorDetails = (criteria, accessToken, offset, limit ) => {
    // Construct the query parameters based on criteria
    const queryParams = Object.entries(criteria)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");

    // Append offset and limit to the query parameters
    const finalQueryParams = `${queryParams}&offset=${offset}&limit=${limit}`;
    return makeGETRequest(`/api/v1/donors/detail?${finalQueryParams}`, accessToken);
};


