import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './ClientPricing.css';
import { toggleClientBillingDetailsModal } from '../../../store/clients/clientBillingDetailsSlice';
import {IconButton} from "../../buttons/icon_button/IconButton";
import {GenericIcons} from "../../../assets/icons";

const ClientPricingModel = () => {
    const isVisible = useSelector(state => state.clientBillingDetails.isModalVisible);
    const pricingDetails = useSelector(state => state.clientBillingDetails.clientBillingDetailsData);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(toggleClientBillingDetailsModal(false));
    };

    if (!isVisible) return null;

    return (
        <div className="pricing-modal-overlay">
            <div className="pricing-modal">
                <div className="pricing-modal-header">
                    <IconButton hoverIcon={GenericIcons.ExitIcon} defaultIcon={GenericIcons.CloseIcon} onClick={handleClose}/>
                </div>
                <div className="pricing-modal-content">
                    {pricingDetails?.map((detail, index) => (
                        <div key={index} className="pricing-detail">
                            <div>{detail.service_name}</div>
                            <div>{detail.service_panel_code}</div>
                            <div>${detail.client_price.toFixed(2)}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ClientPricingModel;
