import React from 'react';
import './Screeners.css';

function ReviewScreeners({ screeners }) {
    return (
        <div className="screener-container">
            <div className="screener-header">
                <div>#</div>
                <div>Name</div>
                <div>Email</div>
                <div>Donor Intake</div>
                <div>Test Panels</div>
                <div>Results</div>
                <div>Manage Users</div>
                <div>Order Supplies</div>
            </div>

            {screeners.map((screener, index) => (
                <div key={index} className="screener-row">
                    <div>{index + 1}</div>
                    <div>{screener.first_name} {screener.last_name}</div>
                    <div className="scrollable-email">{screener.email}</div>
                    <div>
                        <input
                            type="checkbox"
                            checked={screener.can_perform_screens === 'true'}
                            disabled
                        />
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            checked={screener.can_manage_panels === 'true'}
                            disabled
                        />
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            checked={screener.can_manage_results === 'true'}
                            disabled
                        />
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            checked={screener.can_manage_screeners === 'true'}
                            disabled
                        />
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            checked={screener.can_order_cartons === 'true'}
                            disabled
                        />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ReviewScreeners;
