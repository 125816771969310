import React, { useState } from "react";
import "./SpeedSwabsFeatureCards.css";
import FastIcon from "../../../assets/icons/SpeedSwabsFastRabbit.svg";
import OralFluidIcon from "../../../assets/icons/SpeedSwabsOralFluidMouth.svg";
import ThumbsUpIcon from "../../../assets/icons/SpeedSwabsThumbsUp.svg";

// Feature data array
const featureData = [
    {
        icon: ThumbsUpIcon,
        header: "100% Reliable",
        subheader: "Get Lines, Every Time",
        description: "Put an end to 'no-flow' drug tests. SpeedSwabs deliver clear screen results every time!"
    },
    {
        icon: FastIcon,
        header: "Hyper Fast",
        subheader: "Negatives in 1-2 Minutes",
        description: "Tired of waiting on slow drug tests? Switch to SpeedSwabs and enjoy clear screen results in as little as 1 to 2 minutes."
    },
    {
        icon: OralFluidIcon,
        header: "Incredibly Easy",
        subheader: "Can be used by Anyone",
        description: "Don’t worry if you did it right, SpeedSwabs are so simple, they are virtually impossible to make a mistake."
    }
];

const SpeedSwabsFeatureCards = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleCardClick = (index) => {
        setActiveIndex(index);
    };

    return (
        <div className="feature-section">
            {featureData.map((feature, index) => {
                const isActive = index === activeIndex;
                const isLeft = index === (activeIndex + featureData.length - 1) % featureData.length;
                const isRight = index === (activeIndex + 1) % featureData.length;

                return (
                    <div
                        className={`feature-block ${isActive ? 'active' : isLeft ? 'left' : isRight ? 'right' : 'hidden'}`}
                        key={index}
                        onClick={() => handleCardClick(index)}
                    >
                        <div className="icon-wrapper">
                            <img src={feature.icon} alt={feature.header} className="feature-icon" />
                            <div className="circle-outline"></div>
                        </div>
                        <h3 className="feature-heading">{feature.header}</h3>
                        <p className="feature-subheading">{feature.subheader}</p>
                        <p className="feature-description">{feature.description}</p>
                    </div>
                );
            })}
        </div>
    );
};

export default SpeedSwabsFeatureCards;
