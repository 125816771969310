import React, { useState, useEffect } from 'react';
import './ClientsTable.css';
import HeaderRow from "./header_row/HeaderRow";
import ClientsRow from "./clients_row/ClientsRow";
import { PrimaryButton } from "../buttons/primary_button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import {
    clearLaboratoryAccountData,
    getLabbMlAccountRecords,
    getLaboratoryAccountRecords,
    getSearchClientAccountNumber, toggleClientSearchAccountsModal
} from "../../store/laboratory/laboratoryAccountSlice";
import { IconButton } from "../buttons/icon_button/IconButton";
import { LaboratoryAccountsIcons } from "../../assets/icons";
import { showMessageModal } from "../../store/modals/modalsSlice";

const ClientsTable = ({ clients, onEdit, userRoles }) => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.laboratorySlice.status === 'loading');
    const csvUrl = useSelector(state => state.laboratorySlice.csvUrl || "");

    const [searchTerm, setSearchTerm] = useState("");
    const [accountNumberSearchTerm, setAccountNumberSearchTerm] = useState("");
    const [filteredClients, setFilteredClients] = useState(clients);

    const linkQuestAccounts = () => {
        dispatch(getLabbMlAccountRecords())
    }

    const searchAccountNumber = (accountNumber) => {
        setAccountNumberSearchTerm(accountNumberSearchTerm);
        dispatch(getSearchClientAccountNumber({ accountNumber })).then(action => {
            if (action.payload && action.payload.length > 0) {
                dispatch(toggleClientSearchAccountsModal(true));
            }
        });
    };

    useEffect(() => {
        if (csvUrl.length > 0) {
            console.log("csvRawData", csvUrl);
            downloadLaboratoryAccountCsv(csvUrl);
        }
    }, [csvUrl]);

    useEffect(() => {
        setFilteredClients(
            clients.filter(client =>
                client.company.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, clients]);

    const downloadLaboratoryAccountCsv = (csvUrl) => {
        try {
            const link = document.createElement('a');
            link.href = csvUrl;
            link.download = "account-report.csv";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            dispatch(showMessageModal({ message: "Download initiated successfully.", link: link }));
        } catch (error) {
            dispatch(showMessageModal({ message: "Failed to initiate download.", link: null }));
        }
    };

    const handleDownloadButtonClick = () => {
        dispatch(getLaboratoryAccountRecords());
        dispatch(showMessageModal({ message: "Started Download", link: csvUrl }));
        dispatch(clearLaboratoryAccountData());
    };

    return (
        <div className="client-table-container">
            <div className="client-header">
                <div className="account-number-search-container">
                    <input
                        type="text"
                        className="account-number-search-input"
                        placeholder="Search Account Number..."
                        value={accountNumberSearchTerm}
                        onChange={(e) => searchAccountNumber(e.target.value)}
                    />
                </div>
                <div className="client-search-container">
                    <input
                        type="text"
                        className="client-search-input"
                        placeholder="Search clients..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className="client-button-container">
                    <PrimaryButton
                        type="button"
                        onClick={linkQuestAccounts}
                        disabled={loading}
                    >
                        {loading ? 'LOADING...' : 'LINK ACCOUNTS'}
                    </PrimaryButton>
                    <div className="client-action-tray-cell">
                        <IconButton
                            className="client-modal-close-button"
                            defaultIcon={LaboratoryAccountsIcons.DownloadIcon}
                            hoverIcon={LaboratoryAccountsIcons.DownloadIcon}
                            onClick={handleDownloadButtonClick}
                        />
                    </div>
                </div>
            </div>
            <div className="client-table-content">
                <table className="client-table">
                    <HeaderRow />
                    <tbody className="client-scroll-container">
                    {filteredClients.map((client, index) => (
                        <ClientsRow key={`${client.id}-${index}`} client={client} onEdit={onEdit}
                                    userRoles={userRoles} />
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ClientsTable;
