import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchShopifyCustomerDetails } from "../../services/api/v1/userShopifyService";
import {getCollectorByEmail} from "../../services/api/v1/userService";

// Async thunk to fetch Shopify customer details
export const getShopifyCustomerDetails = createAsyncThunk(
    'shopifyUser/getShopifyCustomerDetails',
    async ({shopifyOauthToken}) => {
        return await fetchShopifyCustomerDetails({shopifyOauthToken});
    }
);
// Async thunk to fetch Shopify customer details
export const fetchCollectorByEmail = createAsyncThunk(
    'shopifyUser/getCollectorByEmail',
    async ({ formData }, { rejectWithValue }) => {
        try {
            return await getCollectorByEmail(formData);
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const shopifyUserSlice = createSlice({
    name: 'shopifyUser',
    initialState: {
        lssCollectorDetails: {
            can_manage_panels: false,
            can_order_cartons: false,
            can_manage_results: false,
            can_manage_screeners: false,
            email: null,
            first_name: null,
            last_name: null,
            id: null,
        },
        customerDetails: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getShopifyCustomerDetails.pending, (state) => {  // Updated to use correct thunk
                state.loading = true;
                state.error = null;
            })
            .addCase(getShopifyCustomerDetails.fulfilled, (state, action) => {  // Updated to use correct thunk
                state.loading = false;
                state.customerDetails = action.payload;  // Removed `.data`, assuming `action.payload` is already the customer details object
            })
            .addCase(getShopifyCustomerDetails.rejected, (state, action) => {  // Updated to use correct thunk
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchCollectorByEmail.pending, (state) => {  // Updated to use correct thunk
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCollectorByEmail.fulfilled, (state, action) => {  // Updated to use correct thunk
                state.loading = false;
                state.lssCollectorDetails = action.payload;
            })
            .addCase(fetchCollectorByEmail.rejected, (state, action) => {  // Updated to use correct thunk
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default shopifyUserSlice.reducer;
