import React from 'react';
import "./../button_styles.css";

export function PrimaryButton({ onClick, children, isSelected, type = "button" }) {
    return (
        <button type={type} className={`primary-button ${isSelected ? 'selected' : ''}`} onClick={onClick}>
            {children}
        </button>
    );
}
