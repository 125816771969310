import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    fetchClientCollectors,
    fetchScreenersByClientId,
    getThinkificCollectorDetails
} from "../../services/api/v1/screenerService";

// Async thunk to fetch screeners
export const getScreeners = createAsyncThunk(
    'screener/getScreeners',
    async (params, { rejectWithValue }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            console.log('params', params)
            const offset = 0;
            const limit = 20;
            const filters = {};
            const response = await fetchScreenersByClientId(params.client_id, offset, limit, filters, accessToken);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


export const fetchClientScreenersDetails = createAsyncThunk(
    'screener/fetchClientScreenersDetails',
    async ({ clientId }, thunkAPI) => {
        const accessToken = sessionStorage.getItem("accessToken");
        try {
            // Assume response.data is the array of screeners
            return await fetchClientCollectors(clientId, accessToken);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchThinkificCollectorDetails = createAsyncThunk(
    'screener/fetchThinkificCollectorDetails',
    async ({ collectorId }, thunkAPI) => {
        const accessToken = sessionStorage.getItem("accessToken");
        try {

            return await getThinkificCollectorDetails(collectorId, accessToken);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);




const screenerSlice = createSlice({
    name: 'screener',
    initialState: {
        screeners: [],
        status: 'idle',
        is_badge_approved: false,
        error: null,
        message: null,
    },
    reducers: {
        // You can add other synchronous reducers here if needed
    },
    extraReducers: builder => {
        builder
            .addCase(getScreeners.pending, state => {
                state.status = 'loading';
            })
            .addCase(getScreeners.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.screeners = action.payload;
            })
            .addCase(getScreeners.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchClientScreenersDetails.pending, (state) => {
                    state.status = 'loading';
                })
            .addCase(fetchClientScreenersDetails.fulfilled, (state, action) => {
                    state.status = 'succeeded';
                    console.log('action', action)
                    state.screeners = action.payload;
                })
            .addCase(fetchClientScreenersDetails.rejected, (state, action) => {
                    state.status = 'failed';
                    state.error = action.error.message;
                })
            .addCase(fetchThinkificCollectorDetails.pending, (state) => {
                    state.status = 'loading';
                })
            .addCase(fetchThinkificCollectorDetails.fulfilled, (state, action) => {
                    state.status = 'succeeded';
                    state.is_badge_approved = action.payload.is_badge_approved;
                    state.is_badge_approved = action.payload.is_direct_read_approved;
                    state.message = action.payload.message;
                })
            .addCase(fetchThinkificCollectorDetails.rejected, (state, action) => {
                    state.status = 'failed';
                    state.error = action.error.message;
                });
    }
});


export default screenerSlice.reducer;

export const { clearScreenerData } = screenerSlice.actions;
