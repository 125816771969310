import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchPanelsByOrganizationId} from "../../services/api/v1/organizationsService";
import {getAllClientOrganizations} from "../../services/api/v1/clientService";

// Define the async thunk for fetching panels by organization ID
export const fetchPanelsByOrganizationIdThunk = createAsyncThunk(
    'organization/fetchPanelsByOrganizationId',
    async ({device_id, organization_id}) => {
        const accessToken = sessionStorage.getItem("accessToken");
        return fetchPanelsByOrganizationId(device_id, organization_id, accessToken);
    }
);


const organizationSlice = createSlice({
    name: 'organization',
    initialState: {
        panels: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPanelsByOrganizationIdThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPanelsByOrganizationIdThunk.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.panels = action.payload || [];
            })
            .addCase(fetchPanelsByOrganizationIdThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
        ;
    },
});

export default organizationSlice.reducer;
