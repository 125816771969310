import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import "./QuestAccountRow.css"
import ExpandCell from "../../../../clients/expand_cell/ExpandCell";
import QuestSubAccountModal from "../quest_account_organizations/QuestAccountOrganizations";
import {IconButton} from "../../../../buttons/icon_button/IconButton";
import {GenericIcons} from "../../../../../assets/icons";
import {
    saveLaboratoryAccountRecords
} from "../../../../../store/laboratory/laboratoryAccountSlice";
import {approveQuestAccount} from "../../../../../store/external/questAccountsSlice";
import {
    fetchOrganizationsByClientId,
    fetchOrganizationsByClientIdMatch
} from "../../../../../store/autobot/clients/organizations/organizationsSlice";

const QuestAccountRow = ({
                         key,
                         filteredQuestAccount,
                         updateFilteredQuestAccount,
    index
}) => {
    const dispatch = useDispatch();
    const [selectedClient, setSelectedClient] = useState(
        filteredQuestAccount.labb_client_id || null
    );
    const clientData = useSelector((state) => state.clients.clientData);
    const allQuestAccounts = useSelector(state => state.questAccounts.questAccountData);
    const [clientOptions, setClientOptions] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [filteredQuestSubAccounts, setFilteredQuestSubAccounts] = useState([]);
    const [filteredQuestSubAccount, setFilteredQuestSubAccount] = useState(null);



    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
        const filteredQuestSubAccounts = allQuestAccounts.filter((questAccount) => questAccount["Default Charge To"] === filteredQuestAccount["Client Number"]);
        setFilteredQuestSubAccounts(filteredQuestSubAccounts);
    };

    useState(() => {
        // Map client data to options, ensuring each option has a value and label
        const options = clientData.map((client) => ({
            value: client.id, // Assuming this is the correct identifier
            label: `${client.company} - ${client.address}`,
        }));

        // Ensure filteredQuestAccount.labb_client_id is a string for comparison
        const labbClientId = String(filteredQuestAccount.labb_client_id || "");

        // Find the best match option based on labb_client_id or fallback to null
        // Note: Adjusted to check `value` instead of a non-existing `id`
        // Also using 'String(option.value).includes()' to ensure we compare string values and handle `undefined` safely
        const bestMatchOption = options.find((option) =>
            String(option.value).includes(labbClientId)
        ) || null;

        // Find the initial selected client based on the best match or fallback to null
        const initialSelectedClient = bestMatchOption || null;

        // Update state with the derived options and the selected client
        setClientOptions(options);
        setSelectedClient(initialSelectedClient);
    }, [clientData]);

    const handleDropdownChange = (selectedClient) => {
        setSelectedClient(selectedClient);
    }

    const handleApproveMainAccount = () => {
        dispatch(saveLaboratoryAccountRecords({
            client_id: selectedClient.value,
            ss_organization_id: null,
            client_name: filteredQuestAccount["Client Name"],
            client_address: filteredQuestAccount["Client Address 1"],
            client_number: filteredQuestAccount["Client Number"],
        })).then(() => {
            dispatch(approveQuestAccount({ client_id: selectedClient.value }));
            updateFilteredQuestAccount({ ...filteredQuestAccount, is_draft: false }); // Update the account to reflect approval
        });
    };


    const updateFilteredQuestSubAccount = (filteredQuestSubAccount) => {
        const updatedFilteredQuestSubAccounts = filteredQuestSubAccounts.filter((selectQuestSubAccount) => selectQuestSubAccount.labb_client_id !== filteredQuestSubAccount.labb_client_id);
        const nonDraftQuestSubAccount = filteredQuestSubAccount.is_draft === false;
        updatedFilteredQuestSubAccounts.push(nonDraftQuestSubAccount);
        setFilteredQuestSubAccounts(updatedFilteredQuestSubAccounts);
        setFilteredQuestSubAccount(filteredQuestSubAccount)
   };

    const getOrganizations = (
        filteredQuestSubAccounts,
        selectedMainClientAccount
    ) => {
        const selectedClientAccountId = selectedMainClientAccount.value;

            dispatch(fetchOrganizationsByClientIdMatch({
                    filteredQuestSubAccounts,
                    selectedClientAccountId
                }
            ));
            dispatch(fetchOrganizationsByClientId({
                    selectedClientAccountId
                }
            ));


    }

    useEffect(() => {
        if (filteredQuestSubAccounts.length > 0) {
            getOrganizations(filteredQuestSubAccounts, selectedClient);
        }
    }, [filteredQuestSubAccounts, selectedClient]);

    return (
        <div key={key || index} className={`quest-account-row ${filteredQuestAccount.is_draft === true ? "is-draft" : "is-not-draft"}`}>

            <div className="custom-dropdown">
                {filteredQuestAccount.is_draft
                    ? null // If is_draft is true, don't show the Expand icon
                    : (
                        <div>
                            <ExpandCell isExpanded={isExpanded} toggleExpand={toggleExpand} />
                        </div>
                    )
                }
                <div>
                    <Select
                        options={clientOptions}
                        value={selectedClient}
                        className="custom-select"
                        classNamePrefix="react-select"
                        placeholder="Select Lab Station Client"
                        onChange={handleDropdownChange}
                    />
                </div>
            </div>
            <div className="client-details">
                <span>{filteredQuestAccount["Client Name"]}</span>
                <span>{filteredQuestAccount["Client Address 1"]}</span>
                <span>{filteredQuestAccount["Client Number"]}</span>
            </div>
        <div>
        {filteredQuestAccount.is_draft
                    ? (
                        <div>
                            <IconButton
                                defaultIcon={GenericIcons.CheckIcon}
                                hoverIcon={GenericIcons.CheckIcon}
                                onClick={handleApproveMainAccount}
                            />
                        </div>
                    )
                    : null // If is_draft is false, don't show the Check icon
                }
            </div>
            <div key={key || index}>
                {isExpanded && <QuestSubAccountModal
                    filteredQuestSubAccounts={filteredQuestSubAccounts}
                    selectedMainClientAccount={selectedClient}
                    updateFilteredQuestSubAccount={updateFilteredQuestSubAccount}
                />}
            </div>
        </div>
    );
};

export default QuestAccountRow;