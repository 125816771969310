import {makePOSTRequest, makeGETRequest} from "../../utils/httpUtils";

export const saveLaboratoryAccountDetails = (selectedClientDetails, accessToken) => {
    return makePOSTRequest(`/api/v1/account/draft/update`, selectedClientDetails, accessToken);
};

export const fetchLaboratoryAccountDetails = (accessToken) => {
    return makeGETRequest(`/api/v1/account/details/link`, accessToken);
};

export const fetchLabbMlAccountDetails = (accessToken) => {
    return makeGETRequest(`/api/v1/account/details/list`, accessToken);
};

export const fetchSearchClientAccountNumber = (accessToken, formData) => {
    return makePOSTRequest(`/api/v1/account/details/find`, formData, accessToken);
};