import "./SpecimenFilters.css";
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DateRangePicker} from "rsuite";
import "rsuite/dist/rsuite.min.css";
import {subDays, subMonths} from "date-fns";
import {allowedMaxDays, afterToday, combine} from "rsuite/cjs/DateRangePicker/disabledDateUtils";
import CustomDropdown from "../../dropdown/Dropdown";

import {
    setFilter,
    setDateRange,
    getDeviceIds,
    getClients,
    getLocations,
    getStatuses, getProducts, getServiceLevels
} from "../../../store/filters/filtersSlice.js";
import {IconButton} from "../../buttons/icon_button/IconButton";
import {SpecimenFilterIcons} from "../../../assets/icons";
import {getLaboratoryBillingRecords} from "../../../store/laboratory/laboratorySlice";
import ActionTray from "../../modals/action_tray/ActionTray";
import {getUserRolesFromToken} from "../../../helpers/token/processToken";

function SpecimenFilters({onApplyFilters, iconsData }) {
    const dispatch = useDispatch();
    const {
        deviceId,
        deviceIds,
        client,
        clients,
        location,
        locations,
        product,
        products,
        status,
        statuses,
        serviceLevel,
        serviceLevels,
        dateRange,
    } = useSelector((state) => state.filters);

    const accessToken = sessionStorage.getItem("accessToken");
    const userRoles = getUserRolesFromToken(accessToken);

    const lastWeek = [subDays(new Date(), 6), new Date()];
    const lastMonth = [subMonths(new Date(), 1), new Date()];
    const last3Months = [subMonths(new Date(), 3), new Date()];


    const handleInputChange = (filterName) => (value) => {
        dispatch(setFilter({ filterName, value }));
        onApplyFilters(filterName, value);
    };




    const handleDateRangeChange = (value) => {
        // Ensure we are working with a value that is an array
        if (!Array.isArray(value) || value.length !== 2) {
            console.error("Invalid date range value:", value);
            return; // Exit if value is not a valid array with two dates
        }

        const isoDates = value.map(date => date.toISOString().split('T')[0]);

        // Create filterValues object with start_date and end_date
        let filterValues = {
            start_date: isoDates[0],
            end_date: isoDates[1],
        };

        // Update Redux store with array of ISO date strings
        dispatch(setDateRange(filterValues));

        // Invoke onApplyFilters with the structured date range
        const filterName = "date_range";
        onApplyFilters(filterName, filterValues);
    };


    // Safely handle dateRange expecting it to always be an array
    const dateRangeValue = Array.isArray(dateRange) ? dateRange.map(dateStr => new Date(dateStr)) : [];


    const statusArray = Object.entries(statuses || {}).map(([value, label], index) => ({
        label,
        value: value
    }));
    const customDropdownValues = {
        deviceIds: {
            items: deviceIds || [],
            value: deviceId,
            onChange:handleInputChange('device_id'),
            placeholder: "Search by device",
            labelField: "device_id",
            valueField: "device_id"
        },
        client: {
            items: clients || [],
            value: client,
            onChange:handleInputChange('client'),
            placeholder: "Search by client",
            labelField: "company",
            valueField: "id"
        },
        location: {
            items: locations || [],
            value: location,
            onChange:handleInputChange('location'),
            placeholder: "Search by location",
            labelField: "location_name",
            valueField: "location_id"
        },
        product: {
            items: products || [], // Assuming products is defined in your state
            value: product,
            onChange:handleInputChange('product'),
            placeholder: "Search by product",
            labelField: "product_name",
            valueField: "product_id"
        },
        status: {
            items: statusArray || [],
            value: status,
            onChange:handleInputChange('status'),
            placeholder: "Search by status",
            labelField: 'label',
            valueField: 'value'
        },
        serviceLevel: {
            items: serviceLevels || [],
            value: serviceLevel,
            onChange:handleInputChange('service_level'),
            placeholder: "Search by service level",
            labelField: "service_level",
            valueField: "id"
        }
    };

    useEffect(() => {
        dispatch(getDeviceIds());
        dispatch(getClients());
        dispatch(getLocations());
        dispatch(getStatuses());
        dispatch(getProducts());
        dispatch(getServiceLevels());
    }, [dispatch]);


    return (
        <div className="filter-container all-filters">
            <div className="filter-row">
                <div className="filter">
                    {/*{Object.entries(customDropdownValues).map(([key, config]) => (*/}
                    {/*    <CustomDropdown {...config} />*/}
                    {/*))}*/}
                    <td className="cell">
                        <DateRangePicker
                            showOneCalendar
                            disabledDate={combine(allowedMaxDays(365), afterToday())}
                            appearance="default"
                            placeholder="Select Date Range..."
                            value={dateRangeValue.dateRange}
                            onChange={handleDateRangeChange}
                            ranges={[
                                {label: 'Last Week', value: lastWeek},
                                {label: 'Last Month', value: lastMonth},
                                {label: 'Last 3 Months', value: last3Months},
                            ]}
                        />
                    </td>
                    <div className="filter-actions">
                        <ActionTray iconsData={iconsData} userRoles={userRoles}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SpecimenFilters;
