import "./PublicHeader.css";
import React from "react";
import {NavLink} from "react-router-dom";
import {getShopifyOAuthUrl} from "../../user/sign_in/SignIn";
import UserIconCard from "../../user/icon_card/IconCard";

const PublicHeader = ({ logoSrc, onLogout }) => {
    const handleCollectorLogin = async () => {
        const hostname = window.location.hostname;
        window.location.href = await getShopifyOAuthUrl(hostname);
    };

    return (
        <header className="public-header">
            <NavLink to="/" className="logo-link">
                <img className="header-logo" src={logoSrc} alt="Logo" />
            </NavLink>
            <div className="user-icon-container">
                <UserIconCard
                    handleCollectorLogin={handleCollectorLogin}
                    onLogout={onLogout}
                />
            </div>
        </header>
    );
};

export default PublicHeader;
