/**
 * @file AppRoutes.js
 * @description This file represents the main routing configuration for the application.
 * It manages authentication flows and determines the routes that are available to a user
 * based on their roles.
 */

import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ScreenPage from "../pages/screen/Screen";
import ScreensPage from "../pages/screens/Screens";
import LaboratoryPage from "../pages/lab_tracking/Laboratory";
import MedicalReviewOfficerPage from "../pages/medical_review_officer/MedicalReviewOfficerPage";
import OrdersPage from "../pages/orders/Orders";
import BadganatorPage from "../components/modals/baganator/Badganator";
import InvoicesPage from "../pages/invoices/Invoices";
import PanelsPage from "../pages/panels/Panels";
import ClientsPage from "../pages/clients/Clients";
import EmailPage from "../pages/email/EmailPage";
import AutomationRoutes from "./automationRoutes";
import UserRoutes from "./userRoutes";
import { getUserRolesFromToken } from "../helpers/token/processToken";
import { setUserRoles } from "../store/user/userSlice";
import ManageShopifyPage from "../pages/shopify/manage/ManageShopifyPage";
import AdminHomePage from "../pages/home/admin_home/AdminHome";
import QuickbooksBillingPage from "../pages/quickbooks_billing/QuickbooksBilling";
import ZohoBillingPage from "../pages/zoho_billing/ZohoBilling";




/**
 * @component AppRoutes
 * @description Represents the main component responsible for handling authentication and routing based on user roles.
 * The component handles multiple authentication flows and dynamically sets routes that are available to the user based on their roles.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.instance - An instance object required for handling specific authentication requests.
 * @returns {JSX.Element} - The JSX for the routing and authentication flow management.
 */
const AppRoutes = ({ instance }) => {
    const userRoles = useSelector((state) => state.user.userRoles);
    const dispatch = useDispatch();

    /**
     * Check if the user has the specified role.
     * @param {string} role - The role to check.
     * @returns {boolean} - Returns true if the user has the role, false otherwise.
     */
    const hasRole = (role) => userRoles.includes(role);

    /**
     * useEffect hook to populate roles from token, then set rolesInitialized to true.
     */
    useEffect(() => {
        const token = sessionStorage.getItem('accessToken');
        if (token) {
            const roles = getUserRolesFromToken(token);
            dispatch(setUserRoles(roles));
        }
    }, [dispatch]);


    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<AdminHomePage />} />
                {hasRole("Labb.Specimen.Details") && (
                    <Route path="/screen/review" element={<ScreenPage />} />
                )}
                {hasRole("Freddy.Panels") && (
                    <Route path="/panels" element={<PanelsPage />} />
                )}
                {hasRole("Labb.Specimen.Details") && (
                    <Route path="/screens/" element={<ScreensPage />} />
                )}
                {hasRole("Freddy.Laboratory") && (
                    <Route path="/laboratory/" element={<LaboratoryPage />} />
                )}
                {hasRole("Labb.Mro.Reports") && (
                    <Route path="/medical-review-officer/" element={<MedicalReviewOfficerPage />} />
                )}
                {hasRole("Labb.Billing.Reports") && (
                    <Route path="/zoho/billing" element={<ZohoBillingPage />} />
                )}
                {hasRole("Labb.Billing.Reports") && (
                    <Route path="/quickbooks/billing" element={<QuickbooksBillingPage />} />
                )}
                {hasRole("Freddy.Billing.Invoice.Reports") && (
                    <Route path="/invoices" element={<InvoicesPage />} />
                )}
                {hasRole("Labb.Client.Management") && (
                    <Route path="/clients/" element={<ClientsPage userRoles={userRoles} />} />
                )}
                {hasRole("Freddy.Orders") && (
                    <Route path="/orders" element={<OrdersPage userRoles={userRoles} />} />
                )}
                {hasRole("Freddy.Badganator") && (
                    <Route path="/badganator" element={<BadganatorPage />} />
                )}
                {hasRole("Freddy.Autobot") && hasRole("Freddy.SuperAdmin") && (
                    <Route path="/automation/*" element={<AutomationRoutes instance={instance} />} />
                )}
                {hasRole("Freddy.SuperAdmin") && (
                    <Route path="/users/*" element={<UserRoutes instance={instance} />} />
                )}
                {hasRole("Labb.Email") && (
                    <Route path="/email" element={<EmailPage instance={instance} />} />
                )}
                {hasRole("Freddy.Manage.Shopify") && (
                    <Route path="/shopify" element={<ManageShopifyPage instance={instance} />} />
                )}
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
