import axios from 'axios';

export const fetchShopifyCustomerDetails = async ({shopifyOauthToken }) => {
    try {


        const graphqlQuery = {
            query: `
                query { 
                    customer { 
                        id
                        firstName
                        lastName
                        emailAddress { emailAddress }
                    }
                }
            `,
        };

        const response = await axios.post(
            'https://shopify.com/66278916260/account/customer/api/2024-07/graphql',
            graphqlQuery,
            {
                headers: {
                    'Authorization': `${shopifyOauthToken}`,  // Ensure 'Bearer' prefix is used
                    'Content-Type': 'application/json',
                },
            }
        );

        if (response.status === 200 && response.data.data) {
            return response.data.data.customer;  // Return only the customer data
        } else {
            throw new Error('Failed to fetch customer details');
        }
    } catch (error) {
        console.error('Error occurred while fetching customer details:', error);
        throw error;  // Let the calling function handle the error
    }
};
