import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchCollectorBadge} from "../../services/api/v1/badganatorService";


// Async thunk to fetch screeners
export const createCollectorBadge = createAsyncThunk(
    'badganator/createCollectorBadge',
    async ({ formData }, thunkAPI) => {
        const accessToken = sessionStorage.getItem("accessToken");
        try {
            return await fetchCollectorBadge(formData, accessToken);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);




export const badganatorSlice = createSlice({
    name: 'badganator',
    initialState: {
        isVisible: false,
        clientId: null,
        screenerId: 0,
        badgeUrl: '',
    },
    reducers: {
        setBadganatorDetails: (state, action) => {
            state.isVisible = true;
            state.clientId = action.payload.clientId;
            state.screenerId = action.payload.screenerId;
            state.badgeUrl = action.payload.badgeUrl;
        },
        closeBadganator: (state) => {
            state.isVisible = false;
            state.clientId = null;
            state.screenerId = 0;
            state.badgeUrl = '';
        },
        toggleBadganatorModal: (state, action) => {
            state.isVisible = !state.isVisible;
            if (state.isVisible) {
                state.clientId = action.payload;
            } else {
                state.clientId = null;
            }
        }
    }
});

export const { setBadganatorDetails, closeBadganator, toggleBadganatorModal } = badganatorSlice.actions;

export default badganatorSlice.reducer;
