import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import "./PublicVerify.css";
import { PrimaryButton } from '../../../components/buttons/primary_button/PrimaryButton';
import {fetchRegisterPublicUser, fetchVerifyUserInviteToken} from "../../../store/user/publicUserSlice";
import {useDispatch, useSelector} from "react-redux";

// Function to convert Base64 to ArrayBuffer
const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
};

// Function to convert ArrayBuffer to Base64
const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

const deriveKeyFromToken = async (token) => {
    const cleanedToken = token.replace(/[^a-fA-F0-9]/g, '');  // Clean non-hex chars
    const cleanedTokenBytes = new TextEncoder().encode(cleanedToken);
    const hashBuffer = await crypto.subtle.digest('SHA-256', cleanedTokenBytes);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    while (hashArray.length < 32) {
        hashArray.push(0);
    }
    return new Uint8Array(hashArray);  // Returns Uint8Array of length 32
};

// Function to encrypt the password using AES-GCM
const encryptPassword = async (password, token) => {
    const keyBytes = await deriveKeyFromToken(token);  // Derive key
    const key = await crypto.subtle.importKey(
        'raw',
        keyBytes,
        { name: 'AES-GCM' },
        false,
        ['encrypt']
    );

    const iv = crypto.getRandomValues(new Uint8Array(12));  // Generate 12-byte IV for AES-GCM

    const encrypted = await crypto.subtle.encrypt(
        { name: 'AES-GCM', iv: iv },
        key,
        new TextEncoder().encode(password)
    );

    // Return encrypted data and IV in Base64
    return {
        ciphertext: arrayBufferToBase64(encrypted),  // Convert to Base64
        iv: arrayBufferToBase64(iv)  // Convert IV to Base64
    };
};

const PublicVerifyPage = ({ instance }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [isUsernameAvailable, setIsUsernameAvailable] = useState(true);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [verificationStatus, setVerificationStatus] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const freddyToken = useSelector((state) => state.publicUser.publicAccessToken);

    // Extract token from URL
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    useEffect(() => {
        // Verify token on page load
        if (token) {
            dispatch(fetchVerifyUserInviteToken(token));  // Dispatch the Redux action
        }
    }, [token, dispatch]);

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const validatePasswordStrength = (password) => {
        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
        return strongPasswordRegex.test(password);
    };

    // Example usage in form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        if (!validatePasswordStrength(password)) {
            setError("Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.");
            return;
        }

        const { ciphertext, iv } = await encryptPassword(password, token);

        // Create FormData and send to backend
        const formData = new FormData();
        formData.append('token', token);
        formData.append('username', username);
        formData.append('password', ciphertext);
        formData.append('iv', iv);

        try {
            await dispatch(fetchRegisterPublicUser({ formData, freddyToken }));  // Dispatch registration action
            navigate('/');
        } catch (err) {
            console.error('Verification failed:', err);
            setError('An error occurred during verification. Please try again.');
        }
    };


    if (isLoading) {
        return <div className="loading">Loading...</div>;
    }

    if (verificationStatus === 'invalid') {
        return <div className="error-message">Invalid or expired verification link.</div>;
    }

    return (
        <div className="public-verify-page">
            <div className="verify-container">
                <h2>Set Your Username and Password</h2>
                {error && <div className="error-message">{error}</div>}
                <form onSubmit={handleSubmit} className="verify-form">
                    <div className="form-group">
                        <label htmlFor="username">Username:</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={handleUsernameChange}
                            required
                        />
                        {!isUsernameAvailable && (
                            <div className="error-message">Username is already taken. Please choose another.</div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">New Password:</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="confirmPassword">Confirm Password:</label>
                        <input
                            type="password"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <PrimaryButton type="submit">Set Credentials</PrimaryButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PublicVerifyPage;
