// freddy-react/src/services/api/v1/publicUserService.js

import {makePOSTRequest, makeUnauthGETRequest} from '../../utils/httpUtils';

// Public user login (POST request to backend)
export const loginPublicUser = (formData) => {
    return makePOSTRequest(`api/v1/user/login`, formData);
};



// Public user registration (POST request to backend)
export const registerPublicUser = (formData, freddyToken) => {
    return makePOSTRequest(`api/v1/register/user/new`, formData, freddyToken);
};

// Public user registration (POST request to backend)
export const verifyUserInviteToken = (inviteToken) => {
    return makeUnauthGETRequest(`api/v1/verify/invite/new?token=${inviteToken}`);
};