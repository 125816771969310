// src/store/invoices/omegaInvoiceSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const omegaInvoiceSlice = createSlice({
    name: 'omegaInvoices',
    initialState: {
        invoices: [],
    },
    reducers: {
        setOmegaInvoices: (state, action) => {
            state.invoices = action.payload;
        },
        clearOmegaInvoices: (state) => {
            state.invoices = [];
        },
    },
});

export const { setOmegaInvoices, clearOmegaInvoices } = omegaInvoiceSlice.actions;

export default omegaInvoiceSlice.reducer;