// src/store/prices/productPricesSlice.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setProductPrices } from "../../services/api/v1/productPricesService";

export const fetchProductPricesService = createAsyncThunk(
    'servicePrice/setProductPrices',
    async ({ selectedPrices, selectedClients, username, password }, thunkAPI) => {
        const accessToken = sessionStorage.getItem('accessToken');
        return await setProductPrices(selectedPrices, selectedClients, accessToken, username, password);
    }
);

export const productPricesService = createSlice({
    name: 'productPrices',
    initialState: {
        showProductPricesModal: false,
        clientIds: [],
        productPrices: [],
    },
    reducers: {
        setProductDetailsPrices: (state, action) => {
            state.productPrices = action.payload;
            state.showProductPricesModal = true;  // Show modal only when user sets service prices explicitly
        },
        clearServicePrices: (state) => {
            state.productPrices = [];
            state.clientIds = [];
            state.showProductPricesModal = false;
        },
        toggleProductPricesModal: (state) => {
            state.showProductPricesModal = !state.showProductPricesModal;  // Toggle visibility
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductPricesService.fulfilled, (state) => {
                state.showProductPricesModal = false;  // Close the modal on successful submission
            })
            .addCase(fetchProductPricesService.rejected, (state) => {
                state.showProductPricesModal = false;  // Close the modal on error
            });
    },
});

export const { setProductDetailsPrices, clearServicePrices, toggleProductPricesModal } = productPricesService.actions;
export default productPricesService.reducer;
