// src/components/dropdown/Dropdown.js
import React, {useState, useEffect, useRef} from 'react';
import './Dropdown.css';
import {GenericIcons as GenericIcon, GenericIcons} from "../../assets/icons";
import {IconButton} from "../buttons/icon_button/IconButton";


const CustomDropdown = ({ items, value, onChange, placeholder, labelField, valueField }) => {
    const [selectedLabel, setSelectedLabel] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const dropdownRef = useRef(null);

    useEffect(() => {
        // Check if value is not undefined and items array is not empty
        if (value !== undefined && items.length) {
            const selected = items.find(item =>
                item[valueField] !== undefined &&
                item[valueField].toString() === value.toString()
            );

            if (selected) {
                setSelectedLabel(selected[labelField]);
            }
        } else {
            // If value is undefined or items array is empty, reset the selectedLabel
            setSelectedLabel('');
        }
    }, [value, items, labelField, valueField]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    useEffect(() => {
        if (value !== null && items.length) {
            const selectedItem = items.find(item => item[valueField] === value);
            setSearchQuery(selectedItem ? selectedItem[labelField] : '');
        } else {
            setSearchQuery('');
        }
    }, [value, items, labelField, valueField]);

    const handleClear = () => {
        setSearchQuery('');
    };

    const handleItemClick = (item) => {
        onChange(item[valueField]);
        setShowDropdown(false);
        setSearchQuery(item[labelField]);
    };

    const getFilteredItems = (query) => {
        return query
            ? items.filter(item => item[labelField].toLowerCase().includes(query.toLowerCase()))
            : items;
    };

    const filteredItems = getFilteredItems(searchQuery);


    return (
        <div className="custom-dropdown" ref={dropdownRef}>
            <div className="dropdown-input-container">
                <input
                    className="dropdown-search-input"
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onFocus={() => setShowDropdown(true)}
                    placeholder={placeholder}
                />
                {searchQuery && (
                    <IconButton
                        className="dropdown-clear-button"
                        onClick={handleClear}
                        defaultIcon={GenericIcon.CloseIcon}
                        hoverIcon={GenericIcon.ExitIcon}
                    />
                )}
            </div>
            {showDropdown && (
                <div className={`dropdown-list ${showDropdown ? 'show' : ''}`}>
                    {filteredItems && filteredItems.map((item, index) => (
                        <div
                            key={index}
                            className="dropdown-item"
                            onClick={() => handleItemClick(item)}
                        >
                            {item[labelField]}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomDropdown;