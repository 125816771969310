import React from 'react';
import './ClientOrderCard.css';
import OrderedProductCard from "./ordered_product_card/OrderedProductCard";

const ClientOrderCard = ({ clientOrderDetails, onSelectedOrderClick }) => {
    return (
        <div className="client-card" onClick={() => onSelectedOrderClick(clientOrderDetails.order_id)}>
            <div className="client-name">
                {clientOrderDetails.client_name}
            </div>
            {clientOrderDetails.box_details.map((boxDetail, index) => (
                <OrderedProductCard key={index} boxDetail={boxDetail} />
            ))}
        </div>
    );
};

export default ClientOrderCard;
