// freddy-react/src/services/api/v1/organizationsService.js

import {makeGETRequest, makePOSTRequest} from "../../utils/httpUtils";
import {convertSpecimenIdToHash} from "./screenService";

export const fetchOrganizationsMatch = (filteredQuestSubAccounts, selectedClientAccountId, accessToken) => {
    const formData = new FormData();
    formData.append('quest_accounts', JSON.stringify(filteredQuestSubAccounts));
    // Assuming clientId is correctly formatted as a string when this function is called
    return makePOSTRequest(`/api/v1/client/${selectedClientAccountId}/organizations/match`, formData, accessToken);
};
export const fetchOrganizationsAPI = (selectedClientAccountId, accessToken) => {
    return makeGETRequest(`/api/v1/client/${selectedClientAccountId}/organizations`, accessToken);
};

export const fetchAllOrganizationsAPI = (accessToken) => {
    return makeGETRequest(`/api/v1/organizations/list`, accessToken);
};

// Define the fetchPanelsByOrganizationId service method
export const fetchPanelsByOrganizationId = async (specimenId, organizationId, accessToken) => {
    const deviceId = {device_id: specimenId}
    let deviceIdHash = await convertSpecimenIdToHash(deviceId, accessToken);
    const url = `/api/v1/screen/details/${deviceIdHash}/panels/all`;
    return makeGETRequest(url, accessToken);
};