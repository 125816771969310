import React from 'react';
import './ShopifySpeedCartItem.css';
import SpeedSwabPlaceholder from '../../../../../assets/graphics/SpeedSwabRapidOralFluidTestSingleSwab.png';
import { SpeedIcons } from "../../../../../assets/icons";
import { IconButton } from "../../../../buttons/icon_button/IconButton";
import { useDispatch } from 'react-redux';
import { updateCart, removeItem, addItem } from "../../../../../store/shopify/cart/shopifySpeedCartSlice";

const ShopifySpeedCartItem = ({ item, cart }) => {
    const dispatch = useDispatch();

    const handleQuantityAdjustment = (adjustment) => {
        const newQuantity = item.quantity + adjustment;
        // If the quantity is greater than 0, update the cart
        if (newQuantity > 0) {
            dispatch(updateCart({
                cartId: cart.id,
                lineItemId: item.id,
                quantity: newQuantity
            }));
        } else {
            // If the new quantity is 0, remove the item from the cart
            dispatch(removeItem({ cartId: cart.id, lineItemId: item.id }));
        }


        // Push the quantity adjustment event to dataLayer
        window.dataLayer.push({
            event: "quantity_adjustment",
            action: adjustment > 0 ? "increase" : "decrease",
            productName: item.merchandise.title,
            productId: item.merchandise.id,
            quantityBefore: item.quantity,
            quantityAfter: newQuantity,
            cartTotal: newQuantity * item.merchandise.price.amount,
        });

    };

    return (
        <div className="speed-cart-item-wrapper">
            {/* Larger Image Section */}
            <div className="speed-cart-item-image-container">
                <img
                    src={item.merchandise?.image?.src || SpeedSwabPlaceholder}  // Use placeholder if no image
                    alt={item.merchandise.title}
                    className="speed-cart-item-image"
                />
                <div className="speed-cart-item-overlay">
                    <span className="panel-number">6A</span> {/* Adjust dynamically */}
                </div>
                <div className="speed-cart-product-title-overlay">
                    <span className="speed-cart-product-title">{item.merchandise?.title}</span> {/* Adjust dynamically */}
                </div>
            </div>

            {/* Cart Item Details */}
            <div className="speed-cart-item">
                {/* Quantity Controls Section */}
                <div className="speed-cart-item-info">
                    <div className="speed-cart-item-quantity-controls">
                        <button
                            onClick={() => handleQuantityAdjustment(1)}
                            className="quantity-button plus-button">+
                        </button>
                        <div className="quantity-circle">
                            <span>{item.quantity}</span>
                        </div>
                        <button
                            onClick={() => handleQuantityAdjustment(-1)}
                            className="quantity-button minus-button">
                            <IconButton
                                defaultIcon={item.quantity === 1 ? SpeedIcons.TrashCanIcon : SpeedIcons.MinusSignIcon}
                                hoverIcon={item.quantity === 1 ? SpeedIcons.TrashCanIcon : SpeedIcons.MinusSignIcon}
                                className="quantity-button minus-button"
                            />
                        </button>
                    </div>
                </div>

                {/* Final Price Circle */}
                <div className="speed-cart-item-final-price">
                    <span>${(item.quantity * item.merchandise.price.amount).toFixed(2)}</span>
                </div>
            </div>
        </div>
    );
};

export default ShopifySpeedCartItem;
