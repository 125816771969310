import React from "react";
import "./InvoiceList.css";
import InvoiceItem from "../invoice_item/InvoiceItem";

const InvoiceList = ({ invoices }) => (
    <div className="invoice-list-container">
        {invoices.map((invoice, index) => (
            <InvoiceItem key={index} invoice={invoice} />
        ))}
    </div>
);

export default InvoiceList;
