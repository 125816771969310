// freddy-react/src/utils/cartUtils.js

export function getCartId() {
    return localStorage.getItem('shopifySpeedCartId');
}

export function setCartId(cartId) {
    localStorage.setItem('shopifySpeedCartId', cartId);
}

export function clearCartId() {
    localStorage.removeItem('shopifySpeedCartId');
}
