import React, {useEffect, useState} from 'react';
import ReadTile from "./read_tile/ReadTile";
import {useDispatch} from "react-redux";
import './ReadTiles.css';

export default function ReadTiles() {
    const dispatch = useDispatch();
    const [pendingReads, setPendingReads] = useState([]);

    const getPendingReads = async () => {
        try {
            const accessToken = sessionStorage.getItem("accessToken");
            // const fetchedReads = await fetchPendingReads(accessToken);  // Assuming fetchPendingReads is an API call function
            // dispatch(setPendingReadsAction(fetchedReads));  // Dispatch the fetched reads to Redux store
            // setPendingReads(fetchedReads);  // Set the local state

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getPendingReads();
    }, []);

    return (
        <div>
            <div className="read-tiles-main-body">
                {
                    pendingReads.length ? (
                        <div>
                            {pendingReads.map((screen) => (
                                <ReadTile key={screen.sd_number} screen={screen}/>
                            ))}
                        </div>
                    ) : null
                }
            </div>
        </div>
    )
}
