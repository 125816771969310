import React, { useEffect, useState } from "react";
import "./SpeedSwabsHeroGraphic.css";
import SpeedSwabsImage from "../../../assets/graphics/SpeedSwabRapidOralFluidDrugTestAngled.png";

const SpeedSwabsHeroGraphic = ({ scrollY, rotateAngle }) => {
    return (
        <div className="speed-swabs-hero-graphic">
            <div className="graphic-container">
                <img
                    src={SpeedSwabsImage}
                    alt="SpeedSwab rapid oral fluid drug test"
                    className="speed-swabs-image"
                    style={{
                        transform: `rotate(${rotateAngle}deg) translateY(${scrollY * 0.2}px)`,
                    }}
                />
            </div>
        </div>
    );
};

export default SpeedSwabsHeroGraphic;