// freddy-react/src/store/screens/screensSlice.js
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchScreenFullDetails} from '../../services/api/v1/screenService';

export const getScreenDetails = createAsyncThunk(
    'screen/getScreenDetails',
    async ({ deviceIdHash }, thunkAPI) => {
        // console.log("deviceIdHash from screenslice", deviceIdHash); // Log deviceIdHash here
        const accessToken = sessionStorage.getItem("accessToken");
        // console.log("accessToken from screenslice", accessToken); // Log accessToken here
        return await fetchScreenFullDetails(deviceIdHash, accessToken);
    }
);

const screenSlice = createSlice({
    name: 'screen',
    initialState: {
        screen: [],
        qrCodeData: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        clearScreenData: (state) => {
            state.screen = [];
            state.qrCodeData = [];
            state.status = 'idle';
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getScreenDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getScreenDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Concatenate new screens with existing screens
                state.screen = state.screen.concat(action.payload || []);
                // Assuming QR code data is included in the response payload
                // Update the state with the QR code data
                state.qrCodeData = action.payload.qrCodeData || [];
            })
            .addCase(getScreenDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { clearScreenData } = screenSlice.actions;

export default screenSlice.reducer;
