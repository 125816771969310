import React, { useState, useEffect } from "react";
import { PrimaryButton } from "../../../components/buttons/primary_button/PrimaryButton";
import axios from "axios";
import "./FreddyPublicUserManagementPage.css";
import PublicFreddyHeader from "../../../components/headers/public_freddy_header/PublicFreddyHeader";
import PublicFreddyLogo from "../../../assets/logo/PublicFreddyLogo.png";

const FreddyPublicUserManagementPage = () => {
    const [portalUsers, setPortalUsers] = useState([]);
    const [collectorUsers, setCollectorUsers] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [userType, setUserType] = useState('portal'); // To toggle between portal and collector users
    const [inviteEmail, setInviteEmail] = useState('');

    const onLogout = null;


    useEffect(() => {
        // Fetch the portal and collector users on load
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            // Fetch both types of users (replace with actual API endpoints)
            const portalResponse = await axios.get('/api/v1/users/portal');
            const collectorResponse = await axios.get('/api/v1/users/collector');

            setPortalUsers(portalResponse.data);
            setCollectorUsers(collectorResponse.data);
        } catch (error) {
            console.error("Failed to fetch users", error);
        }
    };

    const handleInvite = async () => {
        try {
            // Invite user API call (collector or portal user based on userType)
            const inviteData = {
                email: inviteEmail,
                userType,  // 'portal' or 'collector'
                organization: selectedOrganization,
                location: selectedLocation
            };
            await axios.post('/api/v1/invite', inviteData);
            alert("User invited successfully!");
            fetchUsers(); // Refresh the user list
        } catch (error) {
            console.error("Failed to invite user", error);
        }
    };

    return (
        <div className="freddy-public-user-management-page">

            <PublicFreddyHeader logoSrc={PublicFreddyLogo} onLogout={onLogout}/>

            {/* Section to invite new users */}
            <div className="invite-section">
                <h2>Invite New User</h2>
                <div className="form-group">
                    <label htmlFor="inviteEmail">Email:</label>
                    <input
                        type="email"
                        id="inviteEmail"
                        value={inviteEmail}
                        onChange={(e) => setInviteEmail(e.target.value)}
                        placeholder="Enter user's email"
                    />
                </div>

                <div className="form-group">
                    <label>User Type:</label>
                    <select
                        value={userType}
                        onChange={(e) => setUserType(e.target.value)}
                    >
                        <option value="portal">Portal User</option>
                        <option value="collector">Collector User</option>
                    </select>
                </div>

                {userType === 'portal' && (
                    <div className="form-group">
                        <label htmlFor="organization">Assign to Organization:</label>
                        <input
                            type="text"
                            id="organization"
                            value={selectedOrganization}
                            onChange={(e) => setSelectedOrganization(e.target.value)}
                            placeholder="Enter organization name"
                        />
                    </div>
                )}

                {userType === 'collector' && (
                    <>
                        <div className="form-group">
                            <label htmlFor="organization">Assign to Organization:</label>
                            <input
                                type="text"
                                id="organization"
                                value={selectedOrganization}
                                onChange={(e) => setSelectedOrganization(e.target.value)}
                                placeholder="Enter organization name"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="location">Assign to Location:</label>
                            <input
                                type="text"
                                id="location"
                                value={selectedLocation}
                                onChange={(e) => setSelectedLocation(e.target.value)}
                                placeholder="Enter location"
                            />
                        </div>
                    </>
                )}

                <PrimaryButton onClick={handleInvite}>Invite User</PrimaryButton>
            </div>

            {/* Portal Users List */}
            <div className="user-list-section">
                <h2>Portal Users</h2>
                <ul>
                    {portalUsers.map((user) => (
                        <li key={user.id}>{user.email} - {user.organization}</li>
                    ))}
                </ul>
            </div>

            {/* Collector Users List */}
            <div className="user-list-section">
                <h2>Collector Users</h2>
                <ul>
                    {collectorUsers.map((user) => (
                        <li key={user.id}>
                            {user.email} - {user.organization} - {user.location}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default FreddyPublicUserManagementPage;
