// freddy-react/src/store/products/lssProductsSlice.js

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchLssProducts} from "../../services/api/v1/productsService";


export const getLssProducts = createAsyncThunk(
    'lssProducts/getLssProducts',
    async ({ accessToken }, thunkAPI) => {
        return await fetchLssProducts(accessToken);
    }
);



export const lssProductsSlice = createSlice({
    name: 'lssProducts',
    initialState: {
        allLssProducts: [],
        isLoading: false,
    },
    reducers: {
        setLssProducts: (state, action) => {
            state.allLssProducts = action.payload;
            state.isLoading = false;
        },
        clearLssProducts: (state) => {
            state.allLssProducts = [];
            state.isLoading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLssProducts.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getLssProducts.fulfilled, (state, action) => {
                state.isLoading = false;
                state.allLssProducts = action.payload;
            })
            .addCase(getLssProducts.rejected, (state, action) => {
                state.isLoading = false;
            })
    }
});



export const { setLssProducts, clearLssProducts } = lssProductsSlice.actions;


export default lssProductsSlice.reducer;


