import "./ShopifyHome.css";
import React from 'react';
import LabFusionLogo from '../../../assets/logo/LabFusionLogo.png';
import LabbLogo from '../../../assets/logo/LabbLogo.png';
import ShopifyHeader from '../../../components/headers/shopify_header/ShopifyHeader';
import { useShopifyLogout } from "../../../utils/authUtils";
import ShopifyCustomerDetails from "../../../components/user/cusotmer_details/ShopifyCustomerDetails";

const ShopifyHomePage = () => {
    const hostname = window.location.hostname;
    const logoSrc = hostname === 'fusion.labb.com' ? LabFusionLogo : LabbLogo;
    const onLogout = useShopifyLogout();

    document.title = hostname === 'fusion.labb.com' ? "Lab Fusion | Home" : "Labb | Home";


    return (
        <div className="shopify-home-container">
            <div className="shopify-header-container">
                <ShopifyHeader logoSrc={logoSrc} onLogout={onLogout} />
            </div>
            <div>
                <ShopifyCustomerDetails />
            </div>
            <div>
                <div>

                </div>
            </div>
        </div>
    );
};

export default ShopifyHomePage;
