// src/services/api/emailService.js

// Function to fetch emails
import { makePOSTRequest } from "../../utils/httpUtils";
import { Client } from '@microsoft/microsoft-graph-client';

/**
 * Fetches emails using Microsoft Graph API with pagination support.
 * @param {string} accessToken - The access token for Microsoft Graph API.
 * @returns {Promise<Object[]>} A promise that resolves to an array of email objects.
 */
export const fetchEmails = async (accessToken) => {
    try {
        // Initialize Microsoft Graph client with provided access token
        const client = Client.init({
            authProvider: (done) => {
                done(null, accessToken);
            },
        });

        let emails = [];
        let nextPageUrl = '/me/messages';
        const pageSize = 50; // Adjust the page size as needed

        // Fetch emails with pagination
        do {
            const response = await client.api(nextPageUrl)
                .version('v1.0') // or 'beta', depending on the API version you want to use
                .header('Prefer', 'outlook.body-content-type="text"')
                .query({ '$top': pageSize })
                .get();

            emails = [...emails, ...response.value];
            console.log(emails);
            nextPageUrl = response['@odata.nextLink'];

        } while (nextPageUrl);

        return emails;
    } catch (error) {
        console.error('Error fetching emails:', error);
        throw error; // Re-throw the error for further handling
    }
};

// Function to process a specific email
export const processSpecimenNoteEmails = async (formData, accessToken) => {
    return makePOSTRequest(`/api/v1/screen/note/add/new`, formData, accessToken);
};

// Function to send an invite email
export const sendEmailInvite = async (formData, accessToken) => {
    try {
        return await makePOSTRequest(`/api/v1/external/sendgrid/email/invite/new/send`, formData, accessToken);
    } catch (error) {
        console.error('Error sending invite email:', error);
        throw error; // Re-throw the error for further handling
    }
};