// src/services/api/v1/emailAuthService.js

export async function fetchEmailAuthToken(instance, emailTokenRequest) {
    // Check if the token already exists and is still valid
    const currentToken = sessionStorage.getItem('emailAccessToken');
    const expirationTime = sessionStorage.getItem('tokenExpiry');
    const now = new Date().getTime();

    if (currentToken && expirationTime && now < expirationTime) {
        return currentToken; // Return the current token if it's still valid
    }

    try {
        // Attempt to get the token silently
        const silentResult = await instance.acquireTokenSilent(emailTokenRequest).catch(silentError => {
            console.error('Silent token acquisition failed:', silentError);
            throw silentError;
        });
        sessionStorage.setItem('emailAccessToken', silentResult.accessToken);
        sessionStorage.setItem('tokenExpiry', silentResult.expiresOn.getTime());
        return silentResult.accessToken;
    } catch (error) {
        console.error('Attempting token acquisition via popup due to silent failure.');
        try {
            // If the silent acquisition fails, try to acquire token with a popup
            const popupResult = await instance.acquireTokenPopup(emailTokenRequest);
            sessionStorage.setItem('emailAccessToken', popupResult.accessToken);
            sessionStorage.setItem('tokenExpiry', popupResult.expiresOn.getTime());
            return popupResult.accessToken;
        } catch (popupError) {
            console.error('Popup token acquisition failed:', popupError);
            throw popupError;
        }
    }
}
