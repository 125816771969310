import { useState } from "react";
import './ShipmentBoxDetails.css';
import { BoxStyles } from "../../../../assets/boxes";
import { GenericIcons } from "../../../../assets/icons";
import BoxStyleOptions from "../box_styles/BoxStyleOptions";
import { IconButton } from "../../../buttons/icon_button/IconButton";

const ShipmentBoxDetails = ({ boxDetails, setBoxDetails }) => {
    const [editingIndex, setEditingIndex] = useState(null);

    const determineBoxType = (box) => {
        if (box.boxes_of_4 > 0) return 'MasterBox';
        if (box.boxes_of_3 > 0) return 'ThreeBox';
        if (box.boxes_of_2 > 0) return 'HalfBox';
        if (box.boxes_of_1 > 0) return 'MailerBox';
        return 'MasterBox';
    };

    const getBoxStyle = (boxType) => {
        switch (boxType) {
            case 'MasterBox':
                return BoxStyles.MasterBox;
            case 'MailerBox':
                return BoxStyles.MailerBox;
            case 'ThreeBox':
                return BoxStyles.ThreeBox;
            case 'HalfBox':
                return BoxStyles.HalfBox;
            default:
                return null;
        }
    };

    const handleBoxSelection = (index, boxType) => {
        const newBoxDetails = [...boxDetails];
        newBoxDetails[index] = {
            ...newBoxDetails[index],
            box_type: boxType
        };
        setBoxDetails(newBoxDetails);
        setEditingIndex(null);
    };

    return (
        <div className="shipment-box-details">
            {boxDetails.map((box, index) => {
                const initialBoxType = box.box_type || determineBoxType(box);
                return (
                    <div key={index} className="box-detail">
                        <div className="box-header">
                            <IconButton className="box-edit-icon" defaultIcon={GenericIcons.EditIcon} hoverIcon={GenericIcons.EditIcon} onClick={() => setEditingIndex(index)} />
                        </div>
                        <div>
                            {editingIndex === index && (
                                <BoxStyleOptions onSelect={(boxType) => handleBoxSelection(index, boxType)} />
                            )}
                            <img src={getBoxStyle(initialBoxType)} alt={initialBoxType} className="box-image-large" />
                            <div className="box-details">
                                <div className="box-product-name">{box.product_name}</div>
                                <div className="box-quantity">{box.product_quantity}</div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ShipmentBoxDetails;
