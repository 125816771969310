import { useDispatch, useSelector } from "react-redux";
import "./LinkAccountsModal.css";
import { GenericIcons } from "../../../assets/icons";
import { IconButton } from "../../buttons/icon_button/IconButton";
import { hideLinkAccountsModal } from "../../../store/laboratory/laboratoryAccountSlice";
import UnlinkedAccounts from "./unlinked_accounts/UnlinkedAccounts";

const LinkAccountsModal = () => {
    const dispatch = useDispatch();
    const unlinkedQuestAccounts = useSelector(state => state.laboratorySlice.unlinkedAccounts);

    return (
        <>
            {unlinkedQuestAccounts && unlinkedQuestAccounts.length > 0 &&
                <div className="modal-overlay">
                    <div className="modal-content">
                        <IconButton
                            defaultIcon={GenericIcons.CloseIcon}
                            hoverIcon={GenericIcons.CloseIcon}
                            onClick={() => dispatch(hideLinkAccountsModal())}
                            className="close-button"
                        />
                        <UnlinkedAccounts unlinkedQuestAccounts={unlinkedQuestAccounts}/>
                    </div>
                </div>
            }
        </>
    );
}

export default LinkAccountsModal;
