import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./UnlinkedAccounts.css";
import UnlinkedAccountRow from "./unlinked_account_row/UnlinkedAccountRow";
import {
    fetchAllOrganizations
} from "../../../../store/autobot/clients/organizations/organizationsSlice";

const UnlinkedAccounts = ({ unlinkedQuestAccounts }) => {
    const dispatch = useDispatch();
    const clients = useSelector(state => state.clients.clientData);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        dispatch(fetchAllOrganizations())
    });

    useEffect(() => {
        if (clients) {
            const loadedOptions = clients.map(client => ({
                value: client.id,
                label: `${client.company}`
            }));
            setOptions(loadedOptions);
        }
    }, [clients]);

    return (
        <div className="unlinked-accounts">
            {unlinkedQuestAccounts.map((unlinkedQuestAccount, index) => (
                <UnlinkedAccountRow
                    key={index}
                    unlinkedQuestAccount={unlinkedQuestAccount}
                    clientOptions={options}
                />
            ))}
        </div>
    );
};

export default UnlinkedAccounts;
