import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QuestAccountsModal from "./quest_accounts/QuestAccounts";

// Add imports for other modals as needed

const UploadModals = () => {
    const isQuestAccountsVisible = useSelector(state => state.questAccounts.isModalVisible);
    return (
        <>
            {isQuestAccountsVisible && (
                    <div className="full-screen-modal" >
                        <div className="modal-content">
                            <QuestAccountsModal
                            />
                        </div>
                    </div>
            )}

        </>
    );
};

export default UploadModals;
