import React from 'react';
import './Icon.css';
import { NavLink } from 'react-router-dom';

const Icon = ({ iconSrc, routePath,whiteActiveClass }) => {
    console.log({routePath})
    return (
        <div className="icons-container">
            {iconSrc && (
                <NavLink to={routePath} className={whiteActiveClass}>
                    <img src={iconSrc} alt="Icon" />    
                </NavLink>
            )}
        </div>
    );
}

export default Icon;
