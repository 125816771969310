import React, { useState } from 'react';
import "./../button_styles.css";

export function IconButton({ onClick, defaultIcon, hoverIcon, isSelected, alt }) {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    }

    const handleMouseLeave = () => {
        setIsHovered(false);
    }

    return (
        <button
            className={`icon-button ${isSelected ? 'selected' : ''}`}
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="icon-container">
                {isHovered && hoverIcon ? <img src={hoverIcon} alt={alt} /> : <img src={defaultIcon} alt={alt} />}
            </div>
        </button>
    );
}
