// src/components/product_prices/ProductPricesModal.js

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '../../buttons/icon_button/IconButton';
import { GenericIcons, PricingIcons } from "../../../assets/icons";
import FastSelect from "../../clients/fast_select/FastSelect";
import './ProductPricesModal.css';
import { clearServicePrices, fetchProductPricesService } from "../../../store/prices/productPricesSlice";
import PricesRow from "./pricing_row/PriceRow";
import LabbLogin from "../../autobot/labb_login/LabbLogin";

const ProductPricesModal = () => {
    const isVisible = useSelector(state => state.productPrices?.showProductPricesModal);
    const servicePrices = useSelector(state => state.productPrices?.productPrices);
    const clients = useSelector(state => state.clients.clientData);
    const labb_username = useSelector(state => state.autobot.login.username);
    const labb_password = useSelector(state => state.autobot.login.password);
    const [selectedClients, setSelectedClients] = useState([]);
    const [updatedPrices, setUpdatedPrices] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isVisible && servicePrices && servicePrices.length > 0) {
            setUpdatedPrices(servicePrices);
        }
    }, [isVisible, servicePrices]);

    const clientOptions = clients.map(client => ({
        label: client.company,
        value: client.id
    }));

    if (!isVisible) return null;

    const handleClose = () => {
        dispatch(clearServicePrices());
        setSelectedClients([]);
        setUpdatedPrices([]);
    };

    const handlePriceChange = (index, field, value) => {
        const newPrices = [...updatedPrices];
        newPrices[index] = { ...newPrices[index], [field]: value };
        setUpdatedPrices(newPrices);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const clientIds = selectedClients.map(client => client.value);
        dispatch(fetchProductPricesService({
            selectedPrices: updatedPrices,
            selectedClients: clientIds,
            username: labb_username,
            password: labb_password
        }))
            .then((result) => {
                if (result.meta.requestStatus === 'fulfilled') {
                    handleClose();
                }
            });
    };

    return (
        <div className="product-prices-modal-overlay">
            <div className="product-prices-modal">
                <div className="product-prices-modal-header">
                    <IconButton defaultIcon={GenericIcons.CloseIcon} hoverIcon={GenericIcons.ExitIcon} alt="Close" onClick={handleClose} />
                </div>
                <div className="product-prices-modal-content">
                    <form onSubmit={handleSubmit}>
                        <div>
                            <LabbLogin />
                        </div>
                        <div className="product-prices-header">
                            <FastSelect
                                options={clientOptions}
                                selectedClients={selectedClients}
                                setSelectedClients={setSelectedClients}
                            />
                        </div>
                        <div className="product-prices-grid">
                            {updatedPrices && updatedPrices.length > 0 ? (
                                updatedPrices.map((price, index) => (
                                    <PricesRow key={index} price={price} handlePriceChange={handlePriceChange} index={index}/>
                                ))
                            ) : (
                                <p>No service prices available</p>
                            )}
                        </div>
                        <div className="product-prices-save-action">
                            <IconButton
                                defaultIcon={PricingIcons.SaveIcon}
                                hoverIcon={PricingIcons.SaveIcon}
                                onClick={handleSubmit}
                                alt="Save"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ProductPricesModal;
