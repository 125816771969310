import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Badganator.css';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import { GenericIcons } from "../../assets/icons";
import { IconButton } from "../buttons/icon_button/IconButton";
import { createCollectorBadge } from "../../store/badganator/badganatorSlice";
import { showMessageModal } from "../../store/modals/modalsSlice";
import {fetchClientScreenersDetails, fetchThinkificCollectorDetails} from "../../store/screener/screenerSlice";
import {PrimaryButton} from "../buttons/primary_button/PrimaryButton";

const Badganator = ({ closeModal }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [selectCollectorOptions, setSelectCollectorOptions] = useState([]);
    const [shouldDownload, setShouldDownload] = useState(false);
    const [collectorBadgeUrl, setCollectorBadgeUrl] = useState("");
    const [overrideApproval, setOverrideApproval] = useState(false);
    const [badgeError, setBadgeError] = useState(false);

    const clientId = useSelector(state => state.badganator.clientId);
    const clientCollectors = useSelector(state => state.screeners.screeners);
    const [isBadgeApproved, setIsBadgeApproved] = useState(false);
    const badgeMessage = useSelector(state => state.screeners.message);
    const [isDirectReadApproved, setIsDirectReadApproved] = useState(false);

    useEffect(() => {
        if (clientId) {
            dispatch(fetchClientScreenersDetails({ clientId }));
        }
    }, [clientId, dispatch]);

    const handleCollectorChange = async (selectedOptions) => {
        setSelectCollectorOptions(selectedOptions);

        try {
            setLoading(true);
            // Fetch the collector details, specifically to check if the badge is approved
            const collectorId = selectedOptions.value;
            const result = await dispatch(fetchThinkificCollectorDetails({ collectorId })).unwrap();
            setLoading(false);

            if (result.is_direct_read_approved !== null) {
                setIsDirectReadApproved(
                    result.is_direct_read_approved
                );
            }
            if (result.is_badge_approved !== null) {
                setIsBadgeApproved(
                    result.is_badge_approved
                );
            }

            // If the badge is not approved, show the message modalo
            if (!result.is_badge_approved) {
                setBadgeError(true);
                dispatch(showMessageModal({ message: badgeMessage || "Badge not approved.", link: null }));
            } else {
                setBadgeError(false); // Reset error if badge is approved
            }
        } catch (error) {
            setLoading(false);
            console.error('Failed to fetch collector details:', error);
            dispatch(showMessageModal({ message: "Failed to fetch collector details.", link: null }));
        }
    };

    const submitCollectorOptions = async () => {
        const collectorId = selectCollectorOptions.value;
        const formData = new FormData();
        formData.append('collector_id', collectorId);
        try {
            const response = await dispatch(createCollectorBadge({ formData })).unwrap();
            setCollectorBadgeUrl(response[0]); // Assuming the response contains a URL
            setShouldDownload(true);
        } catch (error) {
            console.error('Failed to create badge:', error);
            dispatch(showMessageModal({ message: "Failed to create badge.", link: null }));
        }
    };

    const downloadCollectorBadge = (e) => {
        e.preventDefault();
        submitCollectorOptions();
    };

    useEffect(() => {
        if (collectorBadgeUrl && shouldDownload) {
            const anchor = document.createElement('a');
            anchor.href = collectorBadgeUrl;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            setCollectorBadgeUrl("");
            setShouldDownload(false);
            dispatch(showMessageModal({ message: "Download successful.", link: null }));
        }
    }, [collectorBadgeUrl, shouldDownload]);

    const collectorOptions = clientCollectors.map(clientCollector => ({
        value: clientCollector.collector_id,
        label: `${clientCollector.collector_first_name} ${clientCollector.collector_last_name}`,
    }));


    return (
        <div className="badganator-main-frame">
            <div className="badganator-header">
                <div className="badganator-collector-selector">
                    <CreatableSelect
                        components={makeAnimated()}
                        placeholder="Select User..."
                        className="multi-selector-user"
                        closeMenuOnSelect={true}
                        options={collectorOptions}
                        onChange={handleCollectorChange}
                        value={selectCollectorOptions}
                    />
                </div>
                <div>
                    {!isBadgeApproved && badgeError && (
                        <PrimaryButton onClick={() => setOverrideApproval(true)}>
                            OVERRIDE
                        </PrimaryButton>
                    )}
                </div>
                <div className="pre-set-report-icon-frame">
                    {selectCollectorOptions && Object.keys(selectCollectorOptions).length > 0 && (isBadgeApproved || overrideApproval) &&
                        <IconButton onClick={(e) => downloadCollectorBadge(e)} hoverIcon={GenericIcons.DownloadIcon}
                                    defaultIcon={GenericIcons.DownloadIcon}/>
                    }
                </div>
                <div className="pre-set-report-icon-frame">
                    {selectCollectorOptions && Object.keys(selectCollectorOptions).length > 0 && (isDirectReadApproved) &&
                        <IconButton hoverIcon={GenericIcons.PersonIcon}
                                    defaultIcon={GenericIcons.PersonIcon}/>
                    }
                </div>
            </div>
        </div>
    );
};

export default Badganator;
