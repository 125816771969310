import React, { useEffect, useState } from "react";
import "./MedicalReviewOfficerPage.css";
import { useDispatch, useSelector } from "react-redux";
// import Footer from "../../components/footer/Footer";
import MroSpecimensTable from "../../components/specimens/mro_screens/MroSpecimensTable";
import { getMroScreenDetails } from "../../store/mro_screen/mroScreenSlice";
import { clearReportData, getMroReportPdfRecords } from "../../store/mro_reports/mroReportsSlice";
import { showMessageModal } from "../../store/modals/modalsSlice";
import { subDays } from "date-fns";
import AdminHeader from "../../components/headers/admin_header/AdminHeader";

const MedicalReviewOfficerPage = () => {
    const dateRange = useSelector(state => state.filters.dateRange);
    // Add a state for tracking loading status
    const [isLoading, setIsLoading] = useState(false);
    const [screenParams, setScreenParams] = useState({ offset: 0, limit: 20, filters: {} });
    const dispatch = useDispatch();
    const mroScreens = useSelector(state => state.mro_screens);
    const mroScreensArray = mroScreens.screens;
    const pdfRawData = useSelector(state => state.reports?.reports?.payload);

    useEffect(() => {
        if (mroScreens.status === 'failed') {
            dispatch(showMessageModal({ message: mroScreens.error, link: null }));
        }
    }, [mroScreens.status, dispatch]);

    useEffect(() => {
        if (pdfRawData && mroScreensArray.length > 0) {
            const specimenId = mroScreensArray[0].id; // Ensure this accesses the first element correctly
            downloadMroReportPdf(pdfRawData, specimenId);
        }
    }, [pdfRawData, mroScreensArray]);

    // Update this function to handle the URL link directly
    const downloadMroReportPdf = (pdfUrl, specimenId) => {
        try {
            const link = document.createElement('a');
            link.href = pdfUrl.pdf_url;
            link.download = `${specimenId}-final-report.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            dispatch(showMessageModal({
                message: "Download initiated successfully.",
                link: pdfUrl.pdf_url
            }));
        } catch (error) {
            // Handle any potential errors during the process
            dispatch(showMessageModal({ message: "Failed to initiate download.", link: null }));
        }
    };

    const handleDownloadButtonClick = async (initialScreenData) => {
        setIsLoading(true);
        try {
            const response = await dispatch(getMroReportPdfRecords(
                {
                    service_request_id: initialScreenData.service_request_id,
                    date_range: {
                        start_date: screenParams.filters.date_range.start_date,
                        end_date: screenParams.filters.date_range.end_date
                    }
                }
            ));

            if (response.error) {
                throw new Error(response.error.message);
            }

            dispatch(showMessageModal({ message: "Download started successfully.", link: response.payload.pdf_url }));
            return response.payload;
        } catch (error) {
            dispatch(showMessageModal({ message: "Failed to start download.", link: null }));
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const today = new Date();
    const sevenDaysAgo = subDays(today, 7);

    const defaultScreenParams = {
        offset: 0,
        limit: 20,
        filters: {
            date_range: {
                start_date: sevenDaysAgo.toISOString().slice(0, 10),
                end_date: today.toISOString().slice(0, 10)
            }
        }
    };

    useEffect(() => {
        dispatch(getMroScreenDetails(defaultScreenParams));
    }, [dispatch, screenParams]);

    const defaultDateRange = [sevenDaysAgo, today];

    const onFilterChange = (filterName, value) => {
        setScreenParams(prevParams => {
            const updatedParams = {
                ...prevParams,
                offset: 0,
                filters: {
                    ...prevParams.filters,
                    [filterName]: value
                }
            };

            dispatch(getMroScreenDetails(updatedParams));

            return updatedParams;
        });
    };

    return (
        <div className="screens-page-container">
            <div className="screens-header-container">
                <AdminHeader />
            </div>
            <div className="screens-body-container">
                <div className="screens-content-container">
                    <MroSpecimensTable
                        screensArray={mroScreensArray}
                        onDownload={handleDownloadButtonClick}
                        defaultDateRange={defaultDateRange}
                        onFilterChange={onFilterChange}
                    />
                </div>
            </div>
        </div>
    );
};
export default MedicalReviewOfficerPage;
