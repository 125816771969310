// freddy-react/src/store/screens/screensSlice.js
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchScreenFullDetails} from '../../services/api/v1/screenService';
import {makePOSTRequest} from "../../services/utils/httpUtils";
import {fetchAnalyzeScreen} from "../../services/api/v1/analyzeScreenService";

export const getFreddyAnalyzeScreenDetails = createAsyncThunk(
    'screen/getFreddyAnalyzeScreenDetails',
    async ({ deviceIdHash }, thunkAPI) => {
        // console.log("deviceIdHash from screenslice", deviceIdHash); // Log deviceIdHash here
        const accessToken = sessionStorage.getItem("accessToken");
        // console.log("accessToken from screenslice", accessToken); // Log accessToken here
        return await fetchAnalyzeScreen(deviceIdHash, accessToken);
    }
);


const screenAnalyticsSlice = createSlice({
    name: 'screenAnalytics',
    initialState: {
        screenImages: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        clearScreenAnalyticsData: (state) => {
            state.screenImages = [];
            state.status = 'idle';
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFreddyAnalyzeScreenDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getFreddyAnalyzeScreenDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Concatenate new screens with existing screens
                state.screenImages = state.payload
            })
            .addCase(getFreddyAnalyzeScreenDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { clearScreenAnalyticsData } = screenAnalyticsSlice.actions;

export default screenAnalyticsSlice.reducer;
