import "./SpeedSwabsFooter.css";
import React from "react";

const SpeedSwabsFooter = () => {
    return (
        <footer className="speed-swabs-footer">
            <p className="speed-swabs-footer-text">© 2024 SpeedSwabs | Fast. Reliable. Easy.</p>
        </footer>
    );
};

export default SpeedSwabsFooter;
