import "./ClientSelector.css";
import React, {useEffect, useState} from 'react';
import CreatableSelect from 'react-select/creatable';
import { FixedSizeList } from 'react-window';
import {fetchAllClients} from "../../../services/api/v1/clientService";
import {useDispatch} from "react-redux";
import {selectClient} from "../../../store/autobot/clients/clientsSlice";
import {setClients} from "../../../store/clients/clientsSlice";

const ClientSelector = () => {
    const dispatch = useDispatch();
    const [allClients, setAllClients] = useState([]);

    useEffect(() => {
        fetchMoreClients();
    }, []);

    const fetchMoreClients = async () => {
        try {
            const accessToken = sessionStorage.getItem("accessToken");
            const allClients = await fetchAllClients(accessToken);
            dispatch(setClients(allClients));
            setAllClients(allClients)

        } catch (error) {
            console.error(error);
        }
    };


    const handleClientIdSearch = (selectedValue) => {
        if (selectedValue) {
            dispatch(selectClient(selectedValue.value));
        } else {
            dispatch(selectClient(null));
        }
    };




    // Calculate the height of the virtualized list
    const calculateListHeight = () => {
        // Adjust this value to determine the desired height of each option
        const optionHeight = 35;
        // Adjust this value to determine the desired number of visible options
        const visibleOptions = 10;
        return optionHeight * visibleOptions;
    };

    return (
        <div>
                <div className="labb-clients-search">
                    {allClients ? (
                        <CreatableSelect
                            components={{
                                MenuList: (props) => {
                                    const { options, children, maxHeight, getValue } = props;
                                    const [value] = getValue();
                                    const initialOffset = options.indexOf(value) * 35;

                                    return (
                                        <FixedSizeList
                                            height={calculateListHeight()} // Invoke calculateListHeight function
                                            itemCount={options.length}
                                            itemSize={35}
                                            initialScrollOffset={initialOffset}
                                            width="100%" // Assign a valid width value here
                                        >
                                            {({ index, style }) => (
                                                <div style={style}>{children[index]}</div>
                                            )}
                                        </FixedSizeList>
                                    );
                                },
                            }}
                            placeholder="Search Client to add displayed data..."
                            isClearable
                            closeMenuOnSelect={true}
                            onChange={handleClientIdSearch}
                            options={allClients.map(({ company, id }) => ({
                                label: company,
                                value: id,
                            }))}
                            className="search-input"
                        />
                    ) : null}
                </div>
        </div>
    );
};

export default ClientSelector;

