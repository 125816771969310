import React from 'react';
import "./NotificiationsList.css";
import NotificationItem from "./notification_item/NotificationItem";

function NotificationList({ onAcknowledgeNotification, onReviewNotification }) {

    const userNotifications = [
        {
            id: 1,
            type: 'client',
            content: 'This is modified client notification',
            description: 'This is a client notification',
        },
        {
            id: 2,
            type: 'client',
            content: 'This is a client notification',
            description: 'This is a client notification',
        },
        {
            id: 3,
            type: 'specimen',
            content: 'This is a specimen notification',
            description: 'This is a specimen notification',
        },
    ]


    return (
        <div className="notification-list-container">
            {userNotifications && userNotifications.map(notification => (
                <NotificationItem
                    key={notification.id}
                    notification={notification}
                    onAcknowledgeNotification={onAcknowledgeNotification}
                    onReviewNotification={onReviewNotification}
                />
            ))}
        </div>
    );
}


export default NotificationList;

