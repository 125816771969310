import {makeGETRequest, makePOSTRequest} from "../../utils/httpUtils";

export const fetchOrders = (accessToken) => {
    return makeGETRequest('/api/v1/clients/orders/list', accessToken);
};

export const makeOrderInvoice = (accessToken, orderDetails) => {
    const formData = new FormData();
    formData.append('zoho_access_token', orderDetails.zohoAccessToken);
    formData.append('order_details', JSON.stringify(orderDetails.orderDetails));
    return makePOSTRequest('/api/v1/external/zoho/event/create/invoice/order/new', formData, accessToken);
};

export const fetchOrdersPickList = (accessToken) => {
    return makeGETRequest('/api/v1/clients/orders/pick', accessToken);
};

export const fetchOrderDetails = (accessToken, orderId) => {
    return makeGETRequest(`/api/v1/client/order/${orderId}/details`, accessToken);
}