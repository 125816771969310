
// freddy-react/src/store/modals/modalsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    uploadStatus: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    successMessage: '',
    errorMessage: '',
    isModalOpen: false,
    message: '',
    link: null,
    isTalkSwabsModalOpen: false,
};

const modalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        startUpload: state => {
            state.uploadStatus = 'loading';
        },
        uploadSuccess: (state, action) => {
            state.uploadStatus = 'succeeded';
            state.successMessage = action.payload;
        },
        uploadFailure: (state, action) => {
            state.uploadStatus = 'failed';
            state.errorMessage = action.payload;
        },
        showMessageModal: (state, action) => {
            state.isModalOpen = true;
            state.message = action.payload.message;
            state.link = action.payload.link;
        },
        hideMessageModal: state => {
            state.isModalOpen = false;
            state.message = '';
            state.link = null;
        },
        toggleTalkSwabsModal: (state, action) => {
            state.isTalkSwabsModalOpen = action.payload;
        },
    },
});

export const {
    startUpload,
    uploadSuccess,
    uploadFailure,
    showMessageModal,
    hideMessageModal,
    toggleTalkSwabsModal,
} = modalsSlice.actions;

export default modalsSlice;