import {useEffect, useState} from "react";

const ReviewScan = ({
                        screenDetails
                    }) => {
    const [readInfo, setReadInfo] = useState(null);

    return (
        <div>
            <section className="secondary-evaluation">
                {/*{readInfo && readInfo.sides ? (*/}
                {/*    <ResultPanel*/}
                {/*        readInfo={readInfo}*/}
                {/*        checkedItems={null}*/}
                {/*        updateCheckedItems={null}*/}
                {/*    />*/}
                {/*) : null}*/}
            </section>
        </div>
    );
};

export default ReviewScan;
