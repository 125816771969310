import React, {useEffect} from 'react';
import QuestSubAccountRow
    from "../quest_account_organizations/quest_account_organization_row/QuestAccountOrganizationRow";
import LoadingSpinningCup from "../../../../../pages/loading/Loading";

const QuestSubAccountModal = ({
                                  selectedMainClientAccount,
                                  filteredQuestSubAccounts,
                                  updateFilteredQuestSubAccount
                              }) => {

    if (!filteredQuestSubAccounts || filteredQuestSubAccounts.length === 0) {
        // This ensures nothing is rendered or a placeholder is shown if there are no sub-accounts
        return <LoadingSpinningCup/>
    }


    return (
        <div className="quest-sub-account-modal">
            {filteredQuestSubAccounts.map((subAccount, index) => (

                <div>
                    {
                    subAccount ?
                        <div key={`${subAccount["Client Number"]} - ${index}`}>
                            <QuestSubAccountRow
                                selectedMainClientAccount={selectedMainClientAccount}
                                filteredQuestSubAccount={subAccount}
                                updateFilteredQuestSubAccount={updateFilteredQuestSubAccount}
                            />
                        </div> : null
                }
                </div>
            ))}
        </div>
    );
};

export default QuestSubAccountModal;
