// src/store/external/upsAuthSlice.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchShopifyAdminAuthToken, fetchShopifyAuthToken} from "../../services/api/v1/shopifyAuthTokenService";

const initialState = {
    shopifyAuth: {
        access_token: null,
        id_token: null,
    },
    isLoading: false,
    error: null,
};

export const getShopifyAuth = createAsyncThunk(
    'external/fetchShopifyAuthToken',
    async (_, thunkAPI) => {
        try {
            const response = await fetchShopifyAuthToken();
            const tokenData = response.access_token; // Assuming response.data contains the token information
            sessionStorage.setItem("shopifyAccessToken", tokenData);
            sessionStorage.setItem("shopifyTokenExpiry", Date.now() + (tokenData.expires_in * 1000));
            return tokenData;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getShopifyAdminAuth = createAsyncThunk(
    'external/fetchShopifyAdminAuthToken',
    async ({ grantToken }, thunkAPI) => {
        const accessToken = sessionStorage.getItem("accessToken");
        try {
            if (!grantToken || !accessToken) {
                console.log("shopifyAdminGrantToken or accessToken not provided.");
            }

            const response = await fetchShopifyAdminAuthToken(grantToken, accessToken);

            const tokenData = response.access_token;

            if (!tokenData) {
                throw new Error("Access token not found in the response.");
            }

            sessionStorage.setItem("shopifyAdminAccessToken", tokenData);

            return tokenData;
        } catch (error) {
            console.error("Error fetching Shopify Admin Token: ", error); // Add detailed error logging
            return thunkAPI.rejectWithValue(error.response?.data || "Token fetching failed");
        }
    }
);

const shopifyAuthSlice = createSlice({
    name: 'shopifyAuth',
    initialState,
    reducers: {
        clearShopifyAuth: (state) => {
            state.shopifyAuth = null;
        },
        setShopifyAuth: (state, action) => {
            state.shopifyAuth = null;
            state.isLoading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getShopifyAuth.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getShopifyAuth.fulfilled, (state, action) => {
                state.shopifyAuth = action.payload;
                state.isLoading = false;
            })
            .addCase(getShopifyAuth.rejected, (state, action) => {
                state.error = action.error.message;
                state.shopifyAuth = null;
                state.isLoading = false;
            });
    }
});

export const { clearShopifyAuth, setShopifyAuth } = shopifyAuthSlice.actions;
export default shopifyAuthSlice.reducer;
