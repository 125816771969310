import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getClientOrderDetails } from "../../../store/orders/ordersSlice";
import LoadingSpinningCup from "../../../pages/loading/Loading";
import "./ClientOrderBilling.css";

const ClientOrderBilling = () => {
    const dispatch = useDispatch();
    const customerDetails = useSelector(state => state.shopifyUser.customerDetails);
    const orderDetails = useSelector(state => state.orders?.customerOrderDetails);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (customerDetails?.id) {
            setLoading(true);
            dispatch(getClientOrderDetails(customerDetails?.id))
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
        }
    }, [dispatch, customerDetails?.id]);

    if (loading) {
        return <LoadingSpinningCup />;
    }

    if (!orderDetails || !orderDetails.orders || !orderDetails.orders.edges.length) {
        return <p>No orders placed though Labb.com online store.</p>;
    }

    return (
        <div className="order-billing-container">
            {orderDetails.orders.edges.map((orderEdge, index) => {
                const order = orderEdge.node;
                const createdAtDate = new Date(order.createdAt);
                const formattedDate = createdAtDate.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
                const formattedTime = createdAtDate.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });

                return (
                    <div key={order.id} className="order-card">
                        <div className="order-header">
                            <div className="order-date-time">
                                <div className="order-time">{formattedTime}</div>
                                <div className="order-date">{formattedDate}</div>
                            </div>
                            <div className="order-number">
                                {order.name}
                            </div>
                        </div>

                        {/* Payee and Payment Status Box */}
                        <div className="payee-payment-container">
                            <div className="payee-info-box">
                                <div className="payee-name">
                                    {order.shippingAddress?.firstName} {order.shippingAddress?.lastName}
                                </div>
                                <div className="payee-address">
                                    {order.shippingAddress?.address1}<br />
                                    {order.shippingAddress?.address2 && <>{order.shippingAddress?.address2}<br /></>}
                                    {order.shippingAddress?.city}, {order.shippingAddress?.country}
                                </div>
                            </div>
                            <div className="payment-status-box">
                                {order.paymentInformation.paymentStatus}
                            </div>
                        </div>

                        <table className="line-items-table">
                            <tbody>
                            {order.lineItems.edges.map((itemEdge, itemIndex) => {
                                const item = itemEdge.node;
                                return (
                                    <tr key={itemIndex}>
                                        <td>{item.title}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.price.amount} {item.price.currencyCode}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>

                        <div className="order-summary">
                            <div>Total Shipping: {order.totalShipping.amount} {order.totalShipping.currencyCode}</div>
                            <div>Total Price: {order.totalPrice.amount} {order.totalPrice.currencyCode}</div>
                            {order.paymentInformation.paymentCollectionUrl && (
                                <div className="payment-link">
                                    <a href={order.paymentInformation.paymentCollectionUrl} target="_blank" rel="noopener noreferrer">
                                        Pay Now
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ClientOrderBilling;
