import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './ClientNumberDetails.css';
import { IconButton } from '../../buttons/icon_button/IconButton';
import { GenericIcons } from '../../../assets/icons';
import { toggleClientSearchAccountsModal } from "../../../store/laboratory/laboratoryAccountSlice";

const ClientNumberDetails = () => {
    const isVisible = useSelector(state => state.laboratorySlice.isClientNumberDetailsVisible);
    const clientNumberDetails = useSelector(state => state.laboratorySlice.laboratoryClientAccountNumbers) || [];
    const dispatch = useDispatch();

    if (!isVisible) return null;

    const handleClose = () => {
        dispatch(toggleClientSearchAccountsModal(false));
    };

    return (
        <div className="client-number-modal-overlay">
            <div className="client-number-modal-content">
                <div className="client-number-modal-header">
                    <IconButton onClick={handleClose} defaultIcon={GenericIcons.CloseIcon} hoverIcon={GenericIcons.ExitIcon} />
                </div>
                <div className="client-number-modal-body">
                    {clientNumberDetails.length > 0 ? (
                        clientNumberDetails.map((detail, index) => (
                            <div key={index} className="client-number-detail">
                                <h3>{detail.organization_name || 'N/A'}</h3>
                                <p>{detail.client_name || 'N/A'}</p>
                            </div>
                        ))
                    ) : (
                        <p>No client number details found.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ClientNumberDetails;