import {SaveIcons} from "../assets/icons";
import {useState} from "react";

function CopyToClipboard({text, rowData}) {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = (event, rowData) => {
        event.stopPropagation();
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 5000); // Revert back after 5 seconds
    };


    return (
        <div>
            {copied ?
                <div onClick={(event) => copyToClipboard(event, rowData)}>
                    <img src={SaveIcons.SavedIcon} alt="Copy"/>
                </div>
                :
                <div onClick={(event) => copyToClipboard(event, rowData)}>
                    <img src={SaveIcons.SaveIcon} alt="Copy"/>
                </div>

            }
        </div>
    );
}

export default CopyToClipboard;