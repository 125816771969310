import React from 'react';
import './Logout.css';
import {msalInstance} from "../../../index";
import {HeaderIcons} from "../../../assets/icons";

function Logout() {
    const handleLogout = () => {
        msalInstance.logoutPopup({
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/"
        });
    };

    return (
        <button className="logout-btn" onClick={handleLogout}>
            <img src={HeaderIcons.LogoutIcon} alt="Logout" />
        </button>
    );
}

export default Logout;
