// navbarSlice.js (Create a new Redux slice for the selected tab index)
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedTabIndex: 0,
};

const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    setSelectedTabIndex: (state, action) => {
      state.selectedTabIndex = action.payload;
    },
  },
});

export const { setSelectedTabIndex } = navbarSlice.actions;

export default navbarSlice.reducer;
