import React from 'react';
import './ShopifySpeedCartTotalDetails.css';

const ShopifySpeedCartTotalDetails = ({ cart }) => {
    const estimatedTotal = cart?.cost.totalAmount.amount; // Default value as per example

    return (
        <div className="cart-total-details">
            <div>
                <div className="cart-total-header">
                    <h2>Estimated Total</h2>
                    <div className="cart-total-price">
                        <span>{estimatedTotal}</span>
                    </div>
                </div>
                <p className="cart-disclaimer">Taxes, discounts, and shipping calculated at checkout.</p>
            </div>
        </div>
    );
};

export default ShopifySpeedCartTotalDetails;
