import React, { useState, useEffect } from "react";
import { PrimaryButton } from "../../../components/buttons/primary_button/PrimaryButton";
import axios from "axios";
import "./FreddyPublicLocationManagementPage.css";
import PublicFreddyHeader from "../../../components/headers/public_freddy_header/PublicFreddyHeader";
import PublicFreddyLogo from "../../../assets/logo/PublicFreddyLogo.png";

const FreddyPublicLocationManagementPage = () => {
    const [locations, setLocations] = useState([]);
    const [newLocationName, setNewLocationName] = useState('');
    const [newLocationAddress, setNewLocationAddress] = useState('');
    const [editingLocationId, setEditingLocationId] = useState(null);
    const [editedLocationName, setEditedLocationName] = useState('');
    const [editedLocationAddress, setEditedLocationAddress] = useState('');


    const onLogout = null;

    useEffect(() => {
        // Fetch locations when the page loads
        fetchLocations();
    }, []);

    const fetchLocations = async () => {
        try {
            const response = await axios.get('/api/v1/locations');
            setLocations(response.data);
        } catch (error) {
            console.error("Failed to fetch locations", error);
        }
    };

    const handleAddLocation = async () => {
        if (!newLocationName || !newLocationAddress) {
            alert("Please provide both name and address for the new location.");
            return;
        }

        try {
            const locationData = {
                name: newLocationName,
                address: newLocationAddress,
            };

            await axios.post('/api/v1/locations', locationData);
            alert("Location added successfully!");
            setNewLocationName('');
            setNewLocationAddress('');
            fetchLocations(); // Refresh the list of locations
        } catch (error) {
            console.error("Failed to add location", error);
        }
    };

    const handleEditLocation = async () => {
        if (!editedLocationName || !editedLocationAddress) {
            alert("Please provide both name and address for the location.");
            return;
        }

        try {
            const locationData = {
                name: editedLocationName,
                address: editedLocationAddress,
            };

            await axios.put(`/api/v1/locations/${editingLocationId}`, locationData);
            alert("Location updated successfully!");
            setEditingLocationId(null);
            setEditedLocationName('');
            setEditedLocationAddress('');
            fetchLocations(); // Refresh the list of locations
        } catch (error) {
            console.error("Failed to update location", error);
        }
    };

    const handleEditClick = (location) => {
        setEditingLocationId(location.id);
        setEditedLocationName(location.name);
        setEditedLocationAddress(location.address);
    };

    return (
        <div className="freddy-public-location-management-page">

            <PublicFreddyHeader logoSrc={PublicFreddyLogo} onLogout={onLogout}/>

            {/* Add New Location Section */}
            <div className="location-add-section">
                <h2>Add New Location</h2>
                <div className="form-group">
                    <label htmlFor="newLocationName">Location Name:</label>
                    <input
                        type="text"
                        id="newLocationName"
                        value={newLocationName}
                        onChange={(e) => setNewLocationName(e.target.value)}
                        placeholder="Enter location name"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="newLocationAddress">Location Address:</label>
                    <input
                        type="text"
                        id="newLocationAddress"
                        value={newLocationAddress}
                        onChange={(e) => setNewLocationAddress(e.target.value)}
                        placeholder="Enter location address"
                    />
                </div>
                <PrimaryButton onClick={handleAddLocation}>Add Location</PrimaryButton>
            </div>

            {/* Locations List Section */}
            <div className="location-list-section">
                <h2>Current Locations</h2>
                <ul>
                    {locations.map((location) => (
                        <li key={location.id}>
                            <span>{location.name} - {location.address}</span>
                            <button onClick={() => handleEditClick(location)}>Edit</button>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Edit Location Section */}
            {editingLocationId && (
                <div className="location-edit-section">
                    <h2>Edit Location</h2>
                    <div className="form-group">
                        <label htmlFor="editedLocationName">Location Name:</label>
                        <input
                            type="text"
                            id="editedLocationName"
                            value={editedLocationName}
                            onChange={(e) => setEditedLocationName(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="editedLocationAddress">Location Address:</label>
                        <input
                            type="text"
                            id="editedLocationAddress"
                            value={editedLocationAddress}
                            onChange={(e) => setEditedLocationAddress(e.target.value)}
                        />
                    </div>
                    <PrimaryButton onClick={handleEditLocation}>Save Changes</PrimaryButton>
                </div>
            )}
        </div>
    );
};

export default FreddyPublicLocationManagementPage;
