// utils/authentication.js


import {setIsLoggedIn, setIsRegistered, setToken, setUserId, setUsername} from "../store/user/userSlice";
import {authenticateUser} from "../services/api/v1/userService";

/**
 * Asynchronously authenticates a user with a given token and updates the application state.
 *
 * @param {string} token - The authentication token to be used for user authentication.
 * @param {function} dispatch - The dispatch function provided by Redux to update the state.
 * @returns {boolean} true if authentication is successful, false otherwise.
 */
export const authenticateWithToken = async (token, dispatch) => {
    try {
        const response = await authenticateUser({ token });

        dispatch(setToken(token));
        dispatch(setUserId(response.user_id));
        dispatch(setUsername(response.user_name));
        dispatch(setIsLoggedIn(true));

        if (response.user_name) {
            dispatch(setIsRegistered(true));
        }

        return true; // Authentication successful
    } catch (error) {
        return false; // Authentication failed
    }
};

/**
 * Asynchronously refreshes the user's access token.
 *
 * This function sends a request to authenticate the user using the current access token stored in sessionStorage.
 * If successful, it updates the token stored in session storage and sets the new token's expiry time.
 *
 * @param {function} dispatch - The dispatch function from a Redux store to update the token
 * @returns {boolean} - Returns true if the access token was successfully refreshed, false otherwise
 */
export const refreshToken = async (dispatch) => {
    try {
        const response = await authenticateUser({ token: sessionStorage.getItem("accessToken") });

        dispatch(setToken(response.token));

        // Store the new token's expiry time
        sessionStorage.setItem("tokenExpiry", Date.now() + (response.expires_in * 1000));

        return true;
    } catch (error) {
        console.error("Token refresh failed:", error);
        return false;
    }
};