import axios from "axios";

export const fetchClientOrderDetails = async (clientShopifyId) => {
    try {
        const shopifyOauthToken = sessionStorage.getItem('shopifyAccessToken');

        if (!shopifyOauthToken) {
            throw new Error('Shopify access token is missing');
        }

        const graphqlQuery = {
            query: `
                query {
                    customer {
                    id
                    orders(first: 10) {
                      edges {
                        node {
                          id
                          name
                          createdAt
                          shippingAddress {
                            address1
                            address2
                            city
                            company
                            country
                            firstName
                            lastName
                          }
                          billingAddress {
                            address1
                            address2
                            city
                            company
                            country
                            firstName
                            lastName
                          }
                          lineItems(first: 5) {
                            edges {
                              node {
                                title
                                quantity
                                price {
                                    amount
                                    currencyCode
                                }
                              }
                            }
                          }
                          totalShipping {
                            amount
                            currencyCode
                          }
                          totalPrice {
                            amount
                            currencyCode
                          }
                          paymentInformation {
                            paymentCollectionUrl
                            paymentStatus
                          }
                        }
                      }
                    }
                  }
                }
            `,
            variables: {
                "id": clientShopifyId,
            },
        };

        const response = await axios.post(
            'https://shopify.com/66278916260/account/customer/api/2024-07/graphql',
            graphqlQuery,
            {
                headers: {
                    'Authorization': `${shopifyOauthToken}`,  // Ensure 'Bearer' prefix is used
                    'Content-Type': 'application/json',
                },
            }
        );

        if (response.status === 200 && response.data.data) {
            return response.data.data.customer;  // Return only the customer data
        } else {
            throw new Error('Failed to fetch customer details');
        }
    } catch (error) {
        console.error('Error occurred while fetching customer details:', error);
        throw error;  // Let the calling function handle the error
    }
};
