import React from "react";

const Note = ({ note }) => {
    // Destructuring the note object for easier access to fields
    const { created_at, note: noteContent, subject, specimen_note_id } = note;

    // Format date and time
    const formattedDate = new Date(created_at).toLocaleDateString(undefined, {
        month: "2-digit",
        day: "2-digit",
    });
    const formattedTime = new Date(created_at).toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    });

    return (
        <div className="note-box">
             {subject? (
                          <div className="note-full-container">
                          <div className="note-header">
                          <div className="note-specimen-id">
                                  <h3>Email</h3>
                              </div>
          
                              <div className="date-container">
                                  <h3>{formattedDate} {formattedTime}</h3>
                              </div>
                             
                          </div>

                          <div className="note-header">
                          <div className="note-specimen-id">
                                  <h3>RE: {subject}</h3>
                              </div>
          
                             
                          </div>

                          <div className="note-container">
                              <p>{noteContent}</p>
                          </div>
                      </div>
                    )
                    :  
                      <div className="note-full-container">
                    <div className="note-header">
                    <div className="note-specimen-id">
                            <h3>{specimen_note_id}</h3>
                        </div>
    
                        <div className="date-container">
                            <h3>{formattedDate} {formattedTime}</h3>
                        </div>
                       
                    </div>
                    <div className="note-container">
                        <p>{noteContent}</p>
                    </div>
                </div>}
        
        </div>
    );
};

export default Note;
