// freddy-react/src/store/autobot/login/loginSlice.js
import {createSlice} from '@reduxjs/toolkit';

export const loginSlice = createSlice({
    name: 'labb_login',
    initialState: {
        username: '',
        password: '',
    },
    reducers: {
        setUserName: (state, action) => {
            state.username = action.payload;
        },
        setPassword: (state, action) => {
            state.password = action.payload;
        }
    },
});

export const { setUserName, setPassword } = loginSlice.actions;
export default loginSlice.reducer;
