// freddy-react/src/services/api/v1/speedCartService.js
import { makeUnauthGETRequest, makeUnAuthPOSTRequest } from "../../utils/httpUtils";

// Fetch an existing cart from the backend without access token
export const fetchSpeedCart = (cartId) => {
    const formData = new FormData();

    formData.append('shopify_speed_cart_id', cartId);

    return makeUnAuthPOSTRequest(`/api/v1/external/shopify/cart/speed/details/list`, formData);
};

// Create a new cart in the backend without access token
export const createSpeedCart = () => {
    return makeUnauthGETRequest('/api/v1/external/shopify/cart/speed/create/new');
};


// Update an existing cart using POST
export const updateSpeedCart = (cartData) => {
    return makeUnAuthPOSTRequest(`/api/v1/external/shopify/cart/speed/update/existing`, cartData);
};


// Remove an item from the cart
export const removeItemFromCart = (cartId, lineItemId) => {
    const formData = new FormData();
    formData.append('shopify_speed_cart_id', cartId);
    formData.append('shopify_speed_line_item_id', lineItemId);

    return makeUnAuthPOSTRequest(`/api/v1/external/shopify/cart/speed/remove/item`, formData);
};

// Remove an item from the cart
export const addItemToCart = (cartId, variantId, quantity) => {
    const formData = new FormData();
    formData.append('shopify_speed_cart_id', cartId);
    formData.append('shopify_speed_variant_id', variantId);
    formData.append('shopify_speed_quantity', quantity);

    return makeUnAuthPOSTRequest(`/api/v1/external/shopify/cart/speed/add/new`, formData);
};