// freddy-react/src/components/header/nav/Nav.js
import React, {useEffect} from 'react';
import Icon from "./icons/Icon";
import "./Nav.css"

import {useDispatch, useSelector} from "react-redux";
import {HeaderIcons} from "../../../../assets/icons";
import {getAllNotifications, toggleModal} from "../../../../store/notifications/notificationsSlice";


const NavBar = ({userRoles, onNavBarClick, handleUploadButtonClick}) => {
    const selectedTabIndex = useSelector(state => state.navbar.selectedTabIndex);
    const notifications = useSelector(state => state.notifications);
    const dispatch = useDispatch();

    // Helper function to check if a user has a given role
    const hasRole = (role) => userRoles.includes(role);

    useEffect(() => {
        dispatch(getAllNotifications());
    });

    const handleIconClick = (index) => () => {
        onNavBarClick(index);
    };

    const getIconClassName = (index) => {
        return selectedTabIndex === index ? "selectedIconTab" : "";
    };

    const handleUploadIconClick = () => {
        handleIconClick(3)();
        handleUploadButtonClick()
    };



    const handleNotificationsClick = () => {
        handleIconClick(6)
        dispatch(toggleModal())
    }

    const handleShopifyClick = () => {
        handleIconClick(7)
        dispatch(toggleModal())
    }


    return (
        <div className="navbar-container">
            {hasRole('Labb.Specimen.Details') && (
            <div onClick={handleIconClick(0)}>

                    <div className="nav-item">
                        <Icon iconSrc={HeaderIcons.SpeicmenRapidCupIcon} className={getIconClassName(0)}
                              routePath="/screens"/>
                    </div>
            </div>
                    )}
            {hasRole('Freddy.Panels') && (
            <div onClick={handleIconClick(0)}>
                    <div className="nav-item">
                        <Icon iconSrc={HeaderIcons.PanelsIcon} className={getIconClassName(0)}
                              routePath="/panels"/>
                    </div>
            </div>
            )}
            {hasRole('Freddy.Laboratory') && (
                <div onClick={handleIconClick(1)}>

                    <div className="nav-item">
                        <Icon iconSrc={HeaderIcons.LaboratoryIcon} className={getIconClassName(1)} routePath="/laboratory"/>
                    </div>

            </div>
            )}
            {hasRole('Labb.Mro.Reports') && (
            <div onClick={handleIconClick(2)}>
                    <div className="nav-item">
                        <Icon iconSrc={HeaderIcons.PersonIcon} className={getIconClassName(2)}
                              routePath="/medical-review-officer"/>
                    </div>
            </div>
            )}
            {hasRole('Labb.Billing.Reports') && (
            <div onClick={handleIconClick(2)}>
                    <div className="nav-item">
                        <Icon iconSrc={HeaderIcons.DollarSignIcon} className={getIconClassName(2)}
                              routePath="/zoho/billing"/>
                    </div>
            </div>
            )}
            {hasRole('Labb.Billing.Reports') && (
            <div onClick={handleIconClick(2)}>
                    <div className="nav-item">
                        <Icon iconSrc={HeaderIcons.DollarSignIcon} className={getIconClassName(2)}
                              routePath="/quickbooks/billing"/>
                    </div>
            </div>
            )}
            {hasRole('Freddy.Billing.Invoice.Reports') && (
            <div onClick={handleIconClick(3)}>
                    <div className="nav-item">
                        <Icon iconSrc={HeaderIcons.BillingInvoicesIcon} className={getIconClassName(2)}
                              routePath="/invoices"/>
                    </div>
            </div>
            )}
            {hasRole('Labb.Email') && (
            <div onClick={handleIconClick(4)}>
                    <div className="nav-item">
                        <Icon iconSrc={HeaderIcons.EmailIcon} className={getIconClassName(2)}
                              routePath="/email"/>
                    </div>
            </div>
            )}
            {hasRole('Freddy.Orders') && (
                <div onClick={handleIconClick(5)}>

                    <div className="nav-item">
                        <Icon iconSrc={HeaderIcons.BoxIcon} className={getIconClassName(5)} routePath="/orders"/>
                    </div>
                </div>
            )}

            {hasRole('Labb.Client.Management') && (
            <div onClick={handleIconClick(6)}>

                    <div className="nav-item">
                        <Icon iconSrc={HeaderIcons.ClientIcon} className={getIconClassName(3)} routePath="/clients"/>
                    </div>
            </div>
            )}
            {hasRole('Freddy.Manage.Shopify') && (
            <div onClick={handleIconClick(7)}>

                    <div className="nav-item">
                        <Icon iconSrc={HeaderIcons.ShopifyIcon} className={getIconClassName(7)} routePath="/shopify"/>
                    </div>
            </div>
            )}
            {hasRole('Freddy.Uploader') && (
            <div onClick={handleUploadIconClick}>
                <div className="nav-item">
                    <Icon iconSrc={HeaderIcons.UploadIcon}/>
                </div>
            </div>
            )}

            {hasRole('Freddy.Notifier') && (
                <div onClick={handleNotificationsClick}>
                    <div className="nav-item">
                        <Icon iconSrc={HeaderIcons.BellIcon} className={getIconClassName(7)}/>
                    </div>
                </div>
            )}
        </div>
    );
}


export default NavBar;
