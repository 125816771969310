import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {saveLaboratoryAccountRecords} from "../../../../../store/laboratory/laboratoryAccountSlice";
import {IconButton} from "../../../../buttons/icon_button/IconButton";
import {GenericIcons} from "../../../../../assets/icons";
import {fetchOrganizationsByClientId} from "../../../../../store/autobot/clients/organizations/organizationsSlice";

const UnlinkedAccountRow = ({ unlinkedQuestAccount, clientOptions }) => {
    const dispatch = useDispatch();
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedOrgAccount, setSelectedOrgAccount] = useState(null);
    const [orgOptions, setOrgOptions] = useState([]);
    const allOrganizations = useSelector(state => state.organizations.organizationData);

    useEffect(() => {
        if (selectedAccount) {
            const selectedClientAccountId = selectedAccount.value;
            dispatch(fetchOrganizationsByClientId({ selectedClientAccountId }));
        }
    }, [selectedAccount, dispatch]);

    useEffect(() => {
        if (allOrganizations) {
            const loadedOptions = allOrganizations.map(organization => ({
                value: organization.organization_id || organization.client_id,  // Fallback to client_id if organization_id is null
                label: `${organization.organization_name}` || `${organization.client_name}`  // Fallback to client_name if organization_name is null, with additional context
            }));
            setOrgOptions(loadedOptions);
        }
    }, [allOrganizations]);

    const handleChange = (selectedOption) => {
        setSelectedAccount(selectedOption);
        setSelectedOrgAccount(null); // Reset organization when client changes
    };

    const handleOrgAccountChange = (selectedOption) => {
        setSelectedOrgAccount(selectedOption);

        // Find the organization in the list to get the client_id and client_name
        const organizationDetails = allOrganizations.find(org => org.organization_id === selectedOption.value);
        if (organizationDetails) {

            setSelectedAccount({
                value: organizationDetails.client_id,
                label: organizationDetails.client_name
            });
        }
    };

    const handleSave = () => {
        if (selectedAccount && selectedOrgAccount) {
            dispatch(saveLaboratoryAccountRecords({
                client_id: selectedAccount.value,
                client_name: selectedAccount.label,
                client_address: unlinkedQuestAccount?.client_address,
                client_number: unlinkedQuestAccount.laboratory_account_number,
                ss_organization_id: selectedOrgAccount.value
            }));
            setSelectedAccount(null); // Reset the selected account after saving
            setSelectedOrgAccount(null); // Reset the selected organization
        }
    };

    return (
        <div className="account-match">
            <div className="account-info">
                <label className="account-label">Account Number:</label>
                <span className="account-number">{unlinkedQuestAccount.laboratory_account_number}</span>
            </div>
            <div className="select-client-container">
                <label className="select-label">Link to Client:</label>
                <Select
                    options={clientOptions}
                    value={selectedAccount}
                    onChange={handleChange}
                    placeholder="Select an Account"
                    className="account-select"
                />
            </div>
            <div className="select-organization-container">
                <label className="select-label">Link to Organization:</label>
                <Select
                    options={orgOptions}
                    value={selectedOrgAccount}
                    onChange={handleOrgAccountChange}
                    placeholder="Select an Organization"
                    className="account-select"

                />
            </div>
            <div className="save-button-container">
                {(selectedOrgAccount) && (
                    <IconButton
                        defaultIcon={GenericIcons.CheckIcon}
                        hoverIcon={GenericIcons.CheckIcon}
                        onClick={handleSave}
                        className="save-button"
                    />
                )}
            </div>

        </div>
    );
};

export default UnlinkedAccountRow;
