import React, { useEffect, useState } from "react";
import "./Invoices.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchExistingClientInvoices, checkJobStatusAsync, startPolling, stopPolling } from "../../store/clients/clientBillingDetailsSlice";
import { getUserRolesFromToken } from "../../helpers/token/processToken";
import InvoiceFilters from "../../components/invoices/invoice_filters/InvoiceFilters";
import InvoiceList from "../../components/invoices/update_invoice/invoice_list/InvoiceList";
import AdminHeader from "../../components/headers/admin_header/AdminHeader";

const InvoicesPage = () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const userRoles = getUserRolesFromToken(accessToken);
    const dispatch = useDispatch();
    const existingClientInvoices = useSelector((state) => state.clientBillingDetails.existingClientInvoices);
    const totalOverallInvoiceLabbCost = useSelector((state) => state.clientBillingDetails.totalOverallInvoiceLabbCost);
    const totalOverallInvoiceRetailPrice = useSelector((state) => state.clientBillingDetails.totalOverallInvoiceRetailPrice);
    const polling = useSelector((state) => state.clientBillingDetails.polling);
    const jobId = useSelector((state) => state.clientBillingDetails.jobId);
    const [screenParams, setScreenParams] = useState({ offset: 0, limit: 20, filters: {} });
    const [groupedInvoices, setGroupedInvoices] = useState([]);

    useEffect(() => {
        const today = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

        const defaultScreenParams = {
            offset: 0,
            limit: 20,
            filters: {
                date_range: {
                    start_date: thirtyDaysAgo.toISOString().slice(0, 10),
                    end_date: today.toISOString().slice(0, 10)
                }
            }
        };

        setScreenParams(defaultScreenParams);
        dispatch(fetchExistingClientInvoices(defaultScreenParams))
            .then((action) => {
                if (action.meta.requestStatus === 'fulfilled' && action.payload.job_id) {
                    dispatch(startPolling(action.payload.job_id));
                }
            });
    }, [dispatch]);

    useEffect(() => {
        if (existingClientInvoices?.length) {
            const grouped = groupInvoices(existingClientInvoices);
            setGroupedInvoices(grouped);
        }
    }, [existingClientInvoices]);

    useEffect(() => {
        let interval;
        if (polling && jobId) {
            interval = setInterval(() => {
                dispatch(checkJobStatusAsync(jobId));
            }, 5000); // Poll every 5 seconds
        }
        return () => clearInterval(interval);
    }, [polling, jobId, dispatch]);

    const onFilterChange = (filterName, value) => {
        setScreenParams(prevParams => {
            const updatedParams = {
                ...prevParams,
                offset: 0,
                filters: {
                    ...prevParams.filters,
                    [filterName]: value
                }
            };

            dispatch(fetchExistingClientInvoices(updatedParams))
                .then((action) => {
                    if (action.meta.requestStatus === 'fulfilled' && action.payload.job_id) {
                        dispatch(startPolling(action.payload.job_id));
                    }
                });

            return updatedParams;
        });
    };

    const formatCurrency = (amount) => {
        const num = parseFloat(amount);
        return isNaN(num) ? "$0.00" : `$${num.toFixed(2)}`;
    };

    return (
        <div className="billing-page-invoice-container">
            <section>
                <AdminHeader />
            </section>
            <section className="billing-section lab-section">
                <div className="screens-header-container">
                    <InvoiceFilters onApplyFilters={onFilterChange} />
                </div>
            </section>
            <section className="billing-section lab-section">
                <div className="net-gain-loss-summary overall-summary">
                    <div className="cost-bubble overall-client-cost">{formatCurrency(totalOverallInvoiceRetailPrice)}</div>
                    <div className="cost-bubble overall-net-gain-loss">
                        {formatCurrency(totalOverallInvoiceRetailPrice - totalOverallInvoiceLabbCost)}
                    </div>
                    <div className="cost-bubble overall-labb-cost">{formatCurrency(totalOverallInvoiceLabbCost)}</div>
                </div>
                <div className="screens-content-container">
                    <InvoiceList invoices={groupedInvoices} />
                </div>
            </section>
        </div>
    );
}

export default InvoicesPage;

const groupInvoices = (data) => {
    const invoicesMap = {};

    data.forEach(item => {
        const invoiceId = item.zoho_invoice_id;

        if (!invoicesMap[invoiceId]) {
            invoicesMap[invoiceId] = {
                invoice_number: item.zoho_invoice_number,
                date: item.collected_on,
                status: item.invoice_status_id,
                client_total: parseFloat(item.client_invoice_total) || 0,
                labb_total: parseFloat(item.labb_invoice_total) || 0,
                invoice_url: item.invoice_url,
                location_name: item.location_name, // Store common location name
                line_items: []
            };
        }

        // Add line item with all necessary details
        invoicesMap[invoiceId].line_items.push({
            client_id: item.client_id,
            client_name: item.client_name,
            client_site_location_id: item.client_site_location_id,
            collected_on: item.collected_on,
            device_id: item.device_id,
            invoice_status_id: item.invoice_status_id,
            invoice_url: item.invoice_url,
            labb_cost: parseFloat(item.labb_cost) || 0,
            organization_name: item.organization_name,
            organization_non_dot_account_number: item.organization_non_dot_account_number,
            organization_sub_account_number: item.organization_sub_account_number,
            panel_codes: item.panel_codes, // Move panel_codes to individual line item
            payment_made: item.payment_made,
            product_name: item.product_name,
            results: item.results,
            retail_price: parseFloat(item.retail_price) || 0,
            sample_type: item.sample_type,
            service_level: item.service_level,
            specimen_id: item.specimen_id,
            zoho_books_id: item.zoho_books_id,
            zoho_invoice_id: item.zoho_invoice_id,
            zoho_invoice_number: item.zoho_invoice_number,
            client_invoice_total: item.client_invoice_total,
            labb_invoice_total: item.labb_invoice_total,
        });
    });

    return Object.values(invoicesMap);
};
