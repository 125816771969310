import React from 'react';
import { SecondaryButton } from '../../../../buttons/secondary_button/SecondaryButton';
import { PrimaryButton } from '../../../../buttons/primary_button/PrimaryButton';
import './Navigation.css';

const FormStepNavigation = ({ handleNext, handlePrevious, isLastStep = false }) => (
    <div className="talk-swabs-button-group">
        {handlePrevious && (
            <SecondaryButton
                type="button"
                className="talk-swabs-secondary-button"
                onClick={handlePrevious}
                fullWidth={true}
            >
                Previous
            </SecondaryButton>
        )}
        <PrimaryButton
            type="button"
            className="talk-swabs-primary-button"
            onClick={handleNext}
            fullWidth={true}
        >
            {isLastStep ? 'Submit' : 'Next'}
        </PrimaryButton>
    </div>
);

export default FormStepNavigation;
