import { makePOSTRequest } from "../../utils/httpUtils";

export const setLinkedProducts = ({linkedProductsData, accessToken}) => {

    console.log(
        "Product linked data:", linkedProductsData.lssProduct
    )

    const formData = new FormData();
    formData.append('shopify_product_id', linkedProductsData.shopifyProduct.node.id);
    formData.append('lss_product_id', linkedProductsData.lssProduct.product_id);

    return makePOSTRequest(`/api/v1/external/shopify/product/link/details`, formData, accessToken);
};