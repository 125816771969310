import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SpeedSwabDashboard.css";
import NavBubble from "../../../components/modals/nav_bubble/NavBubble";
import SpeedSwabsOverview from "../../../components/speed_swab/overview/SpeedSwabsOverview";
import SpeedSwabsFeatures from "../../../components/speed_swab/features/SpeedSwabsFeatures";
import SpeedSwabsCompare from "../../../components/speed_swab/compare/SpeedSwabsCompare";
import { getPublicShopifyProductDetails } from "../../../store/products/shopifyProductsSlice";

const SpeedSwabsDashboard = () => {
    const [activeTab, setActiveTab] = useState('overview');
    const [isSticky, setIsSticky] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 50) { // Change the threshold to make the NavBubble blend into the header
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);


    // Set document title based on domain
    const docTitle = "SpeedSwabs | Dashboard";

    useEffect(() => {
        document.title = docTitle;
    }, [docTitle]);


    useEffect(() => {
        // Dispatch the action to fetch Shopify product details
        dispatch(getPublicShopifyProductDetails({}));
    }, [dispatch]);

    return (
        <div className="speed-swabs-dashboard">
            {/* Content section */}
            <div className="speed-swabs-content">
                <div className="overview-section">
                    <div className={`speed-swabs-nav-bubble-container ${isSticky ? 'sticky' : ''}`}>
                        <NavBubble activeTab={activeTab} setActiveTab={setActiveTab} />
                    </div>

                    {/* Conditionally render content based on active tab */}
                    {activeTab === "overview" && <SpeedSwabsOverview />}
                    {activeTab === "features" && <SpeedSwabsFeatures />}
                    {/*{activeTab === "compare" && <SpeedSwabsCompare />}*/}
                </div>
            </div>
        </div>
    );
};

export default SpeedSwabsDashboard;
