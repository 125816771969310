import { fetchTestingReasons } from '../../services/api/v1/testingReasonsService';
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const getTestingReasons = createAsyncThunk(
    'testingReason/getTestingReasons',
    async ({ accessToken, offset, LIMIT }, thunkAPI) => {
        return await fetchTestingReasons(accessToken, offset, LIMIT);
    }
);



const testingReasonSlice = createSlice({
    name: 'testingReason',
    initialState: {
        testingReasons: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        // ... any other reducers ...
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTestingReasons.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getTestingReasons.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.testingReasons = action.payload || [];
            })
            .addCase(getTestingReasons.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default testingReasonSlice.reducer;
