// freddy-react/src/store/products/lssProductsSlice.js

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {setLinkedProducts} from "../../services/api/v1/linkProductService";


export const linkProducts = createAsyncThunk(
    'linkProducts/setLinkedProducts',
    async ({ accessToken, linkedProductsData }, thunkAPI) => {
        return await setLinkedProducts({linkedProductsData, accessToken});
    }
);



export const linkedShopifyProductsSlice = createSlice({
    name: 'linkedProducts',
    initialState: {
        linkedShopifyProducts: [],
        isLoading: false,
        error: null,
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(linkProducts.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(linkProducts.fulfilled, (state, action) => {
                state.linkedShopifyProducts = action.payload;
                state.isLoading = false;
            })
            .addCase(linkProducts.rejected, (state, action) => {
                state.isLoading = false;
            });
    }
});


export default linkedShopifyProductsSlice.reducer;


