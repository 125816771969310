import "./SpeedSwabsHeader.css";
import React, { useState, useEffect } from "react";
import {NavLink, useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HeaderIcons } from "../../../assets/icons";
import { PrimaryButton } from "../../buttons/primary_button/PrimaryButton";
import { IconButton } from "../../buttons/icon_button/IconButton";
import { getShopifyOAuthUrl } from "../../user/sign_in/SignIn";
import { getCartId, setCartId } from "../../../utils/checkCart";
import {createCart, toggleSpeedCartModal} from "../../../store/shopify/cart/shopifySpeedCartSlice";

const SpeedSwabsHeader = ({ logoSrc, onLogout }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const shopifyAccessToken = useSelector((state) => state.shopifyAuth.shopifyAuth);
    const speedSwabsCollectorDetails = useSelector((state) => state.shopifyUser.speedSwabsCollectorDetails);

    const [isScrollingUp, setIsScrollingUp] = useState(false); // Track scroll direction
    const [lastScrollY, setLastScrollY] = useState(0); // Track last scroll position
    const [isHeaderVisible, setIsHeaderVisible] = useState(true); // Track header visibility

    // Corrected function to handle cart icon click
    const handleCartIconClick = async () => {
        let cartId = getCartId();

        // If no cart ID exists, create an empty cart
        if (!cartId) {
            const response = await dispatch(createCart({}));  // Create an empty cart with no line items
            const newCartId = response.payload.id;
            setCartId(newCartId); // Store the new cartId in localStorage
            dispatch(toggleSpeedCartModal(true));
        } else {
            // Handle existing cart scenario if needed
            console.log("Cart already exists with ID:", cartId);
            dispatch(toggleSpeedCartModal(true));
        }



    };

    // Track scroll direction and hide/show the header accordingly
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            // If scrolling up, show the header; if scrolling down, hide it
            if (currentScrollY < lastScrollY) {
                setIsScrollingUp(true);
                setIsHeaderVisible(true); // Show header
            } else {
                setIsScrollingUp(false);
                setIsHeaderVisible(false); // Hide header
            }

            setLastScrollY(currentScrollY); // Update last scroll position
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollY]);

    return (
        <>
            {/* Conditional rendering for header visibility */}
            {isHeaderVisible && (
                <header className="speed-swabs-header">
                    <div className="logo-container">
                        <NavLink to="/" className="logo-link">
                            <img className="logo" src={logoSrc} alt="SpeedSwabs Logo" />
                        </NavLink>
                    </div>
                    <div className="header-right">
                        {shopifyAccessToken ? (
                            <nav className="navbar-container">
                                {speedSwabsCollectorDetails?.can_manage_panels ? (
                                    <div className="nav-item">
                                        <NavLink to="/panels">
                                            <img
                                                src={HeaderIcons.PanelsIcon}
                                                alt="Panels"
                                                className="nav-icon"
                                            />
                                        </NavLink>
                                    </div>
                                ) : null}

                                {speedSwabsCollectorDetails?.can_order_cartons ? (
                                    <div className="nav-item">
                                        <NavLink to="/orders">
                                            <img
                                                src={HeaderIcons.OrdersIcon}
                                                alt="Orders"
                                                className="nav-icon"
                                            />
                                        </NavLink>
                                    </div>
                                ) : null}
                            </nav>
                        ) : (
                            <div>Login required</div>
                        )}
                        <div className="buy-now-container">
                            <IconButton
                                onClick={handleCartIconClick} // Pass the function reference, not invocation
                                defaultIcon={HeaderIcons.CartIcon}
                                hoverIcon={HeaderIcons.CartIcon}
                                alt="Checkout Cart Icon"
                            />
                        </div>
                    </div>
                </header>
            )}
        </>
    );
};

export default SpeedSwabsHeader;
