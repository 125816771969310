import React, {useState, useEffect} from "react";
import './Donor.css';
import {fetchDonorDetails} from "../../../../../services/api/v1/donorService";
import {GenericIcons} from "../../../../../assets/icons";
import {IconButton} from "../../../../buttons/icon_button/IconButton";

const Donor = ({device_id, donorDetails, onDonorNameChange, accessToken}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [donors, setDonors] = useState([]);
    const [originalDonor, setOriginalDonor] = useState(donorDetails); // Store the original donor details
    const [selectDonor, setSelectedDonor] = useState(donorDetails);
    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    const [searchQuery, setSearchQuery] = useState(''); // reintroduce search state
    const [showDropdown, setShowDropdown] = useState(false); // control the dropdown display
    const LIMIT = 20;

    useEffect(() => {
        if (isEditing) {
            const accessToken = sessionStorage.getItem('accessToken');
            fetchDonorDetails({"device_id": device_id}, accessToken, offset, LIMIT)
                .then(response => {
                    setDonors(response);
                })
                .catch(error => {
                    console.error("Error fetching donor details:", error);
                });
        }
    }, [isEditing, accessToken]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleScroll = (e) => {
        const dropdown = e.target;
        if (dropdown.scrollHeight - dropdown.scrollTop === dropdown.clientHeight && hasMore) {
            setOffset(prev => prev + LIMIT);
        }
    };

    const formatSelectDonor = (donor) => {
        console.log("donor", donor);
        return {
            donor_id: donor.donor_id,
            original_donor_id: originalDonor.donor_id,
            donor_name: {
                donor_first_name: donor.donor_first_name,
                donor_last_name: donor.donor_last_name
            }
        }
    }

    const handleDonorSelect = (donor) => {
        const formattedDonor = formatSelectDonor(donor);
        setSelectedDonor(formattedDonor);
        onDonorNameChange(formattedDonor);
        setIsEditing(false);
    };

    const toggleEdit = (e) => {
        e.preventDefault();
        setIsEditing(!isEditing);
    };

    return (
        <section>
            <div className="donor">
                <div className="donor_header">
                    <p className="donor_header_text">Donor Details</p>
                    {!isEditing
                        ? <IconButton onClick={toggleEdit} defaultIcon={GenericIcons.EditIcon} hoverIcon={GenericIcons.EditIcon} />
                        : <IconButton onClick={toggleEdit} defaultIcon={GenericIcons.CloseIcon} hoverIcon={GenericIcons.ExitIcon} />
                    }
                </div>
                <div className="dark_grey_strip"/>
                <div className="donor_info_section">
                    {isEditing ? (
                        <>
                            <input
                                className="donor-searchable-dropdown"
                                type="text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                onFocus={() => setShowDropdown(true)}
                                onBlur={() => setTimeout(() => setShowDropdown(false), 150)} // A delay to allow the dropdown item to be clicked
                                placeholder="Search donor..."
                            />
                            {showDropdown && (
                                <div className="dropdown-content" onScroll={handleScroll}>
                                    {donors && donors.length > 0 ? (
                                        donors
                                            .filter(donor =>
                                                `${donor?.donor_first_name} ${donor?.donor_last_name}`
                                                    .toLowerCase()
                                                    .includes(searchQuery.toLowerCase())
                                            )
                                            .map(donor => (
                                                <div className="donor_dropdown_row" key={donor.donor_id}
                                                     onClick={() => handleDonorSelect(donor)}>
                                                    <p className="donor_dropdown_row_text">
                                                        {donor?.donor_first_name} {donor?.donor_last_name}
                                                    </p>
                                                </div>
                                            ))
                                    ) : (
                                        <div className="donor_dropdown_row">
                                            <p className="donor_dropdown_row_text">No donors found</p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    ) : (
                        <p className="donor_dropdown_text">{selectDonor.donor_name?.donor_first_name} {selectDonor.donor_name?.donor_last_name}</p>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Donor;
