import "./PublicFreddyHeader.css";
import React from "react";
import { NavLink } from "react-router-dom";
import { IconButton } from "../../buttons/icon_button/IconButton";
import { GenericIcons } from "../../../assets/icons";
import {useSelector} from "react-redux";

const PublicFreddyHeader = ({ logoSrc, onLogout }) => {
    const publicToken = useSelector((state) => state.publicUser?.publicAccessToken);


    return (
        <header className="public-freddy-header">
            <div className="public-freddy-header__container">
                {/* Left - Logo */}
                <div className="public-freddy-header__left">
                    <NavLink to="/" className="public-freddy-header__logo-link">
                        <img className="public-freddy-header__logo" src={logoSrc} alt="Freddy Logo" />
                    </NavLink>
                </div>

                {/* Right - Icons */}
                <div className="public-freddy-header__right">
                    {
                        publicToken ?
                            <NavLink to="/users" className="public-freddy-header__icon-link">
                                <IconButton defaultIcon={GenericIcons.PersonIcon} hoverIcon={GenericIcons.PersonIcon} />
                            </NavLink> : null
                    }
                    {
                        publicToken ?
                            <NavLink to="/locations" className="public-freddy-header__icon-link">
                                <IconButton defaultIcon={GenericIcons.LocationsIcon} hoverIcon={GenericIcons.LocationsIcon} />
                            </NavLink>: null
                    }
                    {/* Add more icons as needed */}
                </div>
            </div>
        </header>
    );
};

export default PublicFreddyHeader;
