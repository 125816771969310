import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCollectorByEmail, getShopifyCustomerDetails } from "../../../store/user/shopifyUserSlice";
import './ShopifyCustomerDetails.css';
import { PrimaryButton } from "../../buttons/primary_button/PrimaryButton";
import { getCollectorByEmail } from "../../../services/api/v1/userService";
import LoadingSpinningCup from "../../../pages/loading/Loading";


const ShopifyCustomerDetails = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [customerDetails, setCustomerDetails] = useState(null);

    const shopifyOauthToken = sessionStorage.getItem('shopifyAccessToken');

    useEffect(() => {
        const fetchCustomerAndCollectorDetails = async () => {
            try {
                // Fetch customer details from Shopify
                console.log('Fetching customer details using Shopify API...');
                const customerDetails = await dispatch(getShopifyCustomerDetails({ shopifyOauthToken })).unwrap();
                // console.log('Customer details:', customerDetails);
                setCustomerDetails(customerDetails);

                // Fetch collector details using customer email
                if (customerDetails?.emailAddress) {
                    const formData = new FormData();
                    formData.append('collector_email', customerDetails.emailAddress.emailAddress);
                    formData.append('shopify_oauth_token', shopifyOauthToken);

                    await dispatch(fetchCollectorByEmail({ formData })).unwrap();
                } else {
                    throw new Error("Customer email address is missing.");
                }
            } catch (error) {
                console.error("Error fetching details:", error);
                setError("Error fetching customer or collector details.");
            } finally {
                setLoading(false);
            }
        };

        fetchCustomerAndCollectorDetails();
    }, [dispatch, shopifyOauthToken]);

    if (loading) {
        return <LoadingSpinningCup />;
    }

    return (
        <div className="shopify-customer-details">
            {customerDetails ? (
                <div>
                </div>
            ) : (
                <div>No customer details available</div>
            )}
        </div>
    );
};

export default ShopifyCustomerDetails;
