// src/components/product_prices/prices_row/PricesRow.js

import React from 'react';
import './PricesRow.css';

const PricesRow = ({ price, handlePriceChange, index }) => {
    // Safely access properties to prevent undefined errors
    const productName = price?.['Product Name'] || 'Unknown Product';
    const productPrice = price?.['Product Price (per kit)'] || 0;

    return (
        <div className="product-price-row">
            <div className="product-price-item product-name">
                <span id={`product-${index}`} className="product-name-value">
                    {productName}
                </span>
            </div>
            <div className="product-price-item product-price">
                <input
                    type="number"
                    id={`price-${index}`}
                    className="product-price-input"
                    value={productPrice}
                    onChange={(e) => handlePriceChange(index, 'Product Price (per kit)', e.target.value)}
                />
            </div>
        </div>
    );
};

export default PricesRow;
