import './AdminHeader.css';
import React, {useCallback, useEffect, useState} from "react";
import NavBar from "./nav/Nav";
import {NavLink, useNavigate} from "react-router-dom";
import {useDispatch} from 'react-redux';
import {getUserRolesFromToken} from "../../../helpers/token/processToken";
import Logout from "../../../pages/user/logout/Logout";
import PasteUpload from "../../modals/uploads/paste_upload/PasteUpload";
import {setSelectedTabIndex} from "../../../store/navbar/navbarSlice";
import {setOrganization} from "../../../store/autobot/clients/organizations/organizationsSlice";
import {setEmployees} from "../../../store/autobot/employees/employeesSlice";
import {setCynergyRedArrowInvoices} from "../../../store/invoices/cynergyRedArrowInvoiceSlice";
import {setCynergyWorxInvoices} from "../../../store/invoices/cynergyWorxInvoiceSlice";
import {setQuestInvoices} from "../../../store/invoices/questInvoiceSlice";
import {setOmegaInvoices} from "../../../store/invoices/omegaInvoiceSlice";
import {setDesertToxInvoices} from "../../../store/invoices/desertToxInvoiceSlice";
import {setQuestAccounts, showQuestAccountsModal} from "../../../store/external/questAccountsSlice";
import {setServicePrices} from "../../../store/prices/servicePricesSlice";
import {setDeviceIdHash} from "../../../store/device/deviceSlice";
import {uploadReport, uploadUrls} from "../../../services/api/v1/uploadService";
import { setProductDetailsPrices } from "../../../store/prices/productPricesSlice";

function AdminHeader() {
    const accessToken = sessionStorage.getItem('accessToken');
    const [userRoles, setUserRoles] = useState([]);
    const hiddenFileInput = React.useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showModal, setShowModal] = React.useState(false);

    useEffect(() => {
        const token = sessionStorage.getItem('accessToken');
        if (token) {
            const roles = getUserRolesFromToken(token);
            setUserRoles(roles);
        }
    }, []);

    const handleNavBarClick = (index) => {
        dispatch(setSelectedTabIndex(index));
    };

    const handleUploadButtonClick = useCallback(async () => {
        try {

            if (!navigator.clipboard || typeof navigator.clipboard.readText !== 'function') {
                console.error('Clipboard API not supported');
                setShowModal(true); // Show the modal
                return;
            }

            const clipboardText = await navigator.clipboard.readText();

            if (clipboardText) {
                const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
                if (urlRegex.test(clipboardText)) {
                    console.log("URL from clipboard:", clipboardText);
                    const formData = new FormData();
                    formData.append('clipboard_url', clipboardText);
                    console.log("formdata", clipboardText);
                    const deviceIdHash  = await uploadUrls(formData, accessToken);

                    if (deviceIdHash) {
                        // Dispatch action to save deviceIdHash to the store
                        dispatch(setDeviceIdHash(deviceIdHash));

                        navigate("/screen/review")

                    } else {
                        console.warn("URL upload failed.");
                    }

                } else {
                    console.warn("Clipboard does not contain a valid URL.");
                    hiddenFileInput.current.click(); // Open the file upload selector
                }
            } else {
                console.warn("Clipboard is empty.");
                hiddenFileInput.current.click(); // Open the file upload selector
            }
        } catch (error) {
            console.error("Error reading clipboard:", error);
            try{
                hiddenFileInput.current.click(); // Open the file upload selector
            }catch(e){
                console.error("Error reading clipboard22:", e);
            }
         
        }
    }, []);


    const openDocumentSelector = () => {
        hiddenFileInput.current.click(); // Open the file upload selector
    }

    const handleReportUpload = async event => {
        event.preventDefault();

        const filesUploaded = event.target.files;
        if (!filesUploaded || filesUploaded.length === 0) return;

        const formData = new FormData();
        for (let file of filesUploaded) {
            formData.append('uploaded_report', file);
        }

        try {
            const data = await uploadReport(formData, accessToken);

            console.log(data);

            if (data.hasOwnProperty('screeners')) {
                console.log("Screener report received:", data.screeners);
                // dispatch(setScreener(data.screeners));
                navigate('/automation/review');
            } else if (data.hasOwnProperty('organizations')) {
                console.log("Organization report received:", data.organizations);
                dispatch(setOrganization(data.organizations));
            } else if (data.hasOwnProperty('employees')) {
                console.log("Employee report received:", data.employees);
                dispatch(setEmployees(data.employees));
                navigate('/automation/review');
            } else if (data.hasOwnProperty('cynergy_red_arrow_invoices')) {
                console.log("Cynergy Red Arrow Invoice report received:", data.cynergy_red_arrow_invoices);
                dispatch(setCynergyRedArrowInvoices(data.cynergy_red_arrow_invoices));

            } else if  (data.hasOwnProperty('cynergy_worx_invoices')){
                console.log("Cynergy Worx Invoice report received:", data.cynergy_worx_invoices);
                dispatch(setCynergyWorxInvoices(data.cynergy_worx_invoices));

            } else if (data.hasOwnProperty('quest_invoices')) {
                console.log("Quest Invoice report received:", data.quest_invoices);
                dispatch(setQuestInvoices(data.quest_invoices));

            } else if (data.hasOwnProperty('omega_invoices')) {
                console.log("Omega Invoice report received:", data.omega_invoices);
                dispatch(setOmegaInvoices(data.omega_invoices));

            } else if (data.hasOwnProperty('desert_tox_invoices')) {
                console.log("Desert Tox Invoice report received:", data.desert_tox_invoices);
                dispatch(setDesertToxInvoices(data.desert_tox_invoices));
            } else if (data.hasOwnProperty('quest_accounts')) {
                dispatch(setQuestAccounts(data.quest_accounts)); // Dispatch action to save questAccounts
                dispatch(showQuestAccountsModal()); // Show the success modal
            } else  if (data.hasOwnProperty('service_prices')) {
                dispatch(setServicePrices(data.service_prices));
            } else  if (data.hasOwnProperty('product_prices')) {
                dispatch(setProductDetailsPrices(data.product_prices));
            } else {
                console.warn("Unknown data type received:", data);
            }
            event.target.value = null;
        } catch (error) {
            console.error("Error uploading report:", error);
            event.target.value = null;
        }
    };


    // const tabOptions = [
    //     {id: 'recent', label: 'SPECIMEN TRACKING', component: SpecimenTracking},
    // ];
    return (
        <header className="main-header">
            <NavLink to="/" className="nav-button">
                <img className="header-logo"
                     src="https://static.wixstatic.com/media/40f727_b17ccfdda56f41e494a6586affdeae2e~mv2.png"
                     alt="Labb Logo"/>
            </NavLink>
            <div className="nav-items">
                <form onSubmit={handleUploadButtonClick}>
                    <input ref={hiddenFileInput} id="fileUpload" style={{display: 'none'}} type="file"
                           onChange={handleReportUpload}/>
                </form>
                <div className="nav-item">
                    <div>
                        <NavBar userRoles={userRoles} onNavBarClick={handleNavBarClick} handleUploadButtonClick={handleUploadButtonClick}/>
                    </div>
                </div>

                <div className="nav-item">
                    <Logout/>
                </div>
                {/*<div>*/}
                {/*<Tabs tabOptions={tabOptions}/>*/}
                {/*</div>*/}
            </div>
            <PasteUpload
                showModal={showModal}
                onClose={() => setShowModal(false)}
                onEnter={openDocumentSelector}
            />



        </header>
    )
        ;
}

export default AdminHeader;



