import React, {useEffect, useState} from "react";
import "./Laboratory.css";
import Specimens from "../../components/specimens/Specimens";
import {convertSpecimenIdToHash} from "../../services/api/v1/screenService";
import {clearScreenData, getScreenDetails} from "../../store/screen/screenSlice";
import {useDispatch, useSelector} from "react-redux";
import {clearOrganization} from "../../store/autobot/clients/organizations/organizationsSlice";
import ScreenModal from "../../components/modals/edit/screen/ScreenModal";
import {getLaboratoryBillingRecords} from "../../store/laboratory/laboratorySlice";
import {getUserRolesFromToken} from "../../helpers/token/processToken";
import {setLoading} from "../../store/clients/clientsSlice";
import {getScreens} from "../../store/screens/screensSlice";
import LoadingSpinningCup from "../loading/Loading";
import AdminHeader from "../../components/headers/admin_header/AdminHeader";

const LaboratoryPage = () => {

    const dispatch = useDispatch();
    const [records, setRecords] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [screenParams, setScreenParams] = useState({offset: 0, limit: 20, filters: {}});
    const userRoles = getUserRolesFromToken(sessionStorage.getItem("accessToken"));
    const [isEditing, setIsEditing] = useState(false);
    const [currentSpecimen, setCurrentSpecimen] = useState(null);
    const laboratoryRecords = useSelector(state => state.laboratory.records);

    useEffect(() => {
        setIsLoading(true);
        const fetchRecords = async () => {
            const weekAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
            const today = new Date();
            const defaultScreenParams = {
                offset: 0,
                limit: 20,
                filters: {
                    date_range: {
                        start_date: weekAgo.toISOString().split('T')[0],
                        end_date: today.toISOString().split('T')[0]
                    }
                }
            };

            await dispatch(getLaboratoryBillingRecords(defaultScreenParams));
            setIsLoading(false);
        };

        fetchRecords();
    }, [dispatch]);

    const handleEdit = async (specimenData) => {
        try {
            const accessToken = sessionStorage.getItem("accessToken");

            const deviceData = {
                device_id: specimenData.device_id
            };

            const response = await convertSpecimenIdToHash(deviceData, accessToken);
            const updatedSpecimenData = {
                ...specimenData,
                device_id_hash: response
            };

            if (updatedSpecimenData.device_id_hash) {
                dispatch(clearOrganization());
                dispatch(clearScreenData());
                setCurrentSpecimen(prevSpecimen => updatedSpecimenData);
                setIsModalOpen(true);
                setIsEditing(true);
            }
        } catch (error) {
            console.error("An error occurred while converting specimen_id to device_id_hash:", error);
        }
    };

    const onFilterChange = (filterName, value) => {
        setScreenParams(prevParams => {
            const updatedParams = {
                ...prevParams,
                offset: 0,
                filters: {
                    ...prevParams.filters,
                    [filterName]: value
                }
            };

            dispatch(getLaboratoryBillingRecords(updatedParams));

            return updatedParams;
        });
    };

    return (
        <div className="laboratory-page-container">
            <div className="screens-header-container">
                <AdminHeader />
            </div>
            <div className="screens-body-container">
                <div>
                    {isLoading && <LoadingSpinningCup/>}
                </div>
                <div className="screens-content-container">
                    <Specimens
                        userRoles={userRoles}
                        screensArray={laboratoryRecords}
                        onEdit={handleEdit}
                        onFilterChange={onFilterChange}
                    />
                </div>
            </div>
            {/*<Footer />*/}
        </div>
    );
}

export default LaboratoryPage;
