import React, { useEffect, useState } from 'react';
import './CountdownTimer.css';

const CountdownTimer = ({ duration, onComplete }) => {
    const [timeLeft, setTimeLeft] = useState(duration);

    useEffect(() => {
        if (timeLeft === 0) {
            onComplete();
            return;
        }

        const interval = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [timeLeft, onComplete]);

    const progress = (timeLeft / duration) * 100;

    return (
        <div className="countdown-timer">
            <div className="countdown-progress" style={{ width: `${progress}%` }} />
        </div>
    );
};

export default CountdownTimer;