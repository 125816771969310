// src/store/invoices/omegaInvoiceSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const cynergyWorxInvoiceSlice = createSlice({
    name: 'mroCynergyWorxInvoices',
    initialState: {
        invoices: [],
    },
    reducers: {
        setCynergyWorxInvoices: (state, action) => {
            state.invoices = action.payload;
        },
        clearCynergyWorxInvoices: (state) => {
            state.invoices = [];
        },
    },
});

export const { setCynergyWorxInvoices, clearCynergyWorxInvoices } = cynergyWorxInvoiceSlice.actions;

export default cynergyWorxInvoiceSlice.reducer;