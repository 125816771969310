// freddy-react/src/components/autobot/review/Review.js
import "./Review.css";
import React from 'react';
import ReviewScreeners from "./screeners/Screeners";
import ReviewOrganization from "./organizations/Organizations";
import ReviewEmployees from "./employees/Employees";

function Review({ screeners, organizations, employees }) {


    return (
        <div>
            {screeners && screeners > 0 ? <ReviewScreeners screeners={screeners}/> : null}
            {organizations && organizations.length > 0 ? <ReviewOrganization organizations={organizations}/> : null}
            {employees  && employees.length > 0 ? <ReviewEmployees employees={employees} /> : null}
        </div>
    );
}

export default Review;