import React, { useEffect } from 'react';
import './ClientOrders.css';
import ClientOrderCard from "./client_order_card/ClientOrderCard";
import { useSelector, useDispatch } from 'react-redux';
import { getOrderDetails, getOrders, toggleOrderDetailModal } from '../../store/orders/ordersSlice';


const ClientOrders = ({ selectedProducts }) => {
    const dispatch = useDispatch();
    const clientOrders = useSelector(state => state.orders.orders);

    useEffect(() => {
        if (Object.keys(clientOrders).length === 0) {
            dispatch(getOrders());
        }
    }, [dispatch, clientOrders]);

    const onSelectedOrderClick = (orderId) => {
        dispatch(toggleOrderDetailModal());
        dispatch(getOrderDetails(orderId));
    };

    const filteredOrders = selectedProducts.length > 0
        ? Object.values(clientOrders).filter(order =>
            order.box_details.some(box => selectedProducts.includes(box.product_name)))
        : Object.values(clientOrders);

    return (
        <div className="orders-table">
            {filteredOrders.length > 0 ? filteredOrders.map(clientOrder => (
                <ClientOrderCard key={clientOrder.order_id} clientOrderDetails={clientOrder} onSelectedOrderClick={onSelectedOrderClick} />
            )) : (
                <div>No orders found.</div>
            )}
        </div>
    );
};

export default ClientOrders;