import React from 'react';
import './IndustrySelector.css';

const IndustrySelector = ({ selectedIndustry, industries, handleSelect }) => {
    return (
        <div className="industry-selector-container">
            {industries.map((industry) => (
                <button
                    key={industry.id}
                    className={`industry-selector-button ${selectedIndustry === industry.label ? 'industry-selector-button-selected' : ''}`}
                    onClick={() => handleSelect(industry.label)}
                >
                    {industry.label}
                </button>
            ))}
        </div>
    );
};

export default IndustrySelector;
