import React from 'react';
import './PricesRow.css';

const PricesRow = ({ price }) => {


    const specimenTypeColors = {
        'Blood': '#F78E1E',         // A mellow orange
        'Urine': '#F09583',         // A soft coral
        'Oral': '#90AFC5',          // Soft blue
        'Collection': '#B0C4DE',    // Light steel blue
        'Hair': '#FFE4B5',          // Moccasin, light peach
        'Instant Device': '#ADD8E6', // Light blue
        'Physicals': '#AFEEEE',     // Pale turquoise
        'Sendout': '#87CEFA',       // Light sky blue
        'Urine - MPP': '#FFD700',   // Gold
        'x-KITS': '#E6E6FA',        // Lavender
        'Urine - Non-DOT': '#98FB98', // Pale green
        'Urine - DOT': '#FA8072',     // Salmon
        'Urine - Steroid': '#BA55D3', // Medium orchid
        'Z_Other': '#FFEFD5',        // Papaya whip, for miscellaneous items
    };


    const markupColors = {
        high: '#3CB371', // Soft green
        medium: '#F0A830', // Soft orange
        low: '#DD5144', // Soft red
        veryLow: '#E5E5E5', // Light grey to indicate low markup
    };

    const shouldUseWhiteText = (backgroundColor) => {
        // Check if backgroundColor is undefined or not a string
        if (typeof backgroundColor !== 'string' || !backgroundColor) {
            return false; // Default to false or set a default background color
        }

        // Removing the # from the hex color value
        const color = backgroundColor.replace('#', '');
        const r = parseInt(color.substring(0, 2), 16); // Red
        const g = parseInt(color.substring(2, 4), 16); // Green
        const b = parseInt(color.substring(4, 6), 16); // Blue

        // Calculating luminance
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance < 0.5; // Brightness threshold
    };


// In your component
    const specimenTypeStyle = {
        backgroundColor: specimenTypeColors[price["Specimen Type"]] || '#6f7170', // Default color if not specified
        color: shouldUseWhiteText(specimenTypeColors[price["Specimen Type"]]) ? '#FFFFFF' : '#000000',
        padding: '10px',
        borderRadius: '10px',
    };

    // Function to calculate markup percentage
    const calculateMarkupPercentage = (cost, price) => {
        return ((price - cost) / cost) * 100;
    };

// Function to get markup color
    const getMarkupColor = (markupPercentage) => {
        if (markupPercentage >= 50) return markupColors.high;
        if (markupPercentage >= 25) return markupColors.medium;
        if (markupPercentage >= 5) return markupColors.low;
        return markupColors.veryLow;
    };

// Calculate the markup percentage for the given price
    const markupPercentage = calculateMarkupPercentage(
        parseFloat(price["Laboratory Cost"].substring(1)), // Assuming cost is like "$18.90"
        parseFloat(price["Client Cost"]) // Assuming price is like "$25.04"
    );

    const markupColor = getMarkupColor(markupPercentage);


    return (
        <div className="price-row">
            <div className="price-row-header">
                <h2>{price["Service Name"]}</h2>
                <div className="price-row-service-code">
                    <p>{price["Service Code"]}</p>
                </div>
            </div>
            <div className="price-row-body">
                <div>{price.Laboratory}</div>
                <div style={specimenTypeStyle}>{price["Specimen Type"]}</div>
                <div>{price.Category}</div>
            </div>
            <div className="price-row-costs" style={{backgroundColor: markupColor}}>
                <div>{price["Laboratory Cost"]}</div>
                <div>{markupPercentage.toFixed(2)}%</div>
                <div>{price["Client Cost"]}</div>
            </div>
        </div>
    );
};

export default PricesRow;
