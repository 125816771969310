import React from "react";
import "./ProductImages.css";
import SpeedSwabSingleImage from "../../../../../assets/graphics/SpeedSwabRapidOralFluidTestSingleSwab.png";


const ProductImages = ({ variants, selectedVariant, handleSelectVariant }) => {
    return (
        <div className="product-images">
            {variants.slice().reverse().map((variant, index) => (
                <img
                    key={variant.id}
                    src={SpeedSwabSingleImage}
                    alt={`Variant ${index + 1}`}
                    className={`product-variant-image ${selectedVariant?.id === variant.id ? 'selected' : ''}`}
                    onClick={() => handleSelectVariant(variant)}
                />
            ))}
        </div>
    );
};

export default ProductImages;
