// freddy-react/src/routes/userRoutes.js

import { Route, Routes } from 'react-router-dom';
import AutomationPage from "../pages/automation/AutomationPage";

const AutomationRoutes = ({instance}) => {
    return (
        <Routes>
            <Route path="/review" element={<AutomationPage instance={instance}/>} />
        </Routes>
    );
};

export default AutomationRoutes;