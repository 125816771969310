import React, { useState } from "react";
import "./NoteList.css";
import Note from "./note/Note";
import { IconButton } from "../../../buttons/icon_button/IconButton";
import { GenericIcons } from "../../../../assets/icons";

const NoteList = ({ rowData }) => {
    const [isEditing, setIsEditing] = useState(false);

    const renderNotes = () => rowData.map((note, index) => (
        <Note key={index} note={note} />
    ));

    const renderPlaceholder = () => (
        <div className="note-placeholder">
            <p>Click to Add...</p>
        </div>
    );

    const toggleEdit = (e) => {
        e.preventDefault();
        setIsEditing(!isEditing);
    };

    // Stop propagation to prevent the toggleEdit from firing when clicking inside the modal
    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div className="note-list-items" onClick={toggleEdit}>
            {isEditing && (
                <div className="modal-notes-list" onClick={handleModalClick}>
                    <span className="cross-icon-modal-notes-list">
                        <IconButton onClick={toggleEdit} defaultIcon={GenericIcons.CloseIcon} hoverIcon={GenericIcons.ExitIcon} />
                    </span>
                    <div className="inner-modal-notes-list">
                        <div className="wrapper-notes-data">
                            {rowData && rowData.length > 0 ? renderNotes() : renderPlaceholder()}
                        </div>
                        <div className="input-notes-wrapper">
                            <input placeholder="comment here" />
                            <IconButton defaultIcon={GenericIcons.CheckIcon} hoverIcon={GenericIcons.CheckIcon} />
                        </div>
                    </div>
                </div>
            )}
            {!isEditing && (rowData && rowData.length > 0 ? renderNotes() : renderPlaceholder())}
        </div>
    );
};

export default NoteList;
