// src/store/external/upsAuthSlice.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchUpsAuthToken } from '../../services/api/v1/upsAuthTokenService';

const initialState = {
    upsAuth: null,
    isLoading: false,
    error: null,
};

export const getUpsAuth = createAsyncThunk(
    'external/fetchUpsAuthToken',
    async ({ grantToken }, thunkAPI) => {
        const accessToken = sessionStorage.getItem("accessToken");
        try {
            const response = await fetchUpsAuthToken(grantToken, accessToken);
            const tokenData = response.access_token; // Assuming response.data contains the token information
            sessionStorage.setItem("upsAccessToken", tokenData);
            sessionStorage.setItem("upsTokenExpiry", Date.now() + (tokenData.expires_in * 1000));
            return tokenData;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const upsAuthSlice = createSlice({
    name: 'upsAuth',
    initialState,
    reducers: {
        clearUpsAuth: (state) => {
            state.upsAuth = null;
        },
        setUpsAuth: (state, action) => {
            state.upsAuth = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUpsAuth.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getUpsAuth.fulfilled, (state, action) => {
                // Ensure that if there's already a valid token, it's not overwritten
                if (!state.upsAuth) {
                    state.upsAuth = action.payload;
                }
                state.isLoading = false;
            })
            .addCase(getUpsAuth.rejected, (state, action) => {
                state.error = action.error.message;
                state.upsAuth = null;
                state.isLoading = false;
            });
    }
});

export const { clearUpsAuth, setUpsAuth } = upsAuthSlice.actions;
export default upsAuthSlice.reducer;
