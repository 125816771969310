import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCartId } from '../../../../utils/checkCart';
import { fetchCart, toggleSpeedCartModal } from '../../../../store/shopify/cart/shopifySpeedCartSlice';
import { GenericIcons } from '../../../../assets/icons';
import { IconButton } from '../../../buttons/icon_button/IconButton';
import ShopifySpeedCartActions from "./actions/ShopifySpeedCartActions";
import ShopifySpeedCartItem from "./item/ShopifySpeedCartItem";
import ShopifySpeedCartLargerPanels from "./larger_panels/ShopifySpeedCartLargerPanels";
import './ShopifySpeedCartModal.css';
import ShopifySpeedCartTotalDetails from "./total_details/ShopifySpeedCartTotalDetails";

const ShopifySpeedCartModal = () => {
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.shopifyCart?.cart);
    const isCartModalOpen = useSelector((state) => state.shopifyCart?.isCartModalOpen);
    const cartId = getCartId();

    const toggleModal = () => {
        dispatch(toggleSpeedCartModal(false));

        // Push to dataLayer when closing the cart modal
        window.dataLayer.push({
            event: "close_cart",
            action: "click",
            cartItems: cart?.lines?.edges.map(({ node: item }) => ({
                productId: item.merchandise.id,
                productName: item.merchandise.title,
                productQuantity: item.quantity,
                productPrice: item.merchandise.price?.amount || 0
            })),
            cartTotal: cart?.cost?.totalAmount?.amount || 0,
            cartCurrency: cart?.cost?.totalAmount?.currencyCode || "USD" // Default to USD if currency code isn't available
        });
    };

    // Fetch cart details if not available yet
    useEffect(() => {
        if (isCartModalOpen && !cart) {
            if (cartId.length > 0) {
                dispatch(fetchCart({ cartId }));
            }
        }
    }, [cartId, isCartModalOpen, cart, dispatch]);

    if (!isCartModalOpen) return null; // Return nothing if modal is closed

    return (
        <div className="speed-cart-modal-overlay">
            <div className="speed-cart-modal-container">
                {/* Left Side - Full Blue Background with Inner Section for Content */}
                <div className="speed-cart-left-full">
                    <div className="speed-cart-left-inner">
                        <div className="speed-cart-branding">
                            <h1>SpeedSwabs</h1>
                            <p className="powered-by-labb">powered by Labb</p>
                        </div>
                    </div>
                </div>

                {/* Right Side - Cart Details */}
                <div className="speed-cart-right-section">
                    <div className="speed-cart-header">
                        <h2>Your Cart</h2>
                        <IconButton
                            onClick={toggleModal}
                            hoverIcon={GenericIcons.CloseIcon}
                            defaultIcon={GenericIcons.ExitIcon}
                            className="speed-cart-close-button"
                        />
                    </div>

                    {/* Main Cart Items */}
                    <div className="speed-cart-items-section">
                        {cart?.lines?.edges?.map(({ node: item }) => (
                            <ShopifySpeedCartItem key={item.id} item={item} cart={cart} />
                        ))}
                    </div>

                    {/* Upsell Section */}
                    {/*<ShopifySpeedCartLargerPanels cart={cart} />*/}

                    {/* shopify total cart section */}
                    <ShopifySpeedCartTotalDetails cart={cart} />

                    {/* Cart Footer */}
                    <ShopifySpeedCartActions cart={cart} />
                </div>
            </div>
        </div>
    );
};

export default ShopifySpeedCartModal;
