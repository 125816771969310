import {makePOSTRequest} from "../../utils/httpUtils";

export const fetchShipmentRates = (accessToken, upsAccessToken) => {
    const formData = new FormData();
    formData.append('ups_access_token', upsAccessToken.upsAccessToken);
    formData.append('ship_location_id', JSON.stringify(upsAccessToken.selectedShipLocation));
    formData.append('product_order_id', upsAccessToken.productOrderId);
    formData.append('boxes_detail', JSON.stringify(upsAccessToken.boxesDetailConfiguration));
    return makePOSTRequest(`/api/v1/shipping/rating/product/new`, formData, accessToken);
}

export const fetchShippingLabel = (accessToken, shipmentDetails) => {
    const formData = new FormData();
    formData.append('ups_access_token', shipmentDetails.upsAccessToken);
    formData.append('ship_location_id', JSON.stringify(shipmentDetails.selectedShipAddress));
    formData.append('product_order_id', shipmentDetails.productOrderId);
    formData.append('boxes_detail', JSON.stringify(shipmentDetails.boxesDetailConfiguration));
    formData.append('service_detail_code', shipmentDetails.selectedServiceCode);
    return makePOSTRequest(`/api/v1/shipping/label/product/new`, formData, accessToken);
}