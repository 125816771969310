// SpeedSwabsFeaturesCompare.js - Updated to Use a More Interactive and Modern Table Design
import React from "react";
import "./SpeedSwabsFeaturesCompare.css";
import { SpeedIcons } from "../../../../assets/icons";

const SpeedSwabsFeaturesCompare = ({ comparisonData }) => {
    return (
        <div className="speed-swabs-features">
            <div className="comparison-table">
                <div className="comparison-table-row header">
                    <div className="table-cell criteria-header"></div>
                    <div className="table-cell speed-swabs">Speed Swabs</div>
                    <div className="table-cell other-tests">Other Oral Fluid Tests</div>
                </div>
                {comparisonData.map((item, index) => (
                    <div className="comparison-table-row" key={index}>
                        <div className="table-cell criteria">
                            <div className="criteria-header">{item.criteria}</div>
                            <div className="criteria-subheader">{item.criteriaSubheader}</div>
                        </div>
                        <div className="table-cell speed-swabs">
                            <div className="icon-and-text">
                                <img src={SpeedIcons.ApproveIcon} alt="Approve" className="custom-check-icon" />
                                <div className="text-container">
                                    <div className="text-header">{item.speedSwabs}</div>
                                    <div className="success-subheader">{item.speedSwabsSubheader}</div>
                                </div>
                            </div>
                        </div>
                        <div className="table-cell other-tests">
                            <div className="icon-and-text">
                                <img src={SpeedIcons.DenyIcon} alt="Deny" className="custom-cross-icon" />
                                <div className="text-container">
                                    <div className="text-header">{item.otherTests}</div>
                                    <div className="fail-subheader">{item.otherTestsSubheader}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SpeedSwabsFeaturesCompare;
