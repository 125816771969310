import React from 'react';
import { useDispatch } from 'react-redux';
import { PrimaryButton } from '../../../../buttons/primary_button/PrimaryButton';
import './ShopifySpeedCartActions.css';
import { SecondaryButton } from "../../../../buttons/secondary_button/SecondaryButton";
import {toggleSpeedCartModal} from "../../../../../store/shopify/cart/shopifySpeedCartSlice";
import {showMessageModal} from "../../../../../store/modals/modalsSlice";

const ShopifySpeedCartActions = ({ cart }) => {
    const dispatch = useDispatch();

    const toggleModal = () => {
        // Push to dataLayer for "continue shopping"

        dispatch(toggleSpeedCartModal(false));

        window.dataLayer.push({
            event: "continue_shopping",
            action: "click",
            cartItems: cart.lines.edges.map(item => ({
                productName: item.node.title,
                productQuantity: item.node.quantity,
                productPrice: item.node.price.amount
            }))
        });

    };


    const handleOpenCheckout = () => {
        // Check if the cart exists and has items
        if (!cart || !cart.lines || cart.lines.edges.length === 0) {
            // No items in cart
            dispatch(
                showMessageModal({
                    message: "No products selected. Please select some products and try to checkout again.",
                    link: null, // Optional: add a link to the shopping page
                })
            );
            return;
        }


        // Check if the checkout URL is valid
        if (cart && cart.checkoutUrl) {
            window.location.href = cart.checkoutUrl;
        } else {
            // Checkout URL is missing or invalid
            dispatch(
                showMessageModal({
                    message: "There was an issue opening the final checkout page. Please try again later.",
                    link: null, // Optional: add a support link
                })
            );
        }
        // Track the checkout initiation
        window.dataLayer.push({
            event: "checkout_initiation",
            action: "click",
            cartTotal: cart.totalPrice.amount,
            cartCurrency: cart.totalPrice.currencyCode,
            cartItems: cart.lines.edges.map(item => ({
                productName: item.node.title,
                productQuantity: item.node.quantity,
                productPrice: item.node.price.amount
            }))
        });
    };

    return (
        <div className="shopify-cart-footer">
            <div className="shopify-cart-footer-buttons">
                <SecondaryButton
                    onClick={toggleModal}
                    className="continue-shopping-button">
                    Continue shopping
                </SecondaryButton>
                <PrimaryButton
                    onClick={handleOpenCheckout}
                    className="checkout-button"
                >
                    Checkout
                </PrimaryButton>
            </div>
        </div>
    );
};

export default ShopifySpeedCartActions;
