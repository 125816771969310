import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    questAccountData: [],
    isModalVisible: false,
};

/**
 * Slice for managing quest accounts data and modal visibility.
 *
 * @typedef {Object} questAccountsSlice
 * @property {string} name - The name of the slice.
 * @property {Object} initialState - The initial state of the slice.
 * @property {Object} reducers - Reducer functions to update state based on actions.
 * @property {Function} setQuestAccounts - Updates quest account data in the state.
 * @property {Function} appendQuestAccounts - Appends new quest accounts to existing data in the state.
 * @property {Function} showQuestAccountsModal - Sets the modal visibility to true.
 * @property {Function} hideQuestAccountsModal - Clears quest account data and sets modal visibility to false.
 * @property {Function} approveQuestAccount - Approves a quest account by updating its draft status.
 * @property {Function} approveQuestSubAccount - Approves a sub-account within a quest account.
 */
const questAccountsSlice = createSlice({
    name: 'questAccounts/questAccountData',
    initialState,
    reducers: {
        setQuestAccounts: (state, action) => {
            state.questAccountData = action.payload;
        },
        appendQuestAccounts: (state, action) => {
            state.questAccountData = [...state.questAccountData, ...action.payload];
        },
        showQuestAccountsModal: (state) => {
            state.isModalVisible = true;
        },
        hideQuestAccountsModal: (state) => {
            state.questAccountData = []
            state.isModalVisible = false;
        },
        approveQuestAccount: (state, action) => {
            const { client_id } = action.payload;
            const index = state.questAccountData.findIndex(questAccount => questAccount.labb_client_id === client_id);
            if (index !== -1) {
                state.questAccountData[index].is_draft = false;
            }
        },
        approveQuestSubAccount: (state, action) => {
            const { organization_id } = action.payload;
            const index = state.questAccountData.findIndex(questAccount => questAccount.ss_organization_id === organization_id);
            if (index !== -1) {
                state.questAccountData[index].is_draft = false;
            }
        },
    },
});

export const {
    approveQuestAccount,
    approveQuestSubAccount,
    appendQuestAccounts,
    setQuestAccounts,
    showQuestAccountsModal,
    hideQuestAccountsModal
} = questAccountsSlice.actions;

export default questAccountsSlice.reducer;
