import React, {useCallback, useEffect, useRef, useState} from "react";
import './PasteUpload.css';
import {GenericIcons as GenericIcon} from "../../../../assets/icons";

function PasteUpload({ showModal, onClose, onEnter }) {
    const modalRef = useRef();

    const [hoverCloseIcon, setHoverCloseIcon] = useState(false);

    const onMouseOver = () => {
        setHoverCloseIcon(true);
    }

    const onMouseOut = () => {
        setHoverCloseIcon(false);
    }


    const handlePasteEvent = useCallback((event) => {
        if (!showModal) return; // Only handle paste events if the modal is shown

        const clipboardData = event.clipboardData || window.clipboardData;
        if (!clipboardData) {
            console.error('Clipboard data is not available');
            return;
        }

        const text = clipboardData.getData('Text');
        if (!text) {
            console.warn('Clipboard is empty');
            return;
        }

        // Check if the text is a URL and handle it
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        if (urlRegex.test(text)) {
            console.log("URL from clipboard:", text);

            onClose(); // Optionally close the modal after handling the URL
        } else {
            console.warn("Clipboard does not contain a valid URL.");
            onEnter(); // Trigger the Enter action to open the upload selector
            onClose(); // close the modal after handling the URL
        }
    }, [showModal, onClose, onEnter]);

    useEffect(() => {
        document.addEventListener('paste', handlePasteEvent);

        // Check if modalRef.current is defined before calling focus on it
        if (modalRef.current) {
            modalRef.current.focus();
        }

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('paste', handlePasteEvent);
        };
    }, [handlePasteEvent]);

    const handleModalKeyPress = useCallback((event) => {
        if (event.key === 'Enter') {
            onClose(); // Close the modal
            onEnter(); // Trigger the Enter action to open the upload selector
        }
    }, [onClose, onEnter]);

    if (!showModal) return null;

    return (
        <div className="paste-upload-modal"
             tabIndex={0}
             ref={modalRef}
             onKeyDown={handleModalKeyPress}>
            <div className="modal-header">
                <img src={hoverCloseIcon ? GenericIcon.ExitIcon : GenericIcon.CloseIcon}
                     alt="Close"
                     className="close-icon"
                     onClick={onClose}
                     onMouseOver={onMouseOver}
                     onMouseOut={onMouseOut} />
            </div>
            <p className="paste-upload-text">Clipboard Auto-Capture Not Supported</p>
            <p className="paste-upload-instruction">Please paste using Ctrl+V (Cmd+V for Mac) or press Enter to select a file from your computer.</p>
        </div>
    );
}

export default PasteUpload;
