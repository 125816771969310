import {jwtDecode} from "jwt-decode";

export const getUserRolesFromToken = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        // Assuming the user roles are stored in a 'roles' field in the token payload
        return decodedToken.roles || [];
    } catch (error) {
        console.error('Error decoding token:', error);
        return [];
    }
}
