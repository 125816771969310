// MessagesModal.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './MessagesModal.css';
import { IconButton } from "../../buttons/icon_button/IconButton";
import { GenericIcons as GenericIcon } from "../../../assets/icons";
import { hideMessageModal } from "../../../store/modals/modalsSlice";
import CountdownTimer from "../../countdown_timer/CountdownTimer";

const MessagesModal = () => {
    const dispatch = useDispatch();
    const isVisible = useSelector(state => state.modals.isModalOpen);
    const message = useSelector(state => state.modals.message);
    const link = useSelector(state => state.modals.link);

    useEffect(() => {
        if (isVisible) {
            const timer = setTimeout(() => dispatch(hideMessageModal()), 20000);
            return () => clearTimeout(timer);
        }
    }, [isVisible, dispatch]);

    if (!isVisible) return null;

    const handleClear = () => {
        dispatch(hideMessageModal())
    };

    return (
        <div className="messages-modal">
            <div className="messages-modal-header">
                <IconButton
                    className="dropdown-clear-button"
                    onClick={handleClear}
                    defaultIcon={GenericIcon.CloseIcon}
                    hoverIcon={GenericIcon.ExitIcon}
                />
            </div>
            <div className="messages-modal-content">
                <p>{message}</p>
                {link && <a href={link} download>Download</a>}
            </div>
            <CountdownTimer duration={5} onComplete={handleClear} />
        </div>
    );
};

export default MessagesModal;
