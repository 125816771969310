import React, { useState, useEffect } from "react";
import "./SpeedSwabsCookieConsentBanner.css";
import { createCart } from "../../../store/shopify/cart/shopifySpeedCartSlice";
import { PrimaryButton } from "../../buttons/primary_button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { acceptCookieConsent } from "../../../store/shopify/cart/shopifySpeedCartSlice";
import SpeedSwabsArrowsImage from "../../../assets/icons/SpeedSwabsArrows.svg";

const CookieConsentBannerModal = () => {
    const dispatch = useDispatch();
    const cookieConsentAccepted = useSelector((state) => state.shopifyCart.cookieConsentAccepted);

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Check if the cookie consent is already present
        const consentCookie = getCookie("speedSwabsCookieConsent");
        if (consentCookie && JSON.parse(consentCookie).accepted) {
            dispatch(acceptCookieConsent());
            setIsVisible(false);
        } else if (!cookieConsentAccepted) {
            setIsVisible(true);
        }
    }, [cookieConsentAccepted, dispatch]);

    const handleAccept = () => {
        setIsVisible(false);
        dispatch(acceptCookieConsent());
        setCookie("speedSwabsCookieConsent", JSON.stringify({ accepted: true }), 365);
        createOrRetrieveCart();
    };

    const createOrRetrieveCart = () => {
        const existingCartId = getCookie("speedSwabsCartId");
        if (!existingCartId) {
            dispatch(createCart()).then((response) => {
                if (response && response.payload && response.payload.id) {
                    setCookie("speedSwabsCartId", response.payload.id, 365);
                    setCookie("speedSwabsCookieConsent", JSON.stringify({ accepted: true, cartId: response.payload.id }), 365);
                }
            });
        } else {
            setCookie("speedSwabsCookieConsent", JSON.stringify({ accepted: true, cartId: existingCartId }), 365);
        }
    };

    const setCookie = (name, value, days) => {
        let expires = "";
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    };

    const getCookie = (name) => {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    };

    if (!isVisible) return null;

    return (
        <div className="cookie-consent-banner">
            <p className="cookie-consent-text">
                We use Cookies to offer a better user experience and personalize content. By continuing to browse the SpeedSwabs site you agree to allow the SpeedSwabs site to store cookies.
                Click <strong>OK</strong> to acknowledge cookie usage.
            </p>
            <div className="cookie-consent-button-container">
                <PrimaryButton className="cookie-consent-button" onClick={handleAccept}>
                    OK
                </PrimaryButton>
            </div>

            {/*<img src={SpeedSwabsArrowsImage} alt="Arrow" className="cookie-consent-arrow" /> /!* Added the arrow image *!/*/}
        </div>
    );
};

export default CookieConsentBannerModal;
