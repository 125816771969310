import "./ActionTray.css";
import { ActionTrayIcons } from "../../../assets/icons";
import React, { useEffect, useRef, useState } from 'react';
import { IconButton } from "../../buttons/icon_button/IconButton";

function ActionTray({ iconsData, userRoles, rowData }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const trayRef = useRef(null);

    const handleMouseEnter = () => {
        setIsExpanded(true);
    };

    const handleMouseLeave = () => {
        setIsExpanded(false);
    };

    const handleClickOutside = event => {
        if (trayRef.current && !trayRef.current.contains(event.target)) {
            setIsExpanded(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div
            className="action-tray-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={trayRef}
        >
            <IconButton defaultIcon={ActionTrayIcons.ActionTrayIcon} />

            {isExpanded && (
                <div className="action-icons">
                    {iconsData.map((iconData, index) => {
                        if (!iconData.hide && userRoles.includes(iconData.permission)) {
                            return (
                                <div className="icon-wrapper" key={index}>
                                    <div
                                        onClick={(event) => {
                                            event.preventDefault();
                                            iconData.action(rowData);
                                        }}
                                    >
                                        <IconButton defaultIcon={iconData.icon} hoverIcon={iconData.icon} alt={iconData.alt} />
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            )}
        </div>
    );
}

export default ActionTray;
