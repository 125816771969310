// src/store/invoices/omegaInvoiceSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const mroExpressInvoiceSlice = createSlice({
    name: 'mroExpressInvoices',
    initialState: {
        invoices: [],
    },
    reducers: {
        setMroExpressInvoices: (state, action) => {
            state.invoices = action.payload;
        },
        clearMroExpressInvoices: (state) => {
            state.invoices = [];
        },
    },
});

export const { setMroExpressInvoices, clearMroExpressInvoices } = mroExpressInvoiceSlice.actions;

export default mroExpressInvoiceSlice.reducer;