// mroReportsSlice.js
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchMroReportData} from "../../services/api/v1/mroReportsService";

export const getMroReportPdfRecords = createAsyncThunk(
    'reports/getMroReportPdfRecords',
    async (params, { rejectWithValue }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            return await fetchMroReportData(params, accessToken);
        } catch (err) {
            return rejectWithValue(err.response);
        }
    }
);

const initialState = {
    reportPdfUrl: '',
    status: 'idle',
    error: null,
};

const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        // Reducer to clear report data
        clearReportData: state => {
            state.reportPdfUrl = '';
            state.status = 'idle';
            state.error = null;
        },
    },
    extraReducers: {
        [getMroReportPdfRecords.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.reports = action;
        },
        [getMroReportPdfRecords.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action;
        },
        [getMroReportPdfRecords.pending]: state => {
            state.status = 'loading';
        },
    },
});

export const { clearReportData } = reportsSlice.actions;
export default reportsSlice.reducer;
