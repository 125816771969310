import React, { useState } from 'react';
import Select from 'react-select';
import './FastSelect.css';

const FastSelect = ({ options, selectedClients, setSelectedClients }) => {
    const [pasteInput, setPasteInput] = useState('');

    const handlePaste = (e) => {
        const pasteData = e.target.value;
        const clientLabels = pasteData.split(',').map(item => item.trim().replace(/"/g, ''));
        const matchedClients = options.filter(option => clientLabels.includes(option.label));
        setSelectedClients(matchedClients);
    };

    return (
        <div className="client-fast-paste-select">
            <textarea
                placeholder='Paste client names here, separated by commas and enclosed in quotes, e.g., "Client1", "Client2"'
                value={pasteInput}
                onChange={(e) => {
                    setPasteInput(e.target.value);
                    handlePaste(e);
                }}
                className="paste-input"
            />
            <Select
                isMulti
                options={options}
                value={selectedClients}
                onChange={setSelectedClients}
                placeholder="Select clients..."
                className="clients-select"
                closeMenuOnSelect={false}
            />
        </div>
    );
};

export default FastSelect;
