import "./FusionFooter.css";
import React from "react";

const FusionFooter = () => {
    return (
        <footer className="fusion-footer">
            <p>© 2024 Lab Fusion | All rights reserved.</p>
        </footer>
    );
};

export default FusionFooter;
