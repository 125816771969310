// src/routes/ShopifyProtectedRoute.js

import React, { useEffect, useState } from 'react';  // Import React and its hooks (useEffect, useState)
import { useDispatch } from 'react-redux';  // Import useDispatch hook from react-redux to dispatch Redux actions
import { getShopifyOAuthUrl } from '../components/user/sign_in/SignIn';  // Import function to initiate OAuth flow
import LoadingSpinningCup from '../pages/loading/Loading';  // Import loading spinner component for while the app is authenticating
import { useLocation } from 'react-router-dom';  // Import useLocation to get the current URL and query parameters
import { exchangeCodeForTokens } from '../utils/authUtils';  // Import utility function to exchange authorization code for tokens
import { getShopifyAuth } from '../store/external/shopifyAuthSlice';  // Import Redux action to get Shopify user authentication

// Main ShopifyProtectedRoute component definition
const ShopifyProtectedRoute = ({ children }) => {
    // State to handle loading indicator while authentication is in progress
    const [isLoading, setIsLoading] = useState(true);

    // State to store the Shopify Grant Token from session storage
    const [shopifyGrantToken, setShopifyGrantToken] = useState(
        sessionStorage.getItem('shopifyGrantToken')
    );

    // useLocation hook to get current URL and query parameters
    const location = useLocation();

    // Get the hostname from the window object (current URL)
    const hostname = window.location.hostname;

    // useDispatch hook to dispatch Redux actions
    const dispatch = useDispatch();

    // useEffect hook runs once on component mount, authenticating the user
    useEffect(() => {
        // Function to handle user authentication
        const authenticateUser = async () => {
            // Get the URL search parameters (query string) and extract the authorization code
            const params = new URLSearchParams(location.search);
            const authorizationCode = params.get('code');  // Extract the 'code' parameter

            if (shopifyGrantToken) {
                // User is already authenticated with a grant token
                dispatch(getShopifyAuth());  // Dispatch Redux action to get Shopify authentication
                setIsLoading(false);  // Set loading state to false
            } else if (authorizationCode) {
                // User has an authorization code, exchange it for tokens
                try {
                    const tokens = await exchangeCodeForTokens(authorizationCode);  // Exchange code for tokens
                    // Store tokens in session storage
                    sessionStorage.setItem('shopifyGrantToken', tokens.access_token);
                    sessionStorage.setItem('shopifyIdToken', tokens.id_token);
                    setShopifyGrantToken(tokens.access_token);  // Update local state with the access token

                    dispatch(getShopifyAuth());  // Dispatch Redux action to get Shopify authentication

                    // Clean up URL by removing the query parameters
                    window.history.replaceState({}, document.title, location.pathname);
                    setIsLoading(false);  // Set loading state to false
                } catch (error) {
                    console.error('Failed to exchange code for tokens:', error);  // Log error
                    setIsLoading(false);  // Set loading state to false
                }
            } else {
                // No grant token or authorization code, initiate OAuth flow
                try {
                    const oauthUrl = await getShopifyOAuthUrl(hostname);  // Get OAuth URL
                    window.location.href = oauthUrl;  // Redirect the user to Shopify's OAuth page
                } catch (error) {
                    console.error('Failed to initiate OAuth flow:', error);  // Log error
                    setIsLoading(false);  // Set loading state to false
                }
            }
        };

        // Call the authentication function
        authenticateUser();
    }, [dispatch, hostname, location.pathname, location.search, shopifyGrantToken]);

    // If the authentication is still in progress, show a loading spinner
    if (isLoading) {
        return <LoadingSpinningCup />;
    }

    // If no grant token is available (authentication failed), show an error message
    if (!shopifyGrantToken) {
        return <div>Error: Unable to authenticate. Please try again.</div>;
    }

    // If the user is authenticated, render the protected component (child component passed in as a prop)
    return children;
};

export default ShopifyProtectedRoute;  // Export ShopifyProtectedRoute component for use in other parts of the app
