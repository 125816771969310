import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    fetchOrderDetails,
    fetchOrders,
    fetchOrdersPickList,
    makeOrderInvoice
} from "../../services/api/v1/ordersService";
import {fetchShipmentRates, fetchShippingLabel} from "../../services/api/v1/shippingService";
import {fetchClientOrderDetails} from "../../services/api/v1/clientOrderDetailsService";

export const getOrders = createAsyncThunk(
    'orders/getOrders',
    async (thunkAPI) => {
        const accessToken = sessionStorage.getItem("accessToken");
        return await fetchOrders(accessToken);
    }
);

export const getOrderDetails = createAsyncThunk(
    'orders/getOrderDetails',
    async (orderId, thunkAPI) => {
        const accessToken = sessionStorage.getItem("accessToken");
        return await fetchOrderDetails(accessToken, orderId);
    }
)

export const getClientOrderDetails = createAsyncThunk(
    'orders/getClientOrderDetails',
    async (clientShopifyId, thunkAPI) => {
        return await fetchClientOrderDetails(clientShopifyId);
    }
)

export const createShipmentLabel = createAsyncThunk(
    'orders/createShipmentLabels',
    async (shipmentDetails, thunkAPI) => {
        const accessToken = sessionStorage.getItem("accessToken");
        return await fetchShippingLabel(accessToken, shipmentDetails);

    }
)

export const getShipmentRates = createAsyncThunk(
    'orders/fetchShipmentRates',
    async (upsAccessToken, selectedShipAddress, productOrderId, boxesDetailConfiguration, thunkAPI) => {
        const accessToken = sessionStorage.getItem("accessToken");
        return await fetchShipmentRates(accessToken, upsAccessToken, selectedShipAddress, productOrderId, boxesDetailConfiguration);
    }
)

export const getOrdersPickList = createAsyncThunk(
    'orders/fetchOrdersPickList',
    async (thunkAPI) => {
        const accessToken = sessionStorage.getItem("accessToken");
        return await fetchOrdersPickList(accessToken);
    }
)

export const createOrderInvoice = createAsyncThunk(
    'orders/createOrderInvoice',
    async ({ zohoAccessToken, invoiceDetails }, thunkAPI) => {
        const accessToken = sessionStorage.getItem("accessToken");
        return await makeOrderInvoice(accessToken, { zohoAccessToken, orderDetails: invoiceDetails });
    }
)

const ordersSlice = createSlice({
    name: 'orders',
    initialState: {
        orders: [],
        customerOrderDetails: null,
        isOrderDetailVisible: false,
        selectedOrder: null,
        selectedClientOrderDetails: null,
        shipmentOrderRates: null,
        selectedShipmentLabels: [],
        totalShippingCost: 0,
        pickList: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        clearOrdersData: (state) => {
            state.orders = [];
            state.isOrderDetailVisible = false;
            state.selectedOrder = null;
            state.selectedClientOrderDetails = null;
            state.selectedShipmentLabels = [];
            state.shipmentOrderRates = null;
            state.totalShippingCost = 0;
            state.status = 'idle';
            state.error = null;
        },
        toggleOrderDetailModal: state => {
            state.isOrderDetailVisible = !state.isOrderDetailVisible;
            state.orders = [];
            state.selectedOrder = null;
            state.selectedClientOrderDetails = null;
            state.selectedShipmentLabels = [];
            state.shipmentOrderRates = null;
            state.totalShippingCost = 0;
            state.status = 'idle';
            state.error = null;
        },
        showOrderModal: (state) => {
            state.isOrderDetailVisible = true;
        },
        hideOrderModal: (state) => {
            state.isOrderDetailVisible = false;
        },
        setTotalShippingCost: (state, action) => { // Add this reducer
            state.totalShippingCost = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOrders.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getOrders.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.orders = action.payload || [];
            })
            .addCase(getOrders.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getClientOrderDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getClientOrderDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.customerOrderDetails = action.payload || [];
            })
            .addCase(getClientOrderDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getOrdersPickList.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getOrdersPickList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.pickList = action.payload || [];
            })
            .addCase(getOrdersPickList.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getOrderDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getOrderDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedClientOrderDetails = action.payload || [];
                state.selectedOrder = action.meta.arg;
            })
            .addCase(getOrderDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getShipmentRates.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getShipmentRates.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.shipmentOrderRates = action.payload || [];
                state.selectedOrder = action.meta.arg;
            })
            .addCase(getShipmentRates.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(createShipmentLabel.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createShipmentLabel.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedShipmentLabels.push(action.payload);
            })
            .addCase(createShipmentLabel.rejected, (state, action) => {
                state.status = 'failed';
                console.log(action.error)
                state.error = action.error.message;
            })
            .addCase(createOrderInvoice.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createOrderInvoice.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = 'Invoice created successfully';
            })
            .addCase(createOrderInvoice.rejected, (state, action) => {
                state.status = 'failed';
                console.log(action.error)
                state.error = action.error.message;
            });
    },
});

export const { clearOrdersData, toggleOrderDetailModal, showOrderModal, hideOrderModal, setTotalShippingCost} = ordersSlice.actions;

export default ordersSlice.reducer;
