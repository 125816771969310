// freddy-react/src/pages/automation/AutomationPage.js

import React from "react";
import "./AutomationPage.css";
import AdminHeader from "../../components/headers/./admin_header/AdminHeader";
import Review from "../../components/autobot/review/Review";
import LabbLogin from "../../components/autobot/labb_login/LabbLogin";
import ClientSelector from "../../components/autobot/client_selector/ClientSelector";
import {useSelector} from 'react-redux';
import {PrimaryButton} from "../../components/buttons/primary_button/PrimaryButton";
import {
    startClientEmployeeCreation,
    startClientOrganizationCreation,
    startClientScreenerCreation
} from "../../services/api/v1/automationService";


const AutomationPage = () => {
    const accessToken = sessionStorage.getItem('accessToken');
    const screeners = useSelector(state => state.autobot.screeners?.screenerData);
    const employees = useSelector(state => state.autobot.employees.employeeData);
    const organizations = useSelector(state => state.autobot.organizations.organizationData);
    const labb_username = useSelector(state => state.autobot.login.username);
    const labb_password = useSelector(state => state.autobot.login.password);
    const client_id = useSelector(state => state.autobot.clients.selectedClient);


    const initiateAutomation = async (e) => {
        e.preventDefault();

        const labb_login = {
            username: labb_username,
            password: labb_password
        };

        const formData = new FormData();


        // Add organization data if available and not empty
        if (organizations && organizations.length > 0) {
            formData.append('organizations', JSON.stringify(organizations));
        }

        // Add screeners data if available and not empty
        if (screeners && screeners.length > 0) {
            console.log("screeners", screeners);
            formData.append('screeners', JSON.stringify(screeners));
        }

        // Add employees data if available and not empty
        if (employees && employees.length > 0) {
            console.log("employees", employees);
            formData.append('employees', JSON.stringify(employees));
        }

        formData.append('labb_login', JSON.stringify(labb_login));
        formData.append('client_id', client_id);

        try {
            let response;

            // Check organizations first
            if (organizations && organizations.length > 0) {
                response = await startClientOrganizationCreation(accessToken, formData);
            }
            // If no organizations, check screeners
            else if (screeners && screeners.length > 0) {
                console.log("formData", formData);
                response = await startClientScreenerCreation(accessToken, formData);
            }
            // If no screeners, check employees
            else if (employees && employees.length > 0) {
                console.log("formData", formData);
                response = await startClientEmployeeCreation(accessToken, formData);
            }
            console.log(response);
        } catch (error) {
            console.error('Error initiating automation:', error.message);
        }
    }



    return (
        <div className="automation-page-container">
            <AdminHeader/>
            <div className="content-container">
                <form onSubmit={initiateAutomation}>
                    <div>
                        <LabbLogin/>
                    </div>
                    <div>
                        <div className="labb-clients-search-header">
                            <ClientSelector/>
                        </div>
                    </div>
                    <div>
                        <Review screeners={screeners} organizations={organizations} employees={employees}/>
                    </div>
                    <div className="submit-initiate-automation-button">
                        <PrimaryButton type="submit" onClick={initiateAutomation} children="INITIATE AUTOMATION"/>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AutomationPage;