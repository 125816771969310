// src/store/external/emailAuthSlice.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchEmailAuthToken } from '../../services/api/v1/emailAuthService';

const initialState = {
    emailAuth: null,
    isLoading: false,
    error: null
};

export const getEmailAuth = createAsyncThunk(
    'emailAuth/fetchEmailAuthToken',
    async ({ instance, emailTokenRequest }, thunkAPI) => {
        try {
            const accessToken = await fetchEmailAuthToken(instance, emailTokenRequest);
            sessionStorage.setItem("emailAccessToken", accessToken);  // Store the token in sessionStorage
            return accessToken;
        } catch (error) {
            return thunkAPI.rejectWithValue('Failed to fetch email access token');
        }
    }
);

const emailAuthSlice = createSlice({
    name: 'emailAuth',
    initialState,
    reducers: {
        clearEmailAuth: (state) => {
            state.emailAuth = null;
        }
    },
    extraReducers: {
        [getEmailAuth.pending]: (state) => {
            state.isLoading = true;
        },
        [getEmailAuth.fulfilled]: (state, action) => {
            state.emailAuth = action.payload;
            state.isLoading = false;
        },
        [getEmailAuth.rejected]: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        }
    }
});

export const { clearEmailAuth } = emailAuthSlice.actions;
export default emailAuthSlice.reducer;
