// SpeedSwabsFeaturesUltimate.js - Updated with Copy Improvements, Responsive Enhancements, and Style Fixes
import React, { useEffect, useState } from "react";
import "./SpeedSwabsFeaturesUltimate.css";
import swabImage from "../../../../assets/graphics/SpeedSwabRapidOralFluidTestSingleSwab.png";
import FastIcon from "../../../../assets/icons/SpeedSwabsFastRabbit.svg";
import OralFluidIcon from "../../../../assets/icons/SpeedSwabsOralFluidMouth.svg";
import ThumbsUpIcon from "../../../../assets/icons/SpeedSwabsThumbsUp.svg";
import { PrimaryButton } from "../../../buttons/primary_button/PrimaryButton";
import { useNavigate } from "react-router-dom";

const featureData = [
    {
        icon: ThumbsUpIcon,
        header: "100% Reliable",
        subheader: "Clear Results, Every Time",
        description:
            "Say goodbye to failed drug tests due to 'no-flow'. SpeedSwabs deliver reliable, clear results with every use, giving you confidence and accuracy.",
    },
    {
        icon: FastIcon,
        header: "Incredibly Fast",
        subheader: "Results in Just 1-2 Minutes",
        description: "No more waiting around. SpeedSwabs provide negative results in as little as 1 to 2 minutes, helping you save time and get fast, actionable results.",
    },
    {
        icon: OralFluidIcon,
        header: "Effortlessly Easy",
        subheader: "Anyone Can Use It",
        description:
            "SpeedSwabs are designed for simplicity. With straightforward instructions, they are virtually impossible to get wrong—anyone can use them with ease.",
    },
    {
        icon: FastIcon,
        header: "Cost-Effective",
        subheader: "Affordable Quality",
        description:
            "Enjoy premium reliability and speed at an affordable cost. SpeedSwabs offer incredible value without breaking the bank.",
    },
];

const SpeedSwabsFeaturesUltimate = () => {
    const [activeFeatureIndex, setActiveFeatureIndex] = useState(0);
    const navigate = useNavigate();

    const handleBuyNow = async () => {
        // Send event data to Google Tag Manager
        if (window.dataLayer) {
            window.dataLayer.push({
                event: "buy_now_click",
                buttonLocation: "SpeedSwabsUltimate",
                productName: "SpeedSwabs",
                productCategory: "Oral Fluid Drug Test",
                action: "click"
            });
        }

        // Navigate to the product page
        navigate("/product");
    };

    return (
        <div className="ultimate-features-container">
            <div className="ultimate-features-content-wrapper">
                <div className="ultimate-swab-image-container">
                    <img
                        src={swabImage}
                        alt="SpeedSwab rapid oral fluid test"
                        className="ultimate-swab-image"
                        style={{transform: `rotate(${activeFeatureIndex * 10}deg)`}}
                    />
                </div>
                <div className="ultimate-features-content">
                    <h1 className="ultimate-features-title">SpeedSwabs: Rapid Oral Fluid Drug Test</h1>
                    <div className="ultimate-features-icons">
                        {featureData.map((feature, index) => {
                            const isActive = index === activeFeatureIndex;
                            const isNext = index === (activeFeatureIndex + 1) % featureData.length;

                            return (
                                <div
                                    key={index}
                                    className={`ultimate-feature-icon-container ${isActive ? 'active' : isNext ? 'next' : 'hidden'}`}
                                    onClick={() => setActiveFeatureIndex(index)}
                                >
                                    <img src={feature.icon} alt={feature.header} className="ultimate-feature-icon"/>
                                    <p className="ultimate-icon-header">{feature.header}</p>
                                </div>
                            );
                        })}
                    </div>
                    <p className="ultimate-feature-subheading">
                        {featureData[activeFeatureIndex].subheader}
                    </p>
                    <p className="ultimate-features-description">
                        {featureData[activeFeatureIndex].description}
                    </p>
                    {/* Buy Now Button */}
                    <div className="ultimate-buy-now-container">
                        <PrimaryButton onClick={handleBuyNow}>Buy now</PrimaryButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpeedSwabsFeaturesUltimate;