import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLssProducts } from "../../../../store/products/lssProductsSlice";
import "./ShopifyManageProducts.css";
import LoadingSpinningCup from "../../../../pages/loading/Loading";
import { getShopifyProducts } from "../../../../store/products/shopifyProductsSlice";
import {IconButton} from "../../../buttons/icon_button/IconButton";
import {GenericIcons} from "../../../../assets/icons";
import {linkProducts} from "../../../../store/products/productsSlice";


const ShopifyManageProducts = () => {
    const dispatch = useDispatch();
    const accessToken = sessionStorage.getItem("accessToken");
    const shopifyAuth = useSelector((state) => state.shopifyAuth.shopifyAuth);
    let shopifyAdminAccessToken = shopifyAuth?.access_token;
    const allLssProducts = useSelector((state) => state.lssProducts?.allLssProducts);
    const allShopifyProducts = useSelector((state) => state.shopifyProducts?.allShopifyProducts);

    const [selectedShopifyProduct, setSelectedShopifyProduct] = useState(null);
    const [selectedLssProduct, setSelectedLssProduct] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    if (shopifyAdminAccessToken === null) {
        shopifyAdminAccessToken = sessionStorage.getItem("shopifyAdminAccessToken");
    }

    useEffect(() => {
        dispatch(getLssProducts({ accessToken }));
    }, [dispatch, accessToken]);

    useEffect(() => {
        dispatch(getShopifyProducts({ accessToken, shopifyAdminAccessToken }));
    }, [dispatch, accessToken, shopifyAdminAccessToken]);

    // Handle product selection and deselection
    const handleShopifyProductSelect = (product) => {
        setSelectedShopifyProduct((prevProduct) =>
            prevProduct?.node?.id === product.node.id ? null : product
        );
        checkModalVisibility(product, selectedLssProduct);
    };

    const handleLssProductSelect = (product) => {
        setSelectedLssProduct((prevProduct) =>
            prevProduct?.product_id === product.product_id ? null : product
        );
        checkModalVisibility(selectedShopifyProduct, product);
    };

    // Check if both a Shopify and LSS product have been selected
    const checkModalVisibility = (shopifyProduct, lssProduct) => {
        if (shopifyProduct && lssProduct) {
            setIsModalVisible(true);
        } else {
            setIsModalVisible(false);
        }
    };

    const handleLinkProducts = () => {
        const linkedProductsData = {
            shopifyProduct: selectedShopifyProduct,
            lssProduct: selectedLssProduct,
        };
        dispatch(
            linkProducts(
                {
                    accessToken,
                    linkedProductsData,
                }
            )
        )
    };

    return (
        <div className="products-container">
            <div className="shopify-column">
                {allShopifyProducts && allShopifyProducts.length > 0 ? (
                    allShopifyProducts.map((shopifyProduct) => (
                        <div
                            key={shopifyProduct.node.id}
                            className={`product-item ${selectedShopifyProduct?.node?.id === shopifyProduct.node.id ? "selected" : ""}`}
                            onClick={() => handleShopifyProductSelect(shopifyProduct)}
                        >
                            <div className="product-details">
                                <p>{shopifyProduct.node.title}</p>
                                <p>{shopifyProduct.node.productType}</p>
                                {shopifyProduct.node.featuredImage && (
                                    <img
                                        src={shopifyProduct.node.featuredImage.url}
                                        alt={shopifyProduct.node.title}
                                        className="product-image"
                                    />
                                )}
                            </div>
                            {selectedShopifyProduct?.node?.id === shopifyProduct.node.id && (
                                <IconButton
                                    defaultIcon={GenericIcons.CloseIcon}
                                    hoverIcon={GenericIcons.ExitIcon}
                                    onClick={() => handleShopifyProductSelect(shopifyProduct)}
                                />
                            )}
                        </div>
                    ))
                ) : (
                    <LoadingSpinningCup />
                )}
            </div>

            {/* LSS Products Column */}
            <div className="lss-multi-column">
                {allLssProducts && allLssProducts.length > 0 ? (
                    allLssProducts.map((product) => (
                        <div
                            key={product.product_id}
                            className={`product-item ${selectedLssProduct?.product_id === product.product_id ? "selected" : ""}`}
                            onClick={() => handleLssProductSelect(product)}
                        >
                            <div className="product-details">
                                <p>{product.product_name}</p>
                                <p>{product.product_type_name}</p>
                                {product.product_tox && <p>Tox</p>}
                                {product.product_is_dot && <p>DOT</p>}

                                {/* Check if product_labb_image_url exists and render it */}
                                {product.product_labb_image_url && (
                                    <img
                                        src={product.product_labb_image_url}
                                        alt={product.product_name}
                                        className="product-image"
                                    />
                                )}
                            </div>
                            {selectedLssProduct?.product_id === product.product_id && (
                                <IconButton
                                    defaultIcon={GenericIcons.CloseIcon}
                                    hoverIcon={GenericIcons.ExitIcon}
                                    onClick={() => handleLssProductSelect(product)}
                                />
                            )}
                        </div>
                    ))
                ) : (
                    <LoadingSpinningCup />
                )}
            </div>

            {isModalVisible && (
                <div className="shopify-manage-products-modal">
                    <div className="shopify-manage-products-modal-content">
                        <h3>Link Products</h3>
                        <p>Shopify Product: {selectedShopifyProduct?.node.title}</p>
                        <p>LSS Product: {selectedLssProduct?.product_name}</p>
                        <button onClick={handleLinkProducts}>Link Products</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShopifyManageProducts;


