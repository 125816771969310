import React from "react";
import "./ClientNotificaiton.css";
const ClientNotificationContent = ({ notification }) => {
  return (
    <div className="client-notification">
      <div className="client-notification-content">
        <div className="client-notification-content-title">
          {notification.content}
        </div>
        <div className="client-notification-content-description">
          This is a client notification.
        </div>
      </div>
    </div>
  );
};

export default ClientNotificationContent;
