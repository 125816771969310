import "./../button_styles.css";
import "./SecondaryButton.css";

export function SecondaryButton({ onClick, children, isSelected }) {
    return (
        <button className={`secondary-button ${isSelected ? 'selected' : ''}`} onClick={onClick}>
            {children}
        </button>
    );
}
