import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import "./QuestAccounts.css";
import QuestAccountRow from "./quest_account_row/QuestAccountRow"
import {IconButton} from "../../../buttons/icon_button/IconButton";
import {GenericIcons, LaboratoryAccountsIcons} from "../../../../assets/icons";
import {hideQuestAccountsModal} from "../../../../store/external/questAccountsSlice";
import {
    clearLaboratoryAccountData,
    getLaboratoryAccountRecords
} from "../../../../store/laboratory/laboratoryAccountSlice";
import {showMessageModal} from "../../../../store/modals/modalsSlice";


const QuestAccountsModal = () => {
    const dispatch = useDispatch();
    const allQuestAccounts = useSelector((state) => state.questAccounts.questAccountData);
    const [filteredQuestAccounts, setFilteredQuestAccounts] = useState([]);

    const csvUrl = useSelector(state => state.laboratorySlice.csvUrl || "");


    useEffect(() => {
        if (csvUrl.length > 0) {
            console.log("csvRawData", csvUrl);
            downloadLaboraotryAccountCsv(csvUrl);
        }
    }, [csvUrl]);

    // Update this function to handle the URL link directly
    const downloadLaboraotryAccountCsv = (csvUrl) => {
        try {
            const link = document.createElement('a');
            link.href = csvUrl;
            link.download = "account-report.csv";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            dispatch(showMessageModal({message: "Download initiated successfully.", link: link}));
        } catch (error) {
            // Handle any potential errors during the process
            dispatch(showMessageModal({message: "Failed to initiate download.", link: null}));
        }
    };
    useEffect(() => {
        const accounts = allQuestAccounts.filter((questAccount) => questAccount["Default Charge To"] === "C");
        setFilteredQuestAccounts(accounts);
    }, [allQuestAccounts]); // Dependency on allQuestAccounts to avoid infinite loop

    const updateFilteredQuestAccount = (updatedAccount) => {
        // First, update the specific account's draft status
        const updatedAccounts = filteredQuestAccounts.map(account =>
            account.labb_client_id === updatedAccount.labb_client_id ? updatedAccount : account
        );

        // Then, reorder accounts so non-drafts (approved) accounts move to the bottom
        const draftAccounts = updatedAccounts.filter(account => account.is_draft);
        const nonDraftAccounts = updatedAccounts.filter(account => !account.is_draft);

        setFilteredQuestAccounts([...draftAccounts, ...nonDraftAccounts]);
    };

    const handleDownloadButtonClick = () => {
        dispatch(getLaboratoryAccountRecords());
        dispatch(showMessageModal({message: "Started Download", link: csvUrl}));
        dispatch(clearLaboratoryAccountData())
    };


    return (
        <>
            <div>
                <div>
                    <IconButton
                        defaultIcon={GenericIcons.CloseIcon}
                        hoverIcon={GenericIcons.CloseIcon}
                        onClick={() => {
                            dispatch(hideQuestAccountsModal())
                        }}
                    />
                </div>
                <div>
                    {filteredQuestAccounts.map((filteredQuestAccount, index) => (
                        <QuestAccountRow
                            key={`${filteredQuestAccount["Client Number"]} - ${index}`}
                            filteredQuestAccount={filteredQuestAccount}
                            updateFilteredQuestAccount={(
                                filteredQuestAccount
                            ) => updateFilteredQuestAccount(filteredQuestAccount)}
                        />
                    ))}
                </div>
                <div>
                    <IconButton
                        className="modal-close-button"
                        defaultIcon={LaboratoryAccountsIcons.DownloadIcon}
                        hoverIcon={LaboratoryAccountsIcons.DownloadIcon}
                        onClick={handleDownloadButtonClick}
                    />
                </div>
            </div>
        </>
    );
}

export default QuestAccountsModal;
