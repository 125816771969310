// freddy-react/src/components/user/registration/Registration.js

import React, { useEffect, useState } from "react";
import "./Registration.css";
import {registerUser} from "../../../services/api/v1/userService";
import Login from "../login/Login";
import {useDispatch, useSelector} from "react-redux";
import {selectIsRegistered} from "../../../store/user/userSlice";

function Registration({ setIsMicrosoftAccountLinked }) {
    const [username, setUsername] = useState("");

    const saveUsername = async (e) => {
        e.preventDefault();

        const accessToken = sessionStorage.getItem('accessToken');


        if (username) {
            const formData = new FormData();
            formData.append("user_name", username);
            try {
                const response = await registerUser(formData, accessToken);
                setIsMicrosoftAccountLinked(response.data);
            } catch (error) {
                console.error("There was an error!", error);
            }
        }
    };


    return (
        <div className="registration-wrapper">
            <div className="registration-card">
                <img
                    className="registration-logo"
                    src="https://static.wixstatic.com/media/40f727_b17ccfdda56f41e494a6586affdeae2e~mv2.png"
                    alt="Labb Logo"
                />
                <form className="registration-form" onSubmit={saveUsername}>
                    <input
                        type="text"
                        placeholder="Create your username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <button type="submit" className="registration-btn">
                        REGISTER
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Registration;
