import React from "react";
import "./ExpandCell.css";
import { IconButton } from "../../buttons/icon_button/IconButton";
import { GenericIcons as GenericIcon } from "../../../assets/icons";

const ExpandCell = ({ isExpanded, toggleExpand }) => (
  <div className="expand-cell-container" style={{ padding: 5 }}>
    <div>
      {isExpanded ? (
        <IconButton
          onClick={toggleExpand}
          defaultIcon={GenericIcon.ContractIcon}
          hoverIcon={GenericIcon.ContractIcon}
        />
      ) : (
        <IconButton
          onClick={toggleExpand}
          defaultIcon={GenericIcon.ExpandIcon}
          hoverIcon={GenericIcon.ExpandIcon}
        />
      )}
    </div>
  </div>
);

export default ExpandCell;
