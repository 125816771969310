import React, { useState, useEffect } from 'react';
import './Orders.css';
import ClientOrders from "../../components/orders/ClientOrders";
import ProductPickList from "../../components/orders/product_pick_list/ProductPickList";
import AdminHeader from "../../components/headers/admin_header/AdminHeader";

// Add additional imports for your specific selectors and actions

const OrdersPage = () => {
    const [selectedProducts, setSelectedProducts] = useState([]);

    const handleProductSelection = (productName) => {
        setSelectedProducts(prevProducts => {
            if (prevProducts.includes(productName)) {
                return prevProducts.filter(product => product !== productName);
            } else {
                return [...prevProducts, productName];
            }
        });
    };

    return (
        <div className="orders-page-container">
            <div className="orders-header-container">
                <AdminHeader />
            </div>
            <div className="orders-body-container">
                <div className="orders-content-container">
                    <div className="orders-table-container">
                        <ProductPickList onSelectProduct={handleProductSelection} selectedProducts={selectedProducts} />
                    </div>
                    <div className="orders-table-container">
                        <ClientOrders selectedProducts={selectedProducts} />
                    </div>
                </div>
            </div>
            {/*<Footer />*/}
        </div>
    );
};

export default OrdersPage;
