import React from 'react';
import { PrimaryButton } from '../../../buttons/primary_button/PrimaryButton';
import './CreateInvoice.css';

const CreateInvoice = ({ handleCreateInvoice }) => {
    return (
        <div className="create-invoice-container">
            <PrimaryButton onClick={handleCreateInvoice}>Create Invoice</PrimaryButton>
        </div>
    );
};

export default CreateInvoice;
