import React from "react";
import "./NotificationItem.css";
import { SecondaryButton } from "../../../../buttons/secondary_button/SecondaryButton";
import { PrimaryButton } from "../../../../buttons/primary_button/PrimaryButton";
import ClientNotificationContent from "./client_notification/ClientNotificaiton";
import SpecimenNotificationContent from "./specimen_notificaiotn/SpecimenNotification";

const NotificationItem = ({
  notification,
  onAcknowledgeNotification,
  onReviewNotification,
}) => {
  const renderNotificationContent = () => {
    switch (notification.type) {
      case "specimen":
        return <SpecimenNotificationContent notification={notification} />;
      case "client":
        return <ClientNotificationContent notification={notification} />;

      default:
        return <div>Unknown Notification Type</div>;
    }
  };

  return (
    <div className="notification-item">
      <div className="notification-content">{renderNotificationContent()}</div>
      <div className="notification-actions">
        <SecondaryButton
          onClick={() => onAcknowledgeNotification(notification.id)}
        >
          ACKNOWLEDGE
        </SecondaryButton>
        <PrimaryButton onClick={() => onReviewNotification(notification.id)}>
          REVIEW
        </PrimaryButton>
      </div>
    </div>
  );
};

export default NotificationItem;
