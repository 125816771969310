import React from 'react';
import './AdminFooter.css';
import ReadTiles from "./read_tiles/ReadTiles";
// import {ws} from "../../services";

export default function AdminFooter() {


    return (
        <footer>
            <div className="footer-main-body">
                <ReadTiles />
            </div>
        </footer>
    )
}
