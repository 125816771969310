// freddy-react/src/store/user/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        isRegistered: false,
        username: '',  // Updated from user_name
        userRoles: [],
        isLoggedIn: false,  // Updated from is_login
        token: null,
        userId: ''  // Added this field for user_id
    },
    reducers: {
        setUserId: (state, action) => {  // Added this reducer
            state.userId = action.payload;
        },
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        setUsername: (state, action) => {
            state.username = action.payload;
        },
        setIsRegistered: (state, action) => {
            state.isRegistered = action.payload;
        },
        setUserRoles: (state, action) => {
            state.userRoles = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        }
    },
});

export const { setUserId, setUsername, setIsRegistered, setUserRoles, setIsLoggedIn, setToken } = userSlice.actions;

export const selectUserId = (state) => state.user.userId;
export const selectIsRegistered = (state) => state.user.isRegistered;
export const selectUsername = (state) => state.user.username;
export const selectUserRoles = (state) => state.user.userRoles;
export const selectIsLoggedIn = (state) => state.user.isLoggedIn;
export const selectToken = (state) => state.user.token;
export const selectIsAuthenticated = (state) => !!state.user.token;


export default userSlice.reducer;

