// freddy-react/src/components/screens/header_row/HeaderRow.js

import React from 'react';
import './HeaderRow.css';

const HeaderRow = () => {
    return (
        <thead>
        <tr className="header-row">
            <th className="header-cell">Labb ID</th>
            <th className="header-cell">Client</th>
            <th className="header-cell">Date Collected</th>
            <th className="header-cell">Status</th>
            <th className="header-cell">Actions</th>
        </tr>
        </thead>
    );
};


export default HeaderRow;
