import React from 'react';
import { SpeedIcons } from '../../../../../assets/icons';
import './ProgressBar.css';

const ProgressBar = ({ step }) => {
    const steps = [
        { icon: SpeedIcons.PersonIcon, label: 'Personal Details' },
        { icon: SpeedIcons.CompanyIcon, label: 'Company Details' },
        { icon: SpeedIcons.EmailIcon, label: 'Contact Details' },
        { icon: SpeedIcons.CheckIcon, label: 'Preferences' }
    ];

    return (
        <div className="progress-bar">
            {steps.map((stepObj, index) => (
                <div key={index} className={`progress-step ${step >= index + 1 ? "active" : ""}`}>
                    <div className="step-icon-container">
                        <img src={stepObj.icon} alt={stepObj.label} className={`step-icon ${step >= index + 1 ? "icon-active" : ""}`} />
                    </div>
                    <div className="step-label">{stepObj.label}</div>
                    {index < steps.length - 1 && <div className="step-line" />}
                </div>
            ))}
        </div>
    );
};

export default ProgressBar;