import './ClientLocation.css'

const ClientLocation = ({
                            screenDetails,
                        }) => {
    return (
        <div>
            <section>
                <div className="location">
                    <div className="location_header">
                        <p className="location_header_text">Location</p>
                    </div>
                    <div className="dark_grey_strip"/>
                    <table>
                        <tbody>
                        <tr>
                            <td>{screenDetails?.locationDetails?.location_name}</td>
                        </tr>
                        <tr>
                            <td>{screenDetails?.locationDetails?.location_phone}</td>
                        </tr>
                        <tr>
                            <td>{screenDetails?.locationDetails?.location_email}</td>
                        </tr>
                        <tr>
                            <td>{screenDetails?.locationDetails?.location_address}</td>
                        </tr>
                        <tr>
                            <td>{screenDetails?.locationDetails?.location_city}</td>
                        </tr>
                        <tr>
                            <td>{screenDetails?.locationDetails?.location_state}</td>
                        </tr>
                        <tr>
                            <td>{screenDetails?.locationDetails?.location_zip}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    )
}

export default ClientLocation;
