// freddy-react/src/components/screens/header_row/HeaderRow.js

import React from 'react';
import './HeaderRow.css';

const HeaderRow = ({ requestSort }) => {


    return (
        <thead>
        <tr className="mro-page-header-row">
            <th className="mro-page-header-cell">
                <button onClick={() => requestSort('specimen_id')}>Specimen ID</button>
            </th>
            <th className="mro-page-header-cell">

            </th>
            <th className="mro-page-header-cell">
                <button onClick={() => requestSort('collected_on')}>Collected On</button>
            </th>
            <th className="mro-page-header-cell">
                <button onClick={() => requestSort('received_date')}>Received Date</button>
            </th>
            <th className="mro-page-header-cell">
                <button onClick={() => requestSort('hours_since_received')}>Hours Since Received</button>
            </th>
            <th className="mro-page-header-cell">
                <button onClick={() => requestSort('customer_id')}>Account Number</button>
            </th>
            <th className="mro-page-header-cell">
                <button onClick={() => requestSort('laboratory_name')}>Laboratory</button>
            </th>
            <th className="mro-page-header-cell">

            </th>
            <th className="mro-page-header-cell">

            </th>
        </tr>
        </thead>
    );
};


export default HeaderRow;