import {makePOSTRequest} from "../../utils/httpUtils";

export const setProductPrices = async (selectedPrices, selectedClients, accessToken, username, password) => {
    let formData = new FormData();

    formData.append('client_ids', JSON.stringify(selectedClients));
    formData.append('product_pricing', JSON.stringify(selectedPrices));

    // Add `labb_admin` details as a nested object
    const labbAdmin = {
        username: username,
        password: password,
    };

    formData.append('labb_admin', JSON.stringify(labbAdmin));

    return makePOSTRequest(`/api/v1/automation/client/pricing/create`, formData, accessToken);
};