import React from 'react';
import './Employees.css';

function ReviewEmployees({ employees }) {
    const headers = [
        "#",
        "Organization Name",
        "External ID",
        "Status",
        "First Name",
        "Last Name",
        "Date of Birth",
        "Phone",
        "Email",
        "Address",
        "Address Line 2",
        "City",
        "State",
        "Zip Code",
        "ID Type",
        "ID Number",
        "ID State",
        "ID Expiration"
    ];

    return (
        <div className="employee-container">
            <div className="employee-header">
                {headers.map((header, index) => (
                    <div key={index}>{header}</div>
                ))}
            </div>

            {employees.map((employee, index) => (
                <div key={index} className="employee-row">
                    <div><span>{index + 1}</span></div>
                    <div><span>{employee['Organization (required)'] || "N/A"}</span></div>
                    <div><span>{employee['External ID'] || "N/A"}</span></div>
                    <div><span>{employee['Status (required)'] || "N/A"}</span></div>
                    <div><span>{employee['First Name (required)'] || "N/A"}</span></div>
                    <div><span>{employee['Last Name (required)'] || "N/A"}</span></div>
                    <div><span>{employee['Date of Birth (required (mm/dd/yyyy))'] || "N/A"}</span></div>
                    <div><span>{employee['Phone (required (0000000000))'] || "N/A"}</span></div>
                    <div><span>{employee['Email (required)'] || "N/A"}</span></div>
                    <div><span>{employee['Address'] || "N/A"}</span></div>
                    <div><span>{employee['Address Line 2'] || "N/A"}</span></div>
                    <div><span>{employee['City'] || "N/A"}</span></div>
                    <div><span>{employee['State'] || "N/A"}</span></div>
                    <div><span>{employee['Zip Code'] || "N/A"}</span></div>
                    <div><span>{employee['ID Type'] || "N/A"}</span></div>
                    <div><span>{employee['ID Number'] || "N/A"}</span></div>
                    <div><span>{employee['ID State'] || "N/A"}</span></div>
                    <div><span>{employee['ID Expiration'] || "N/A"}</span></div>
                </div>
            ))}
        </div>
    );
}

export default ReviewEmployees;
