// freddy-react/src/pages/user/login/Login.js

import React from 'react';
import LoginComponent from '../../../components/user/login/Login';
import './Login.css';

/**
 * Represents a Login Page component.
 *
 * @param {Object} params - The parameters for the LoginPage component.
 * @param {Object} params.instance - The instance object passed to the LoginPage component.
 * @return {JSX.Element} The JSX element representing the LoginPage component with the provided instance.
 */
export default function LoginPage({ instance }) {
    return (
        <div className="login-page">
            <main className="login-main-content">
                <LoginComponent instance={instance}/>
            </main>
        </div>
    );
}

