import React from "react";
import "./SpeedSwabsHero.css";
import { PrimaryButton } from "../../buttons/primary_button/PrimaryButton";
import { useNavigate } from "react-router-dom";
import {SecondaryButton} from "../../buttons/secondary_button/SecondaryButton";
import {toggleTalkSwabsModal} from "../../../store/modals/modalsSlice";
import {useDispatch} from "react-redux";

const SpeedSwabsHero = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleBuyNow = async () => {
        // Send event data to Google Tag Manager
        if (window.dataLayer) {
            window.dataLayer.push({
                event: "buy_now_click",
                buttonLocation: "SpeedSwabsHero", // Location of the button being clicked
                productName: "SpeedSwabs", // Name of the product
                productCategory: "Oral Fluid Drug Test", // Category of the product
                action: "click" // Action type
            });
        }

        // Navigate to the product page
        navigate("/product");
    };

    const handleGetMoreDetails = () => {
        // Open the Talk Swabs modal
        dispatch(toggleTalkSwabsModal(true));
    };

    return (
        <div className="speed-swabs-hero">
            {/* Hero Text */}
            <div className="hero-text">
                <h1 className="hero-heading">Experience the <br/>speed of</h1>
                <h1 className="hero-heading hero-heading-highlight"><strong>SpeedSwabs...</strong></h1>

                <div className="speed-swabs-hero-subheader">
                    <p className="hero-description">
                        SpeedSwabs are the fastest, easiest-to-use <strong>rapid oral fluid drug
                        tests</strong> available.
                    </p>
                </div>
            </div>
            <div className="button-container">
                <PrimaryButton onClick={handleBuyNow}>Buy now</PrimaryButton>
                <SecondaryButton onClick={handleGetMoreDetails}>
                    Learn more
                </SecondaryButton>
            </div>
        </div>
    );
};

export default SpeedSwabsHero;