import React from 'react';
import './LabbLogin.css';
import { useDispatch, useSelector } from 'react-redux';
import {setPassword, setUserName} from "../../../store/autobot/login/loginSlice";


function LabbLogin() {
    const dispatch = useDispatch();

    // Get the current state using selectors
    const labb_username = useSelector(state => state.autobot.login.username);
    const labb_password = useSelector(state => state.autobot.login.password);


    // Update the input handlers to dispatch the Redux actions
    const handleEmailChange = (e) => {
        dispatch(setUserName(e.target.value));
    };

    const handlePasswordChange = (e) => {
        dispatch(setPassword(e.target.value));
    };

    return (
        <div className="login-form">
            <div className="email-input">
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={labb_username}
                    onChange={handleEmailChange}
                    className="login-input"
                />
            </div>
            <div className="password-input">
                <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={labb_password}
                    onChange={handlePasswordChange}
                    className="login-input"
                />
            </div>
        </div>
    );
}

export default LabbLogin;
