// src/store/invoices/questInvoiceSlice.js

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {makePOSTRequest} from "../../services/utils/httpUtils";
import {linkPricesToClients} from "../../services/api/v1/clientService";


export const linkClientPricesToService = createAsyncThunk(
    'servicePrice/linkClientPricesToService',
    async ({ selectedPrices, selectedClients }, thunkAPI) => {
        const accessToken = sessionStorage.getItem('accessToken');
        const response = await linkPricesToClients(selectedPrices, selectedClients, accessToken);
        return response;
    }
);



export const servicePricesSlice = createSlice({
    name: 'servicePrice',
    initialState: {
        showModal: false,
        prices: [],
    },
    reducers: {
        setServicePrices: (state, action) => {
            state.prices = action.payload;
            state.showModal = true;
        },
        clearServicePrices: (state) => {
            state.prices = [];
            state.showModal = false;
        }
    },
    extraReducers: (builder) => {
        builder
          .addCase(linkClientPricesToService.pending, (state) => {
                state.showModal = true;
            })
          .addCase(linkClientPricesToService.fulfilled, (state, action) => {
                state.showModal = false;
                state.prices = action.payload;
            })
          .addCase(linkClientPricesToService.rejected, (state, action) => {
                state.showModal = false;
            })
    }
});


export const { setServicePrices, clearServicePrices } = servicePricesSlice.actions;

export default servicePricesSlice.reducer;

