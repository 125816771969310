import React from 'react';
import ShopifySpeedCartModal from "../shopify/speed_cart/ShopifySpeedCartModal";
import CookieConsentBannerModal from "../cookie_consent/SpeedSwabsCookieConsentBanner";
import TalkSwabsModal from "../forms/progressive/talk_swabs/TalkSwabs";


const UnauthModals = () => {
    return (
        <>
            <CookieConsentBannerModal />
            <ShopifySpeedCartModal />
            <TalkSwabsModal />
        </>
    );
};

export default UnauthModals;
