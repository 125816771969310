import React from "react";
import "./SpeedProductDetailFeatures.css";
const SpeedProductDetailFeatures = () => {
    return (
        <div className="speed-product-details">
            <div className="product-info">
                <div className="product-features">
                    <div><span className="check-icon">✔</span> 60-Day Money Back Guarantee</div>
                    <div><span className="check-icon">✔</span> Negative results in 1-2 minutes</div>
                    <div><span className="check-icon">✔</span> Simple, 1-step process</div>
                    <div><span className="check-icon">✔</span> Runs on low specimen volume</div>
                </div>
            </div>
        </div>
    );
};

export default SpeedProductDetailFeatures;
