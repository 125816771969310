import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {loginPublicUser, registerPublicUser, verifyUserInviteToken} from '../../services/api/v1/publicUserService';


export const fetchRegisterPublicUser = createAsyncThunk(
    'publicUser/register',
    async ({ formData, freddyToken }, { rejectWithValue }) => {
        try {
            return await registerPublicUser(formData, freddyToken);

        } catch (error) {
            return rejectWithValue('An error occurred during registration.');
        }
    }
);

// Async thunk to verify user invite token
export const fetchVerifyUserInviteToken = createAsyncThunk(
    'publicUser/verifyInviteToken',
    async (token, { rejectWithValue }) => {
        try {
            return await verifyUserInviteToken(token);
        } catch (error) {
            return rejectWithValue('An error occurred during token verification.');
        }
    }
);


// Async thunk for login action
export const fetchLoginPublicUser = createAsyncThunk(
    'publicUser/login',
    async ({ token, username, password }, { rejectWithValue }) => {
        try {
            // Create FormData and send to backend
            const formData = new FormData();
            formData.append('token', token);
            formData.append('username', username);
            formData.append('password', password.ciphertext);
            formData.append('iv', password.iv);

            return await loginPublicUser(formData);

        } catch (error) {
            return rejectWithValue('An error occurred during login.');
        }
    }
);

export const publicUserSlice = createSlice({
    name: 'publicUser',
    initialState: {
        publicApprovalStatus: null,
        publicAccessToken: null,
        publicUserId: '',
        isPublicUserLoggedIn: false,
        loading: false, // Add loading state for async actions
        error: null,    // Add error state for handling login errors
    },
    reducers: {
        setPublicUserId: (state, action) => {
            state.publicUserId = action.payload;
        },
        setIsPublicUserLoggedIn: (state, action) => {
            state.isPublicUserLoggedIn = action.payload;
        },
        setPublicApprovalStatus: (state, action) => {
            state.publicApprovalStatus = action.payload;
        },
        setPublicToken: (state, action) => {
            state.publicToken = action.payload;
        },
        requestPublicAccess: (state) => {
            state.publicApprovalStatus = 'pending';
        },
        approvePublicAccess: (state) => {
            state.publicApprovalStatus = 'approved';
        },
        denyPublicAccess: (state) => {
            state.publicApprovalStatus = 'denied';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLoginPublicUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchLoginPublicUser.fulfilled, (state, action) => {
                state.loading = false;
                state.publicAccessToken = action.payload.freddy_token;
                state.isPublicUserLoggedIn = true;
            })
            .addCase(fetchLoginPublicUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.isPublicUserLoggedIn = false;
            })
            .addCase(fetchVerifyUserInviteToken.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchVerifyUserInviteToken.fulfilled, (state, action) => {
                state.loading = false;
                state.publicAccessToken = action.payload.freddy_token;
                state.isPublicUserLoggedIn = true;
            })
            .addCase(fetchVerifyUserInviteToken.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.isPublicUserLoggedIn = false;
            })
            .addCase(fetchRegisterPublicUser.pending, (state) => {  // Fixed here
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchRegisterPublicUser.fulfilled, (state, action) => {  // Fixed here
                state.loading = false;
                state.publicAccessToken = action.payload.freddy_token;
                state.isPublicUserLoggedIn = true;
            })
            .addCase(fetchRegisterPublicUser.rejected, (state, action) => {  // Fixed here
                state.loading = false;
                state.error = action.payload;
                state.isPublicUserLoggedIn = false;
            });
    },
});

export const {
    setPublicUserId,
    setIsPublicUserLoggedIn,
    setPublicApprovalStatus,
    setPublicToken,
    requestPublicAccess,
    approvePublicAccess,
    denyPublicAccess,
} = publicUserSlice.actions;

export const selectPublicUserId = (state) => state.publicUser.publicUserId;
export const selectIsPublicUserLoggedIn = (state) => state.publicUser.isPublicUserLoggedIn;
export const selectPublicApprovalStatus = (state) => state.publicUser.publicApprovalStatus;
export const selectPublicToken = (state) => state.publicUser.publicToken;
export const selectLoadingState = (state) => state.publicUser.loading;
export const selectError = (state) => state.publicUser.error;

export default publicUserSlice.reducer;
