// src/store/invoices/questInvoiceSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const questInvoiceSlice = createSlice({
    name: 'questInvoices',
    initialState: {
        invoices: [],
    },
    reducers: {
        setQuestInvoices: (state, action) => {
            state.invoices = action.payload;
        },
        clearQuestInvoices: (state) => {
            state.invoices = [];
        },
    },
});

export const { setQuestInvoices, clearQuestInvoices } = questInvoiceSlice.actions;

export default questInvoiceSlice.reducer;